import fetchCreator from "../utils/fetchCreator";
import { Request, PackageItemInterface } from "../interfaces/Request";
import { fetchRoutes } from "./routes";

const client = new fetchCreator();

export const getPoints = (request: Request): string => {
  // RequestForm Format.
  let response = request.stops;

  // API Response Format.
  if (request.sections) {
    const { sections } = request;

    const { start } = sections[0];

    const ends = sections.map(({ end }: any) => end);

    response = [start, ...ends];
  }

  return response
    .map(({ address: { latitude, longitude } }) => `${latitude},${longitude}`)
    .join(",");
};

export const getPointsForTracking = (
  request: Request,
  tracking: any
): string => {
  // RequestForm Format.
  let response = request.stops;

  // API Response Format.
  if (request.sections) {
    const { sections } = request;

    const { start } = sections[0];

    const ends = sections.map(({ end }: any) => end);

    response = [start, ...ends];
  }

  const currentStop = response.find((stop) => !stop.status || stop.status < 3);

  return `${tracking.location.point.latitude},${tracking.location.point.longitude},${currentStop?.address.latitude},${currentStop?.address.longitude}`;
};

/**
 * Format Stop as BE expected.
 *
 * @param stop FE way.
 * @returns stop BE way.
 */
const _createFormattedStop = ({
  address: { latitude, longitude, description, address_components },
  address_department,
  message,
  phone,
  packages,
  packages_to_drop = {},
  manager,
}: any) => ({
  address: {
    address_lines: {
      "0": description,
      "1": address_department,
      "2": message,
    },
    country_code: address_components.find((elem: any) => { return elem.types[0] === 'country' }).short_name,
    country_name: address_components.find((elem: any) => { return elem.types[0] === 'country' }).long_name,
    has_latitude: !!latitude,
    has_longitude: !!longitude,
    latitude,
    longitude,
    phone,
  },
  dropins: Object.values(packages).map((pkg: any) => ({
    ...pkg,
    quantity: 1,
    weight: Number(Math.round(pkg.weight)),
  })),
  dropoffs: Object.values(packages_to_drop).map((pkg: any) => ({
    ...pkg,
    weight: Math.round(pkg.weight),
    quantity: 1,
  })),
  player: {
    phone,
    nickname: manager,
  },
});

/**
 * sum some package fields.
 *
 * @param request
 * @returns { items, weight, floors }
 */
const _getPackagesSumData = (request: Request) => {
  const defaultValues = {
    items: 0,
    weight: 0,
    floors: 0,
  };

  const getPackagesFieldsReduce: any = (
    acc: number,
    { weight = 0 }: PackageItemInterface
  ) => acc + Number(Math.round(weight));

  const getStopFieldsReduce = (
    acc: typeof defaultValues,
    { packages = {}, floors = 0 }: any
  ) => ({
    items: acc.items + Object.values(packages).length,
    weight:
      acc.weight +
      Number(Object.values(packages).reduce(getPackagesFieldsReduce, 0)),
    floors: acc.floors + Number(floors),
  });

  return request.stops.reduce(getStopFieldsReduce, defaultValues);
};

export const getRequest = (requestId: string) =>
  client.get(`request/${requestId}`).then((data: any) => data.data.request);

export const getCloudRequest = (cloudId: string, requestId: string) =>
  client
    .get(`clouds/business/${cloudId}/requests/${requestId}`)
    .then((data: any) => data.data.request);

export const getRequests = () =>
  client.get("requests").then((data: any) => data.data.requests);

export const getCloudRequests = (
  since: string,
  status?: number,
  shipperID?: string,
  businessID?: string
) => {
  let url = `clouds/business/requests?since=${since}`;
  if (status != null && status >= 0) {
    url = url + `&status=${status}`;
  }
  if (shipperID != null && shipperID !== "") {
    url = url + `&shipperID=${shipperID}`;
  }
  if (businessID != null && businessID !== "") {
    url = url + `&businessID=${businessID}`;
  }
  return client.get(url).then((data: any) => data.data.requests);
};

/**
 * Create New Request.
 *
 * @param request
 * @returns Promise
 */
export const createRequest = async ({ request }: { request: Request }) => {
  return client
    .post("request", createRequestBody(request))
    .then((response: any) => response.data);
};
const createRequestBody = (request: Request) => {
  // format stop with BE format.
  const formattedStops = request.stops.map(_createFormattedStop);

  // const points = await getPoints(request);

  // const routeResponse: { route: { legs: [{ distance: { value: number } }] } } =
  //   await fetchRoutes({
  //     points,
  //     vehicle: request.vehicle_type,
  //   });

  // create sections from stops list.
  const sections = formattedStops.slice(1).map((value, index) => ({
    start: { position: "start", ...formattedStops[index] },
    end: { position: "end", ...value },
    // distance: routeResponse.route.legs[index].distance.value,
    sla: 0,
  }));

  const { floors } = _getPackagesSumData(request);

  return {
    sections,
    events: [],
    request_source: "user_source",
    vehicle_category: request.vehicle_type,
    trusted_user: true,
    assistants: 0, // @todo un-hardcode me plz. :(
    floors,
  };
};

/**
 * how much for my request?
 *
 * @param param0
 * @returns
 */
export const getRequestPrice = async (request: Request) => {
  const points = await getPoints(request);

  const { distance }: any = await fetchRoutes({
    points,
    vehicle: request.vehicle_type,
  });

  const distanceInKms = Math.round(distance / 1000);
  return client
    .post("request/price", createRequestBody(request))
    .then((response: any) => ({
      price: response.data.price,
      distance: distanceInKms,
    }));
};

// Get last known location of the shipper in charge of a request
export const getRequestTracking = (requestId: string) =>
  client.get(`request/${requestId}/tracking`).then((data: any) => data.data);

export const getRequestAvailability = async (requestId: string) =>
  await client.get(`request/${requestId}/availability`);
