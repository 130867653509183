import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Switch,
  Route,
  Router,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  isLoggedSelector,
  userCloudIdSelector,
} from "../../state/selectors/user";
import { getUser, setUserRoles } from "../../state/slices/user";
import { getGeolocation } from "../../state/slices/zones";
import { selectIsDeliveryFeatureActivated } from "../../state/selectors/cloud";
import { getCloud } from "../../state/slices/cloud";

import { PATHS } from "../../constants";
import { refreshToken } from "../../utils/fetchCreator";
import { getRolesFromJwt } from "../../utils/jwt";
import * as ROUTES from "../../routes";

import useMessaging from "./useMessaging";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID || "");

const history = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);
  const userCloudId = useSelector(userCloudIdSelector);
  const isDeliveryFeatureActivated = useSelector(
    selectIsDeliveryFeatureActivated
  );
  const homePage = isDeliveryFeatureActivated
    ? PATHS.REQUEST_IN_PROGRESS
    : PATHS.SHIPPERS;

  const { appBootstrap } = useMessaging();

  useEffect(() => {
    dispatch(getGeolocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Save Roles from JWT access_token
  useEffect(() => {
    if (isLogged) {
      appBootstrap();
      const access_token = localStorage.getItem("@access_token");
      const roles = getRolesFromJwt(access_token);

      dispatch(setUserRoles(roles));
      setTimeout(() => {
        refreshToken();
      }, 1000 * 60 * 59);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (!isLogged) return;

    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  // @TODO [salva@29-04-2024] what if NOT cloudID?
  useEffect(() => {
    if (userCloudId) {
      dispatch(getCloud(userCloudId));
    }
  }, [dispatch, userCloudId]);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={isLogged ? `(${PATHS.LOGIN}|${PATHS.WEB_LOGIN})` : homePage}
            render={() => (
              <Redirect to={isLogged ? homePage : PATHS.WEB_LOGIN} />
            )}
          />

          <Route path={PATHS.LOGOUT} exact component={ROUTES.Logout} />
          <Route path={PATHS.HOME} exact component={ROUTES.Home} />
          <Route path={PATHS.POLICY} exact component={ROUTES.Policy} />

          {isLogged ? (
            <>
              <Route
                path={PATHS.REQUEST_IN_PROGRESS}
                component={ROUTES.RequestsInProgress}
              />
              <Route
                path={PATHS.CREATE_REQUEST}
                component={ROUTES.CreateRequest}
              />
              <Route path={PATHS.REQUEST} component={ROUTES.Request} />

              <Route path={PATHS.HISTORY} component={ROUTES.History} />
              <Route path={PATHS.SHIPPERS} component={ROUTES.Shippers} />
              <Route path={PATHS.BUSINESSES} component={ROUTES.Businesses} />

              <Route path={PATHS.PROFILE} component={ROUTES.Profile} />
              <Route
                path={PATHS.BUSINESS_PROFILE}
                component={ROUTES.BusinessProfile}
              />
              <Route path={PATHS.MELI_PROFILE} component={ROUTES.MeliProfile} />

              <Route path={PATHS.LOGOUT} component={ROUTES.Logout} />
              {/* <Route path={PATHS.REGISTER} component={Register} /> */}
              <Route path={PATHS.LOGIN} component={ROUTES.Login} />
              <Route
                path={PATHS.NEW_SHIPPERS_LANDING}
                component={ROUTES.NewShippersLanding}
              />
              <Route
                path={PATHS.REGISTER_CLOUD}
                component={ROUTES.RegisterCloud}
              />
            </>
          ) : (
            <>
              <Route path={PATHS.LOGIN} component={ROUTES.Login} />
              {/* <Route path={PATHS.REGISTER} component={Register} /> */}
              <Route
                path={PATHS.NEW_SHIPPERS_LANDING}
                component={ROUTES.NewShippersLanding}
              />
              <Route
                path={PATHS.REGISTER_CLOUD}
                component={ROUTES.RegisterCloud}
              />
            </>
          )}
        </Switch>

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Router>
    </React.Suspense>
  );
};

export { App };
