import { FunctionComponent, memo } from "react";
import { GoogleMap, Polyline, Circle, Marker } from "@react-google-maps/api";
import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";
import pinIcon from "../../assets/images/marker.svg";
import useMap from "./useMap";
import * as styles from "./MapStyles";
import { RequestStop } from "../../interfaces";

export type Position = {
  lat: number;
  lng: number;
};

export type MapProps = {
  routeCords?: Position[];
  shipperRouteCords?: Position[];
  zoom?: number;
  radius?: number;
  center?: Position;
  stops: RequestStop[];
  tracking?: any;
};

const circleStyles: any = {
  pending: styles.pendingCircleStyle,
  current: styles.currentCircleStyle,
  completed: styles.completedCircleStyle,
};
const Map: FunctionComponent<MapProps> = memo((props) => {
  const { positions, firstPosition, isLoaded, tracking, ...mapProps } =
    useMap(props);

  if (!isLoaded || isEmpty(props.routeCords)) {
    return null;
  }

  return (
    <GoogleMap {...mapProps} mapContainerStyle={styles.containerStyle}>
      <Polyline path={props.routeCords} options={styles.lineStyle} />' '
      {positions.map((position) => (
        <Circle
          key={uuidv4()}
          center={position.position}
          radius={mapProps.radius}
          options={circleStyles[position.status]}
        />
      ))}
      {tracking && (
        <>
          <Marker position={tracking} icon={pinIcon} />
          <Polyline
            path={props.shipperRouteCords}
            options={styles.shipperLineStyle}
          />
        </>
      )}
    </GoogleMap>
  );
});

export { Map };
