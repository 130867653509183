import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaLink } from "@react-icons/all-files/fa/FaLink";

import { Button } from "../../../../components";
import { PATHS, toastConfig } from "../../../../constants";
import { copyToClipboard } from "../../../../utils";
import { getCloudSelector } from "../../../../state/selectors/cloud";
import { WHATSAPP_TEMPLATE_TEXT } from "../../../../hooks";
import Truck from "../../../../assets/images/truck.svg";

import styles from "./EmptyState.module.scss";

const EmptyState: React.FC = () => {
  const cloud = useSelector(getCloudSelector);

  const handleCopyLink = () => {
    const path = PATHS.NEW_SHIPPERS_LANDING.replace(
      ":cloudId?",
      cloud?.alias.toUpperCase()
    );
    const formattedText = WHATSAPP_TEMPLATE_TEXT.replace(
      "{CLOUD_NAME}",
      cloud?.name
    )
      .replace("{CLOUD_LINK}", window.location.origin.concat(path))
      .replace("{CLOUD_ALIAS}", cloud?.alias.toUpperCase());
    copyToClipboard(formattedText);
    toast.success("Copiado al portapapeles!", toastConfig);
  };

  return (
    <div className={styles.wrapper}>
      <img src={Truck} alt="" />

      <h2 className={styles.wrapper__title}>¡Aún no tienes repartidores!</h2>

      <p className={styles.wrapper__message}>
        Puedes asociar nuevos repartidores utilizando el siguiente enlace de
        invitación
      </p>

      <Button
        title="Link de invitación"
        type="gradient"
        icon={<FaLink />}
        onClick={handleCopyLink}
      />
    </div>
  );
};

export { EmptyState };
