import { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";

import { PlaceAutocompleteProps } from "./PlaceAutocomplete";
import { geolocationSelector } from "../../state/selectors/zones";
import { useSelector } from "react-redux";

const MAX_LENGTH = 2;
const INPUT_DEBOUNCE = 600;
const DEFAULT_COUNTRY = "ar";

const usePlaceAutocomplete = ({ value, onChange }: PlaceAutocompleteProps) => {
  const geolocation = useSelector(geolocationSelector);
  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState(value);

  const getPredictionsDebounced = useRef(
    debounce(
      ({ autocomplete, placesConf }) =>
        autocomplete
          .getPlacePredictions(placesConf)
          .then(({ predictions }: any) => setPredictions(predictions)),
      INPUT_DEBOUNCE
    )
  ).current;

  useEffect(
    () => () => getPredictionsDebounced.cancel(),
    [getPredictionsDebounced]
  );

  useEffect(() => {
    if (value) {
      setInputValue(value.description);
    } else {
      setInputValue("");
    }
  }, [value]);

  const handleInputChange = (input: string) => {
    setInputValue(input);
    onChange(input);

    const autocomplete: any =
      google && new google.maps.places.AutocompleteService();

    if (input.length < MAX_LENGTH) return setPredictions([]);

    const placesConf = {
      input,
      componentRestrictions: {
        country: geolocation.country_code || DEFAULT_COUNTRY,
      },
    };

    getPredictionsDebounced({ autocomplete, placesConf });
  };

  const handleSelectItem = (place: any) => () => {
    setInputValue(place.description);
    setPredictions([]);

    const geocoder = google && new google.maps.Geocoder();

    geocoder.geocode({ placeId: place.place_id }).then(({ results }: any) => {
      onChange({
        ...place,
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng(),
        address_components: results[0].address_components,
      });
    });
  };

  return {
    handleInputChange,
    handleSelectItem,
    inputValue,
    predictions,
  };
};

export default usePlaceAutocomplete;
