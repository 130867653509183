import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  noEmpty,
  emailValidator,
  validatePhone,
  greaterEqualThanZero,
} from "../../../../utils/validators";
import { updateStop } from "../../../../state/slices/request";
import {
  packagesToDropSelector,
  getPackages,
} from "../../../../state/selectors/requests";

const requestFields = ["address", "manager", "phone", "floors"];

const validators: any = {
  address: noEmpty,
  manager: noEmpty,
  email: emailValidator,
  phone: validatePhone,
  floors: greaterEqualThanZero,
};

const useRequestForm = ({ data, setHasErrors, stop }: any) => {
  const dispatch = useDispatch();

  const errors = useMemo(
    () =>
      Object.entries(data).reduce((acc: any, [key, value]: any) => {
        if (typeof validators[key] === "function") {
          return { ...acc, [key]: validators[key](value) };
        }

        return acc;
      }, {}),
    [data]
  );

  const handleInputChange = (field: string) => (value: string | {}) => {
    dispatch(updateStop({ data: { ...data, [field]: value }, stop }));
  };

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error !== null),
    [errors]
  );

  const packagesToDrop = useSelector(packagesToDropSelector);
  const packages = useSelector(getPackages);

  const isComplete = useMemo(() => {
    const formComplete = requestFields.length <= Object.values(errors).length;
    return (
      (packagesToDrop.length > 0 || Object.entries(packages).length > 0) &&
      formComplete
    );
  }, [errors, packages, packagesToDrop.length]);

  useEffect(
    () => setHasErrors(hasErrors || !isComplete),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasErrors, isComplete, errors]
  );

  const handleDropdownChange = (value: any) => {
    const handler = handleInputChange("packages_to_drop");

    const ids = value.map(({ value }: any) => value);

    const selectedPackages = Object.entries(packages).filter(([id]) => {
      return ids.includes(id);
    });

    handler(Object.fromEntries(selectedPackages));
  };

  const getDropdownValue = (value: any = []) =>
    Object.entries(value).map(([id, { description }]: any) => ({
      value: id,
      label: description,
    }));

  return {
    packagesToDrop,
    handleDropdownChange,
    getDropdownValue,
    handleInputChange,
    errors,
  };
};

export default useRequestForm;
