import { FunctionComponent, useMemo, Fragment } from "react";
import cn from "classnames";

import { Card } from "..";
import styles from "./CardWrap.module.scss";

export type CardWrapProps = {
  theme?: string;
  children: JSX.Element | JSX.Element[];
  twoCols?: boolean;
  className?: string;
};

const CardWrap: FunctionComponent<CardWrapProps> = ({
  theme,
  children,
  twoCols,
  className,
}) => {
  const isCard = useMemo(() => theme === "card", [theme]);

  const Wrapper = useMemo(() => (isCard ? Card : Fragment), [isCard]);

  const props = useMemo(
    () =>
      isCard
        ? {
            className: cn(className, {
              [styles["two-columns"]]: twoCols,
            }),
          }
        : null,
    [isCard, className, twoCols]
  );

  return <Wrapper {...props}>{children}</Wrapper>;
};

export { CardWrap };
