import { FunctionComponent, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { Divider, Avatar, TextStack, Button } from "..";

import Message from "./Message";

import { FaPaperPlane } from "@react-icons/all-files/fa/FaPaperPlane";

import useChat from "./useChat";
import styles from "./Chat.module.scss";

import { purple } from "../../constants/colors";

export type ChatProps = {
  itinerary: any;
  itineraryId: string;
};

const Chat: FunctionComponent<ChatProps> = ({ itinerary, itineraryId }) => {
  const {
    messagesRef,
    handleInputChange,
    handleKeyDown,
    messages,
    message,
    chatDisabled,
    handleClick,
  } = useChat({ itinerary, itineraryId });

  const scrollToEndMessage: any = useRef(null);

  useEffect(() => {
    scrollToEndMessage.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__row}>
        <div className={styles.wrapper__row}>
          <Avatar />

          <TextStack title={itinerary?.shipper?.name} />
        </div>
      </div>

      <Divider />

      <div className={styles["wrapper__chat-container"]}>
        <div
          className={styles["wrapper__chat-container__messages"]}
          ref={messagesRef}
        >
          {messages.map((message: any) => (
            <Message {...message} key={uuidv4()} />
          ))}
          <div ref={scrollToEndMessage} />
        </div>

        <div className={styles["wrapper__chat-container__send-container"]}>
          <input
            type="text"
            className={styles["wrapper__chat-container__input"]}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={message}
            disabled={chatDisabled}
          />

          <div className={styles["wrapper__chat-container__send-button"]}>
            <Button
              icon={<FaPaperPlane color={purple} />}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Chat };
