import { FunctionComponent, useLayoutEffect, useMemo, useState } from "react";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import isEmpty from "lodash/isEmpty";

import { Shipper } from "../../interfaces";
import { Button, Layout } from "../../components";
import { GA_EVENTS } from "../../constants";
import {
  useCopyInviteToClipboard,
  useGATracking,
  useIsMobile,
} from "../../hooks";

import { EnrollShipperModal } from "./components";
import { ShippersMobile } from "./ShippersMobile";
import { ShippersDesktop } from "./ShippersDesktop";
import { useShippers, useTrackingScreen } from "./hooks";

const Shippers: FunctionComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { trackGAPageView } = useGATracking();

  useLayoutEffect(() => {
    trackGAPageView({ path: "Shippers" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { summaryData, shippers, isLoading } = useShippers();
  const {
    activeTrackingShippers,
    filteredData,
    handleAcceptShipper,
    handleChangeShipperStatusToFilter,
    handleDeclineShipper,
    handleInputChange,
    handleMarkerClick,
    handleOnClickShipperItem,
    handleOnHoverShipperItem,
    handleShowAcceptShipperModal,
    handleShowFilters,
    hasFilters,
    hoveredShipperId,
    isListCollapsed,
    selectedShipper,
    selectedShipperId,
    shipperAcceptanceLoading,
    shipperStatusToFilter,
    showAcceptShipperModal,
    showFilters,
    toggleListCollapse,
  } = useTrackingScreen({ data: shippers });

  const { isMobile } = useIsMobile();
  const { copyInviteToClipboard } = useCopyInviteToClipboard();

  const shippersToList = useMemo(() => {
    if (isMobile && !drawerOpen && !isEmpty(selectedShipper)) {
      return [
        selectedShipper,
        ...filteredData.filter(
          (shipper: Shipper) => shipper.id !== selectedShipper?.id
        ),
      ];
    }

    return filteredData;
  }, [drawerOpen, filteredData, isMobile, selectedShipper]);

  return (
    <>
      <Layout
        headerAction={
          <Button
            title="Link de invitación"
            type="gradient"
            icon={<FaLink />}
            onClick={copyInviteToClipboard}
            gaEvent={GA_EVENTS.SHIPPERS.INVITE_LINK}
          />
        }
        summaryData={summaryData}
        isLoading={isLoading}
      >
        {isMobile ? (
          <ShippersMobile
            isLoading={isLoading}
            shippers={shippers!}
            hasShippers={!isEmpty(shippers)}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            shippersToList={shippersToList}
            handleShowFilters={handleShowFilters}
            showFilters={showFilters}
            handleChangeShipperStatusToFilter={
              handleChangeShipperStatusToFilter
            }
            shipperStatusToFilter={shipperStatusToFilter}
            activeTrackingShippers={activeTrackingShippers}
            hoveredShipperId={hoveredShipperId}
            selectedShipperId={selectedShipperId}
            handleMarkerClick={handleMarkerClick}
            handleOnClickShipperItem={handleOnClickShipperItem}
            handleOnHoverShipperItem={handleOnHoverShipperItem}
            isListCollapsed={isListCollapsed}
            hasFilters={hasFilters}
          />
        ) : (
          <ShippersDesktop
            isLoading={isLoading}
            handleChangeShipperStatusToFilter={
              handleChangeShipperStatusToFilter
            }
            handleInputChange={handleInputChange}
            handleOnClickShipperItem={handleOnClickShipperItem}
            handleOnHoverShipperItem={handleOnHoverShipperItem}
            handleShowFilters={handleShowFilters}
            hasFilters={!isEmpty(shippers)}
            isListCollapsed={isListCollapsed}
            selectedShipperId={selectedShipperId}
            shipperStatusToFilter={shipperStatusToFilter}
            shippersToList={shippersToList}
            showFilters={showFilters}
            toggleListCollapse={toggleListCollapse}
            activeTrackingShippers={activeTrackingShippers}
            hoveredShipperId={hoveredShipperId}
            handleMarkerClick={handleMarkerClick}
          />
        )}
      </Layout>

      <EnrollShipperModal
        handleAcceptShipper={handleAcceptShipper}
        handleDeclineShipper={handleDeclineShipper}
        handleShowAcceptShipperModal={handleShowAcceptShipperModal}
        shipperAcceptanceLoading={shipperAcceptanceLoading}
        showAcceptShipperModal={showAcceptShipperModal}
      />
    </>
  );
};

export { Shippers };
