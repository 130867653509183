import { Dispatch, SetStateAction } from "react";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";

import flettiSmallLogo from "../../assets/images/fletti.svg";
import { PATHS } from "../../constants";
import { Button } from "../Button";
import { Card } from "../Card";
import { HeaderCard } from "./HeaderCard";
import { TextStackProps } from "../TextStack";

import styles from "./Header.module.scss";

type Props = {
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  hideCard: boolean;
  showMenu: boolean;
  infoCard: React.ReactNode;
  summaryData?: TextStackProps[];
  isLoading?: boolean;
};

const MobileHeader = ({
  setShowMenu,
  showMenu,
  hideCard,
  infoCard,
  summaryData,
  isLoading = false,
}: Props): JSX.Element => {
  const history = useHistory();
  const isShippersScreen = history.location.pathname === PATHS.SHIPPERS;

  const handleToggleMenu = () => {
    setShowMenu((showMenu: boolean) => !showMenu);
  };

  return (
    <header>
      <div className={styles.header__background}>
        <div className={styles.header__background__elementsContainer}>
          <Button
            type="clear"
            icon={<FaChevronLeft />}
            onClick={history.goBack}
            className={cn({
              [styles.hidden]: isShippersScreen,
            })}
          />

          <a href="/">
            <img src={flettiSmallLogo} alt="fletti-logo" />
          </a>

          <Button
            type="clear"
            icon={showMenu ? <FaTimes /> : <FaBars />}
            onClick={handleToggleMenu}
          />
        </div>
      </div>

      {!hideCard && (
        <Card className={styles.header__profile}>
          {infoCard || (
            <HeaderCard summaryData={summaryData!} isLoading={isLoading} />
          )}
        </Card>
      )}
    </header>
  );
};

export { MobileHeader };
