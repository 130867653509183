import { v4 as uuidv4 } from "uuid";
import cn from "classnames";

import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";

import { RequestStop } from "../../../../interfaces/Request";
import { violet } from "../../../../constants/colors";

import { TextStack, Button, Modal } from "../../../../components";

import useSummaryStep from "./useSummaryStep";
import styles from "./SummaryStep.module.scss";

export interface SummaryStepProps extends RequestStop {
  stop: number;
  currentStop: boolean;
  status?: number;
  firstStop?: boolean;
  lastStop?: boolean;
  handleEditStop?: any;
  handleDeleteStop?: (x: any) => void;
  dropoffs?: any;
  dropins?: any;
  editMode?: boolean;
  confirmationCode?: string;
}

const SummaryStep = ({
  address,
  manager,
  floors,
  stop,
  currentStop,
  status,
  firstStop,
  lastStop,
  dropoffs = {},
  dropins = {},
  handleEditStop,
  confirmationCode,
  ...props
}: SummaryStepProps): JSX.Element => {
  const {
    open,
    hasPackagesToDrop,
    hasPackages,
    handleOpenModal,
    handleDeleteStop,
    packages,
    packagesToDrop,
    setOpen,
    couldDelete,
  } = useSummaryStep({
    stop,
    currentStop,
    status,
    lastStop,
    firstStop,
    dropins,
    dropoffs,
    ...props,
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__row}>
          <h3
            className={cn(styles.wrapper__title, {
              [styles["wrapper__title--current"]]: currentStop,
              [styles["wrapper__title--finished"]]: status === 3,
            })}
          >
            {`Parada ${stop + 1}`}
          </h3>

          <div className={styles.wrapper__row}>
            {handleEditStop && (
              <Button
                className={styles.wrapper__row__button}
                type="clear"
                icon={<FaEdit color={violet} />}
                onClick={() => handleEditStop(stop)}
              />
            )}

            {couldDelete && (
              <Button
                className={styles.wrapper__row__button}
                type="clear"
                icon={<FaTrash color={violet} />}
                onClick={handleOpenModal}
              />
            )}
          </div>
        </div>

        <div
          className={cn(styles["wrapper__stop-container"], {
            [styles["wrapper__stop-container--last"]]: lastStop,
          })}
        >
          <TextStack
            subtitle="Dirección"
            title={address?.description}
            reverse
          />

          <TextStack subtitle="Remitente" title={`${manager}`} reverse />

          {hasPackagesToDrop && (
            <>
              <h5>Paquetes a entregar</h5>

              {packagesToDrop.map(({ id, description, weight }: any) => (
                <p key={uuidv4()}>
                  <strong>Paquete #{id}:</strong> {description} ({weight} kgs)
                </p>
              ))}

              {confirmationCode && (
                <h4>
                  Codigo de confirmación: <strong>{confirmationCode}</strong>
                </h4>
              )}
            </>
          )}

          {hasPackages && (
            <>
              <h5>Paquetes a retirar</h5>

              {packages.map(({ id, description }: any) => (
                <p key={uuidv4()}>
                  <strong>Paquete #{id}:</strong> {description}
                </p>
              ))}
            </>
          )}

          {/*
           * We hide this field because the API response does not specify floors per stop.
           */}
          {/* <TextStack subtitle="Pisos" title={floors} reverse /> */}
        </div>
      </div>

      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <>
          <p>Confirma borrar parada {stop + 1} ?</p>

          <div className={styles.wrapper__row}>
            <Button
              type="clear"
              title="Cancelar"
              onClick={() => setOpen(false)}
            />
            <Button type="gradient" title="Borrar" onClick={handleDeleteStop} />
          </div>
        </>
      </Modal>
    </>
  );
};

export default SummaryStep;
