/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from "react-router-dom";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";

import { GA_EVENTS, PATHS } from "../../../constants";
import { Button } from "../../Button";
import { Divider } from "../../Divider";

import styles from "./Menu.module.scss";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";

const Menu = (): JSX.Element => {
  const history = useHistory();

  const handleNavigationToWhatsapp = () => {
    window.open(PATHS.WHATSAPP_URL);
  };

  const handleLogout = () => history.replace(PATHS.LOGOUT);
  const handleViewProfile = () => history.replace(PATHS.PROFILE);

  return (
    <div className={styles.wrapper}>
      <nav>
        <ul className={styles.list}>
          <li className={styles["menu-link"]}>
            <Button
              titleClassName={styles["menu-button"]}
              title="Ver Perfil"
              type="clear"
              icon={<FaEye color="#fff" />}
              onClick={handleViewProfile}
              gaEvent={GA_EVENTS.MOBILE_MENU.VIEW_PROFILE}
            />
          </li>

          <Divider />

          <li className={styles["menu-link"]}>
            <Button
              titleClassName={styles["menu-button"]}
              icon={<FaSignOutAlt color="#fff" />}
              title="Logout"
              type="clear"
              onClick={handleLogout}
              gaEvent={GA_EVENTS.MOBILE_MENU.LOGOUT}
            />
          </li>

          <li className={styles["menu-link"]}>
            <Button
              type="green-gradient"
              title="¿Tenés dudas?"
              icon={<FaWhatsapp />}
              onClick={handleNavigationToWhatsapp}
              gaEvent={GA_EVENTS.MOBILE_MENU.WHATSAPP}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Menu };
