import { FunctionComponent } from "react";
import { v4 as uuidv4 } from "uuid";

import isEmpty from "lodash/isEmpty";

import styles from "./List.module.scss";

export type ListInterface = {
  data: any;
  emptyState?: any;
  itemComponent: any;
  searchComponent?: any;
  noSearch?: boolean;
  isTracking?: boolean;
};

const List: FunctionComponent<ListInterface> = ({
  data,
  emptyState,
  itemComponent: Item,
  searchComponent: Search,
  isTracking,
}) => {
  return (
    <>
      {Search && <Search />}

      {isEmpty(data) ? (
        emptyState || <span>No Hay Resultados.</span>
      ) : (
        <ul className={isTracking ? styles.trackinglist : styles.list}>
          {data?.map(
            (data: any, index: number) =>
              <Item key={uuidv4()} data={data} index={index} /> || null
          )}
        </ul>
      )}
    </>
  );
};

export { List };
