import { useCallback, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import axios from "axios";

import { createBusinessRequest } from "../../state/slices/user";
import { validators } from "./validators";

import {
  firstStepFields,
  secondStepFields,
  TITLES,
  STEPS,
  USER_PROFILE_KEY_BY_STEP,
} from "../../constants/register";

import type { Props as RegisterInterface } from "./Register";
import { State } from "../../interfaces";
import { USER_PROFILE_KEYS } from "../../types";

const useRegister = ({ defaultData = {}, inProfile }: RegisterInterface) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState(defaultData);

  const [errors, setErrors] = useState<
    Record<USER_PROFILE_KEYS, Record<string, string | null>>
  >({
    [USER_PROFILE_KEYS.GENERAL_PROFILE]: {},
    [USER_PROFILE_KEYS.BUSINESS_PROFILE]: {},
  });
  const [accessToken, setAccessToken] = useState<string | null>("");
  const [refreshToken, setRefreshToken] = useState<string | null>("");

  useEffect(() => {
    if (!inProfile) {
      return;
    }

    if (Object.values(data).every(isEmpty)) {
      setData(defaultData);
    } else if (!isEqual(defaultData, data)) {
      setData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canGoToNextStep = useMemo(() => {
    if (inProfile) {
      return true;
    }

    // Errors by current step.
    const errorKeys = Object.keys(
      errors[USER_PROFILE_KEY_BY_STEP[currentStep]]
    );

    const stepsKeys = {
      [USER_PROFILE_KEYS.GENERAL_PROFILE]: firstStepFields,
      [USER_PROFILE_KEYS.BUSINESS_PROFILE]: secondStepFields,
    };

    const hasErrors = errorKeys
      .filter((errorKey: string) =>
        stepsKeys[USER_PROFILE_KEY_BY_STEP[currentStep]].includes(errorKey)
      )
      .some(
        (errorKey: string) =>
          errors[USER_PROFILE_KEY_BY_STEP[currentStep]][errorKey] !== null
      );

    const hasStepsKeys = stepsKeys[USER_PROFILE_KEY_BY_STEP[currentStep]].every(
      (key: string | string[]) => errorKeys.includes(key as string)
    );
    return hasStepsKeys && !hasErrors;
  }, [currentStep, errors, inProfile]);

  const handleInputChange =
    (field: string) =>
    (values: any | string = []) => {
      Object.keys(values).forEach((key) => {
        if (typeof validators[field as USER_PROFILE_KEYS][key] === "function") {
          setErrors((errors) => ({
            ...errors,
            [field]: {
              ...(errors[field as USER_PROFILE_KEYS] as {}),
              [key]: validators[field as USER_PROFILE_KEYS][key]?.(values[key]),
            },
          }));
        }

        setData((data: any) => ({
          ...data,
          [field]: { ...data[field], [key]: values[key] },
        }));
      });
    };

  const handleNextStep = useCallback(() => {
    if (currentStep === STEPS - 1) return;

    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handlePrevStep = useCallback(() => {
    if (currentStep === 0) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleSubmit = () => {
    const finalData = {
      business_cloud_id: data.cloudId,
      fullname: data[USER_PROFILE_KEYS.GENERAL_PROFILE].name,
      address_line:
        data[USER_PROFILE_KEYS.BUSINESS_PROFILE].address_line.description,
      identification_type: Number(
        data[USER_PROFILE_KEYS.GENERAL_PROFILE].identification_type.value
      ),
      business_identification_type: Number(
        data[USER_PROFILE_KEYS.BUSINESS_PROFILE].identification_type.value
      ),
      identification_number: Number(
        data[USER_PROFILE_KEYS.GENERAL_PROFILE].identification_number
      ),
      business_identification_number: Number(
        data[USER_PROFILE_KEYS.BUSINESS_PROFILE].identification_number
      ),
    };

    dispatch(createBusinessRequest(finalData));

    handleNextStep();
  };

  const loading = useSelector(({ user }: State) => user.status === "loading");

  const goToLastStep = () => {
    setCurrentStep(STEPS - 1);
  };

  return {
    accessToken,
    refreshToken,
    canGoToNextStep,
    data,
    errors,
    handleInputChange,
    handleNextStep,
    handlePrevStep,
    handleSubmit,
    step: currentStep,
    title: TITLES[currentStep],
    loading,
    goToLastStep,
  };
};

export default useRegister;
