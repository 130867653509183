import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FaList } from "@react-icons/all-files/fa/FaList";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";

import { generalProfileSelector } from "../../state/selectors/user";

import {
  Button,
  Avatar,
  Divider,
  TextStack,
  TextStackProps,
  Skeleton,
} from "..";

import { GA_EVENTS, PATHS, orange, violet } from "../../constants";

import {
  getCloudSelector,
  selectIsDeliveryFeatureActivated,
} from "../../state/selectors/cloud";

import { useIsMobile } from "../../hooks/useIsMobile";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { useCopyInviteToClipboard } from "../../hooks";
import { ShippersSkeleton } from "../../routes/Shippers/components/Skeletons/ShippersSkeleton";
import { LoadingOrContent } from "../../components";

import styles from "./HeaderCard.module.scss";

type Props = {
  summaryData: TextStackProps[];
  isLoading?: boolean;
};

const HeaderCard = ({ summaryData, isLoading = false }: Props): JSX.Element => {
  const isDeliveryFeatureActivated = useSelector(
    selectIsDeliveryFeatureActivated
  );
  const history = useHistory();

  const profile = useSelector(generalProfileSelector);
  const cloud = useSelector(getCloudSelector);

  const { isMobile } = useIsMobile();

  const profilePhoto = useMemo(() => {
    const { photo } = profile || {};

    return photo ? `data:image/jpeg;base64, ${photo}` : undefined;
  }, [profile]);

  const { copyInviteToClipboard } = useCopyInviteToClipboard();

  return (
    <div className={styles["card-container"]}>
      <div className={styles["avatar-container"]}>
        <LoadingOrContent
          loading={isLoading}
          customLoading={<ShippersSkeleton />}
        >
          <Avatar src={profilePhoto} />

          {cloud.alias && (
            <TextStack title={cloud.name} subtitle={`Alias: ${cloud.alias}`} />
          )}
        </LoadingOrContent>
      </div>

      {summaryData && !isMobile && (
        <>
          <Divider vertical />

          <LoadingOrContent
            loading={isLoading}
            customLoading={
              <div className={styles["shipper-loader"]}>
                <Skeleton circle width="42px" />
                <Skeleton />
              </div>
            }
          >
            {summaryData.map((item) => (
              <TextStack key={uuidv4()} {...item} />
            ))}
          </LoadingOrContent>
        </>
      )}

      {!isMobile ? (
        <>
          <Divider vertical />

          <div style={{ display: "flex", gap: 8 }}>
            {history.location.pathname !== PATHS.PROFILE ? (
              <Button
                title="Ver Perfil"
                color={orange}
                icon={<FaEye color="#fff" />}
                onClick={() => history.push(PATHS.PROFILE)}
                gaEvent={GA_EVENTS.SHIPPERS.VIEW_PROFILE}
              />
            ) : (
              <Button
                title="volver"
                type="dark-gradient"
                icon={<FaChevronLeft color="#fff" />}
                onClick={history.goBack}
                gaEvent={GA_EVENTS.PROFILE.BACK_BUTTON}
              />
            )}
          </div>
        </>
      ) : (
        <Button
          title="Invitar"
          type="gradient"
          icon={<FaLink color="fff" />}
          onClick={copyInviteToClipboard}
          size="small"
        />
      )}

      {isDeliveryFeatureActivated && (
        <Button
          title="Historial"
          color={violet}
          icon={<FaList color="#fff" />}
          onClick={() => history.push(PATHS.HISTORY)}
        />
      )}
    </div>
  );
};

export { HeaderCard };
