import useValidations, { isRequired } from "use-validations";
import { useSelector } from "react-redux";

import { State } from "../../interfaces";
import { USER_PROFILE_KEYS, User } from "../../types";
import { emailValidator } from "../../utils/validators";
import { getIdentificationType } from "../../utils/documents";
import { PERSONAL_IDENTIFICATION_TYPES } from "../../constants";
import { Button, Dropdown, Input } from "../../components";

import { useSaveUser } from "./useSaveUser";
import styles from "./DataForm.module.scss";

const initialData: User[USER_PROFILE_KEYS.GENERAL_PROFILE] = {
  name: "",
  email: "",
  identification_type: undefined,
  identification_number: "",
  phone: "",
};

const GeneralDataForm = () => {
  const generalProfile = useSelector(
    (state: State) => state.user?.data?.general_profile
  );

  const { errors, data, handleInputChange } = useValidations<
    User[USER_PROFILE_KEYS.GENERAL_PROFILE]
  >({
    defaultData: generalProfile || initialData,
    validators: {
      name: isRequired,
      email: emailValidator,
      identification_type: isRequired,
    },
  });

  const { handleSavePersonalData, isLoading } = useSaveUser();

  const handleSaveGeneralProfile = () => {
    if (data) {
      const completeData = { ...initialData, ...data };
      handleSavePersonalData(completeData);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Input
          label="Nombre Completo"
          placeholder="Juan Carlos Perez"
          theme="card"
          onChange={handleInputChange("name")}
          value={data?.name}
          error={errors?.name}
          required
        />

        <Input
          label="Correo Electrónico"
          placeholder="juancarlosperez@gmail.com"
          type="email"
          theme="card"
          onChange={handleInputChange("email")}
          value={data?.email}
          error={errors?.email}
          required
        />

        <Dropdown
          label="Tipo de Documento"
          placeholder="Seleccione"
          theme="card"
          options={PERSONAL_IDENTIFICATION_TYPES}
          onChange={({ value }) =>
            handleInputChange("identification_type")(value)
          }
          value={{
            label: getIdentificationType(Number(data?.identification_type)),
            value: data?.identification_type,
          }}
          required
        />

        <Input
          label="Número de Documento"
          placeholder="31313131"
          theme="card"
          onChange={handleInputChange("identification_number")}
          value={data?.identification_number}
          error={errors?.identification_number}
          required
        />

        <Input
          label="Número de Celular"
          placeholder="11 3131 3131"
          theme="card"
          onChange={handleInputChange("phone")}
          value={data?.phone}
          error={errors?.phone}
          required
        />
      </div>

      <Button
        title="Guardar"
        type="gradient"
        onClick={handleSaveGeneralProfile}
        className={styles.button}
        xxl
        loading={isLoading}
      />
    </>
  );
};

export { GeneralDataForm };
