import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";

import { Avatar, Divider, Button } from "../../../../components";

import useEmptyState from "./useEmptyState";
import styles from "./ShipperDetails.module.scss";

interface Props {
  requestId: string;
  shouldSearchAvailability: boolean;
}

const EmptyState = ({
  requestId,
  shouldSearchAvailability,
}: Props): JSX.Element => {
  const { shippersAvailable, handleShippersOffer, handleShipperOffer } =
    useEmptyState({
      requestId,
      shouldSearchAvailability,
    });

  return (
    <div className={styles["shippers-available"]}>
      {isEmpty(shippersAvailable) ? (
        <p>No hay repartidores disponibles</p>
      ) : (
        <>
          <p>Repartidores disponibles:</p>

          {shippersAvailable.map((shipper: any) => (
            <div key={uuidv4()} className={styles.row}>
              <Avatar />
              <Divider className={styles.row__divider} vertical />

              <span>{shipper.name}</span>

              <Divider className={styles.row__divider} vertical />

              <span>{shipper.vehicle_model}</span>
              <Button
                title="Ofertar"
                type="clear"
                onClick={() => handleShipperOffer(shipper)}
              />
            </div>
          ))}

          <Button
            className={styles["wrapper__second-row__shippers-available__btn"]}
            title="Ofertar a todos"
            type="gradient"
            onClick={handleShippersOffer}
          />
        </>
      )}
    </div>
  );
};

export default EmptyState;
