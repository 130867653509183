import { Skeleton, SkeletonGroup } from "../../../../components";
import { ShippersSkeleton } from "./ShippersSkeleton";
import { SkeletonMap } from "./SkeletonMap";

import styles from "./MobileSkeleton.module.scss";

const MobileSkeleton = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Skeleton height="100%">
        <SkeletonGroup containerStyles={styles["title-container"]}>
          <div className={styles.title}>
            <Skeleton circle width="32px" />
            <Skeleton width="130px" height="28px" />
            <Skeleton circle width="32px" />
          </div>

          <Skeleton square width="42px" />
        </SkeletonGroup>
      </Skeleton>

      <SkeletonMap />

      <Skeleton height="100%">
        <SkeletonGroup containerStyles={styles.handle} />

        <div className={styles["shippers-container"]}>
          <ShippersSkeleton />
          <ShippersSkeleton />
          <ShippersSkeleton />
        </div>
      </Skeleton>
    </div>
  );
};

export { MobileSkeleton };
