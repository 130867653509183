import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Request } from "../../../../interfaces/Request";
import { createRequest, getPrice } from "../../../../state/slices/request";
import {
  optimizedRequestSelector,
  requestDistanceSelector,
  requestPriceSelector,
} from "../../../../state/selectors/requests";
import { PATHS } from "../../../../constants";
import { selectIsDeliveryFeatureActivated } from "../../../../state/selectors/cloud";

const useSummary = ({ data }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const optimizedRequest = useSelector(optimizedRequestSelector);

  const areOptimizationsAvailable = useMemo(() => {
    return (
      JSON.stringify(data.stops) !== JSON.stringify(optimizedRequest.stops)
    );
  }, [optimizedRequest, data]);

  const [shouldOptimize, setShouldOptimize] = useState(false);

  const priceData: any = useSelector(requestPriceSelector);

  const isPriceLoading = priceData[0].priceStatus === "loading";

  const requestDistance = useSelector(requestDistanceSelector);

  const isDeliveryFeatureActivated = useSelector(
    selectIsDeliveryFeatureActivated
  );
  const homePage = isDeliveryFeatureActivated
    ? PATHS.REQUEST_IN_PROGRESS
    : PATHS.SHIPPERS;

  useEffect(() => {
    if (data.status["request/create"] === "idle") {
      history.push(homePage);
    }
    // TODO [salva@19-03-2024] [TASK-ID] do we need to skip these deps?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.status, history]);

  const handleSubmit = (request: Request) => {
    dispatch(createRequest({ request }));
  };

  const getRequestPrices = (payload: any) => {
    dispatch(getPrice(payload));
  };

  return {
    isPriceLoading,
    priceData,
    requestDistance,
    request: shouldOptimize ? optimizedRequest : data,
    areOptimizationsAvailable,
    shouldOptimize,
    setShouldOptimize,
    handleSubmit,
    getRequestPrices,
  };
};

export default useSummary;
