import { CreateCloudResponse } from "../interfaces/CreateCloudServiceResponse";
import { Cloud } from "../types";
import fetchCreator from "../utils/fetchCreator";
import { toast } from "react-toastify";

const client = new fetchCreator();

export const fetchCloudService = (cloudId?: string) => {
  if (!cloudId) return Promise.reject("cloudId is required");
  const url = `/clouds/${cloudId}`;

  return client.get(url).then((res) => {
    return res?.data?.cloud;
  });
};

export const updateCloud = (
  cloudId: string,
  cloudData: Partial<Pick<Cloud["cloud"], "name" | "alias" | "business_name">>
) => {
  if (!cloudId) return Promise.reject("cloudId is required");
  const url = `/clouds/${cloudId}`;

  return client
    .patch(url, cloudData)
    .then((res: any) => res?.data?.cloud)
    .catch((error: any) =>
      toast.error(
        error?.response?.data?.message || "Ocurrió un error guardando la Nube."
      )
    );
};

export const createCloudService = (
  data: { name: string; alias: string },
  auto: boolean,
  successCallback?: () => void
) =>
  client
    .post("/clouds/applications", { ...data }, { params: { auto } })
    .then((res: any) => {
      const data: CreateCloudResponse = res.data;
      successCallback?.();
      return data;
    })
    .catch((err: any) => {
      throw err.response.data.message;
    });

export const fetchUserCloudRequestService = (userId: string) =>
  client
    .get(`/clouds/application/${userId}`)
    .then((response: any) => response.data.application);

export const kickShipperService = (cloudId: string, shipperId: string) =>
  client
    .delete(`/clouds/${cloudId}/shippers/${shipperId}`)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw err.response.data.message;
    });
