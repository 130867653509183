import { FunctionComponent } from "react";
import { day_purple, purple } from "../../../../constants/colors";

export type BikeIconProps = {
  selected?: boolean;
};

const BikeIcon: FunctionComponent<BikeIconProps> = ({ selected = false }) => {
  const fillColor = selected ? purple : day_purple;

  return (
    <svg
      width="39"
      height="28"
      viewBox="0 0 39 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8329 22.3215C13.7349 22.3167 13.6263 22.3119 13.5062 22.309C12.7969 22.2869 11.8406 22.2869 10.5076 22.2869C9.17363 22.2869 8.21736 22.2869 7.50904 22.309C7.38891 22.3128 7.28031 22.3167 7.18228 22.3215C7.30352 23.1166 7.70561 23.8419 8.31567 24.3659C8.92572 24.89 9.70336 25.1782 10.5076 25.1782C11.3119 25.1782 12.0895 24.89 12.6995 24.3659C13.3096 23.8419 13.7117 23.1166 13.8329 22.3215ZM10.5076 27.0923C11.2018 27.0923 11.8891 26.9556 12.5304 26.6899C13.1718 26.4243 13.7545 26.0349 14.2453 25.5441C14.7362 25.0532 15.1255 24.4705 15.3912 23.8292C15.6568 23.1879 15.7935 22.5005 15.7935 21.8064C15.7935 21.3796 15.7935 21.0788 15.6561 20.8674C15.3303 20.3647 14.2289 20.3647 10.5076 20.3647C5.22168 20.3647 5.22168 20.3647 5.22168 21.8064C5.22168 23.2083 5.77859 24.5528 6.76989 25.5441C7.76119 26.5354 9.10569 27.0923 10.5076 27.0923Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5331 5.94873H31.1703V17.4817H29.2482V7.87089H28.1189L25.3866 21.9891L23.499 21.6239L26.5331 5.94873Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5234 25.1109C33.9573 25.0279 34.3706 24.8603 34.7397 24.6175C35.1089 24.3747 35.4266 24.0616 35.6747 23.6961C35.9228 23.3305 36.0964 22.9197 36.1857 22.487C36.275 22.0543 36.2782 21.6083 36.1951 21.1744L38.0827 20.813C38.3463 22.1899 38.052 23.6152 37.2648 24.7752C36.4775 25.9352 35.2617 26.7349 33.8847 26.9985C32.5078 27.262 31.0825 26.9678 29.9225 26.1805C28.7625 25.3933 27.9628 24.1774 27.6992 22.8005L29.5868 22.4391C29.6698 22.8731 29.8374 23.2864 30.0802 23.6555C30.323 24.0247 30.6361 24.3423 31.0016 24.5905C31.3672 24.8386 31.778 25.0122 32.2107 25.1015C32.6434 25.1908 33.0894 25.194 33.5234 25.1109Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3711 18.2618C34.7379 17.8183 33.9973 17.5526 33.2265 17.4925C32.4558 17.4324 31.683 17.5799 30.9886 17.9199C30.2942 18.2598 29.7036 18.7796 29.2784 19.4253C28.8531 20.0709 28.6087 20.8188 28.5706 21.591L36.8762 20.1263C36.5601 19.3754 36.0384 18.7291 35.3711 18.2618ZM31.8055 15.6525C33.2442 15.3989 34.7262 15.6575 35.994 16.3834C37.2617 17.1093 38.235 18.2565 38.7445 19.6256C39.1828 20.8 38.3044 21.8264 37.3164 21.9994L28.7983 23.5025C27.8113 23.6755 26.635 23.0124 26.6436 21.7591C26.6542 20.2983 27.1765 18.8874 28.1195 17.7717C29.0626 16.656 30.3669 15.9062 31.8055 15.6525Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7546 9.79325H8.10491C7.85002 9.79325 7.60557 9.8945 7.42533 10.0747C7.24509 10.255 7.14383 10.4994 7.14383 10.7543V12.6765H17.7157V10.7543C17.7157 10.4994 17.6144 10.255 17.4342 10.0747C17.254 9.8945 17.0095 9.79325 16.7546 9.79325ZM8.10491 7.87109C7.34023 7.87109 6.60687 8.17486 6.06616 8.71557C5.52545 9.25628 5.22168 9.98965 5.22168 10.7543V13.6376C5.22168 13.8925 5.32294 14.1369 5.50317 14.3171C5.68341 14.4974 5.92786 14.5986 6.18276 14.5986H18.6768C18.9317 14.5986 19.1761 14.4974 19.3564 14.3171C19.5366 14.1369 19.6378 13.8925 19.6378 13.6376V10.7543C19.6378 9.98965 19.3341 9.25628 18.7934 8.71557C18.2527 8.17486 17.5193 7.87109 16.7546 7.87109H8.10491Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7543 14.5984H10.1094C6.29108 14.5984 3.23582 17.1309 2.5054 20.3649H16.6477C16.6857 20.3669 16.7232 20.356 16.7543 20.3341V14.5984ZM10.1094 12.6763C4.75624 12.6763 0.416016 16.7878 0.416016 21.8603C0.416016 22.0958 0.617842 22.287 0.867722 22.287H16.6477C17.7683 22.287 18.6765 21.4269 18.6765 20.3649V13.4451C18.6765 13.0203 18.3132 12.6763 17.8653 12.6763H10.1094Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2098 2.10477H28.2877C27.7779 2.10477 27.289 2.30729 26.9285 2.66776C26.568 3.02823 26.3655 3.51714 26.3655 4.02693C26.3655 4.53672 26.568 5.02562 26.9285 5.3861C27.289 5.74657 27.7779 5.94908 28.2877 5.94908H30.2098V2.10477ZM28.2877 0.182617C27.2681 0.182617 26.2903 0.587641 25.5693 1.30859C24.8484 2.02954 24.4434 3.00735 24.4434 4.02693C24.4434 5.0465 24.8484 6.02432 25.5693 6.74527C26.2903 7.46621 27.2681 7.87124 28.2877 7.87124H31.5409C31.8677 7.87124 32.132 7.60598 32.132 7.28018V0.77368C32.132 0.61692 32.0697 0.466581 31.9589 0.355735C31.848 0.244889 31.6977 0.182617 31.5409 0.182617H28.2877Z"
        fill={fillColor}
      />
      <path
        d="M20.9312 1.14355L25.6645 1.97585L25.331 3.86917L20.5986 3.03688L20.9312 1.14355Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3259 22.2869H15.793V20.3647H27.3259V22.2869Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4036 17.4817H16.7539V15.5596H25.4036V17.4817Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default BikeIcon;
