import HomeIcon from "../../assets/images/icons/home.svg";
import HistoryIcon from "../../assets/images/icons/history.svg";
import BriefcaseIcon from "../../assets/images/icons/briefcase.svg";
import TicketIcon from "../../assets/images/icons/ticket.svg";
import UserIcon from "../../assets/images/icons/user.svg";
import MercadoPago from "../../assets/images/icons/mercado-pago.svg";

import { PATHS } from "../../constants";

export const menuItems = [
  {
    path: PATHS.REQUEST_IN_PROGRESS,
    name: "En Curso",
    icon: <img src={HomeIcon} alt="" />,
  },
  {
    path: PATHS.HISTORY,
    name: "Historial",
    icon: <img src={HistoryIcon} alt="" />,
  },
];

export const managerMenuItems = [
  {
    path: PATHS.BUSINESSES,
    name: "Comercios",
    icon: <img src={BriefcaseIcon} alt="" />,
  },
  {
    path: PATHS.SHIPPERS,
    name: "Repartidores",
    icon: <img src={TicketIcon} alt="" />,
  },
];

export const configMenuItems = [
  {
    path: PATHS.PROFILE,
    name: "Datos Personales",
    icon: <img src={UserIcon} alt="" />,
  },
  {
    path: PATHS.BUSINESS_PROFILE,
    name: "Datos de comercio",
    icon: <img src={BriefcaseIcon} alt="" />,
  },
  {
    path: PATHS.MELI_PROFILE,
    name: "Integración MercadoPago",
    icon: <img src={MercadoPago} alt="" />,
  },
];
