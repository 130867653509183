import { FunctionComponent } from "react";
import flettiLogo from "../../assets/images/fletti_white.svg";
import { Card } from "..";
import { HeaderCard } from "./HeaderCard";
import styles from "./Header.module.scss";

// @TODO [salva@26-03-2024] the notification component is hidden as it will not be used for the time being.
// import { Notifications } from "../Notifications";

export interface HeaderProps {
  headerAction?: JSX.Element;
  infoCard?: JSX.Element;
  summaryData?: any[];
  hideCard: boolean;
  isLoading?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({
  headerAction = null,
  infoCard,
  summaryData = null,
  hideCard,
  isLoading = false,
}) => {
  return (
    <header className={styles.header}>
      <div className={styles.header__background}>
        <div className={styles.header__background__elementsContainer}>
          <a href="/">
            <img src={flettiLogo} alt="fletti-logo" />
          </a>

          {headerAction}
        </div>

        {/* @TODO [salva@26-03-2024] the notification component is hidden as it will not be used for the time being. */}
        {/* <Notifications showNotifications={false} /> */}
      </div>

      {!hideCard && (
        <Card className={styles.header__profile}>
          {infoCard || (
            <HeaderCard summaryData={summaryData!} isLoading={isLoading} />
          )}
        </Card>
      )}
    </header>
  );
};

export { Header };
