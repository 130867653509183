import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Request,
  RequestStop,
  PackageItemInterface,
} from "../../interfaces/Request";
import {
  createRequest as createRequestService,
  getRequestPrice,
  getRequest as getRequestService,
  getCloudRequest as getCloudRequestService,
  getCloudRequests as getCloudRequestsService,
  getRequestTracking as getRequestTrackingService,
} from "../../services/request";
import { toast } from "react-toastify";

import { toastConfig } from "../../constants";

import { createOffers } from "../../services/shipper-offer";
import { State } from "../../interfaces";
import { userCloudIdSelector, userIsManagerSelector } from "../selectors/user";

const EMPTY_STOP: RequestStop = {
  id: 0,
  packages: {},
  upstairs: "false",
  floors: 0,
};

/**
 * Get Request
 */
export const getRequest = createAsyncThunk<
  any,
  string,
  {
    state: State;
  }
>("request/get", async (requestId: string = "1d", thunkApi) => {
  const store = thunkApi.getState();

  const userIsManager = userIsManagerSelector(store);
  const userCloudId = userCloudIdSelector(store);

  const response = userIsManager
    ? await getCloudRequestService(userCloudId, requestId)
    : await getRequestService(requestId);

  return { response, requestId };
});

/**
 * Get Requests
 */
export const getRequests = createAsyncThunk<
  any,
  { since?: string; status?: number; shipperID?: string; businessID?: string },
  {
    state: State;
  }
>("requests/get", async ({ since = "1d", status, shipperID, businessID }) => {
  const response = await getCloudRequestsService(
    since,
    status,
    shipperID,
    businessID
  );
  return response;
});

/**
 * Create Request
 */
export const createRequest = createAsyncThunk(
  "request/create",
  async ({ request }: { request: Request }, { rejectWithValue }) => {
    try {
      const response: any = await createRequestService({ request });
      const { request_id, shippers } = response;

      if (shippers && shippers.length > 0) {
        await createOffers({ request_id, shippers });
      }

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getPrice = createAsyncThunk(
  "request/get-price",
  async (request: Request) => {
    const response: any = await getRequestPrice(request);

    return response;
  }
);

export const getRequestTracking = createAsyncThunk(
  "request/get-tracking",
  async (requestId: string) => {
    const response: any = await getRequestTrackingService(requestId);

    return { ...response, requestId };
  }
);

const removeIndex = (
  arr: {
    [key: string]: PackageItemInterface;
  },
  index: string
) => {
  const entries = Object.entries(arr).filter(([id]) => id !== index);

  return Object.fromEntries(entries);
};

const initialState = {
  vehicle_type: 0,
  request_type: "express",

  stops: [EMPTY_STOP],

  status: {},
  response: null,
  requests: {},
  tracking: {},
  price: [],
  distance: 0,
};

const getActionType = (type: string) => type.split("/").slice(0, 2).join("/");

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    updateRequest(state: any, action: PayloadAction<{ data: Request }>) {
      return {
        ...state,
        ...action.payload.data,
      };
    },

    updateStop(
      state: any,
      action: PayloadAction<{ data: RequestStop; stop: number }>
    ): any {
      const { stop, data } = action.payload;

      let stops = [...state.stops];

      stops[stop] = data;

      return {
        ...state,
        stops,
      };
    },

    addStop(state: typeof initialState) {
      state.stops.push({ ...EMPTY_STOP, id: state.stops.length });
    },

    deleteStop(
      state: typeof initialState,
      action: PayloadAction<{ stop: number }>
    ) {
      state.stops = state.stops.filter(
        (_, index) => action.payload.stop !== index
      );
    },

    removePackage(
      state: typeof initialState,
      action: PayloadAction<{ stop: number; index: string }>
    ) {
      const { stop, index } = action.payload;

      state.stops[stop].packages = removeIndex(
        state.stops[stop].packages || {},
        index
      );
    },

    updatePackages(
      state: any,
      action: PayloadAction<{ index: number; pkgData: any; stop: number }>
    ) {
      const { stop, index, pkgData } = action.payload;

      const stops = [...state.stops];

      const packages = { ...stops[stop].packages };

      const weight = Number(pkgData.weight || packages[index].weight);

      packages[index] = {
        ...packages?.[index],
        id: Number(index),
        ...pkgData,
        weight,
      };

      stops[stop] = {
        ...stops?.[stop],
        packages,
      };

      return {
        ...state,
        stops,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getRequest.pending, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "loading" };
      })
      .addCase(getRequest.fulfilled, (state, action) => {
        const type = getActionType(action.type);
        state.status = { [type]: "idle" };
        state.requests = {
          ...state.requests,
          [action.payload.requestId]: action.payload.response,
        };
      })

      .addCase(getRequests.pending, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "loading" };
      })
      .addCase(getRequests.fulfilled, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "idle" };
        state.requests = action.payload;
      })

      .addCase(createRequest.pending, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "loading" };
      })
      .addCase(createRequest.fulfilled, (state, action): any => {
        const type = getActionType(action.type);

        toast.success("El pedido se creo correctamente!", toastConfig);

        return {
          ...initialState,
          status: { [type]: "idle" },
          response: action.payload,
        };
      })
      .addCase(createRequest.rejected, (state, action) => {
        const type = getActionType(action.type);

        const payload: any = action.payload;
        const error = payload?.message;

        const errorMessage = `Ocurrio un error al cargar el pedido: ${error}`;

        toast.error(errorMessage, toastConfig);

        state.status = { [type]: "rejected" };
      })

      .addCase(getPrice.pending, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "loading" };
      })
      .addCase(getPrice.fulfilled, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "idle" };
        state.price = action.payload.price;
        state.distance = action.payload.distance;
      })

      .addCase(getRequestTracking.pending, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "loading" };
      })
      .addCase(getRequestTracking.fulfilled, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "idle" };
        state.tracking = {
          ...state.tracking,
          [action.payload.requestId]: action.payload,
        };
      });
  },
});

export const {
  updateRequest,
  removePackage,
  updatePackages,
  addStop,
  deleteStop,
  updateStop,
} = requestSlice.actions;

export default requestSlice.reducer;
