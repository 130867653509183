import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getItinerary as getItineraryService,
  sendChatMessage as sendChatMessageService,
} from "../../services/itinerary";

/**
 * Get Itinerary
 */
export const getItinerary = createAsyncThunk(
  "itinerary/get",
  async (itineraryId: string) => {
    const response = await getItineraryService(itineraryId);
    return { itineraryId, itinerary: response.itinerary };
  }
);

/**
 * Send chat message for Itinerary
 */
export const sendChatMessage = createAsyncThunk(
  "itinerary/sendChatMessage",
  async ({ itineraryId, message, successCallback }: any) => {
    const response = await sendChatMessageService(itineraryId, message);
    successCallback();
    return { itineraryId, itinerary: response.itinerary };
  }
);

const initialState = {
  status: {},
  itineraries: {},
};

const getActionType = (type: string) => type.split("/").slice(0, 2).join("/");

export const itinerariesSlice = createSlice({
  name: "itineraries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getItinerary.pending, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "loading" };
      })
      .addCase(getItinerary.fulfilled, (state, action) => {
        const type = getActionType(action.type);

        state.status = { [type]: "idle" };
        state.itineraries = {
          ...state.itineraries,
          [action.payload.itineraryId]: action.payload.itinerary,
        };
      });
  },
});

export default itinerariesSlice.reducer;
