import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { State } from "../../interfaces";
import {
  fetchBusinesses,
  fetchBusinessPayments,
  fetchBusinessRequests,
} from "../../services/businesses";

const initialState = {
  data: [],
  business_requests: [],
  business_payments: [],
  status: {
    businesses: "idle",
    businessRequests: "idle",
    businessPayments: "idle",
  },
};

export const getBusinesses = createAsyncThunk<
  any,
  void,
  {
    state: State;
  }
>("clouds/fetchBusinesses", async () => {
  const response = await fetchBusinesses();

  return response;
});

export const getBusinessRequests = createAsyncThunk<
  any,
  void,
  {
    state: State;
  }
>("clouds/fetchBusinessRequests", async () => {
  const response = await fetchBusinessRequests();

  return response;
});

export const getBusinessPayments = createAsyncThunk<
  any,
  void,
  {
    state: State;
  }
>("clouds/fetchBusinessPayments", async () => {
  const response = await fetchBusinessPayments();
  return response;
});

export const businessesSlice = createSlice({
  name: "businesses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinesses.pending, (state) => {
        return {
          ...state,
          status: {
            ...state.status,
            businesses: "loading",
          },
        };
      })
      .addCase(getBusinesses.fulfilled, (state, action) => {
        return {
          ...state,
          status: {
            ...state.status,
            businesses: "idle",
          },
          data: action.payload,
        };
      })
      .addCase(getBusinessRequests.pending, (state) => {
        return {
          ...state,
          status: {
            ...state.status,
            businessRequests: "loading",
          },
        };
      })
      .addCase(getBusinessRequests.fulfilled, (state, action) => {
        return {
          ...state,
          status: {
            ...state.status,
            businessRequests: "idle",
          },
          business_requests: action?.payload?.business_requests,
        };
      })
      .addCase(getBusinessPayments.pending, (state) => {
        return {
          ...state,
          status: {
            ...state.status,
            businessPayments: "loading",
          },
        };
      })
      .addCase(getBusinessPayments.fulfilled, (state, action) => {
        return {
          ...state,
          status: {
            ...state.status,
            businessPayments: "idle",
          },
          business_payments: action.payload,
        };
      });
  },
});

export default businessesSlice.reducer;
