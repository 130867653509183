import { GoogleMap, Marker } from "@react-google-maps/api";
import ReactDOMServer from "react-dom/server";
import { v4 as uuidv4 } from "uuid";
import isUndefined from "lodash/isUndefined";

import { useIsMobile } from "../../../../hooks";
import { ShipperProps } from "../../types";

import { TrackingMapIcon } from "./TrackingMapIcon";
import { useTrackingMap } from "./useTrackingMap";
import { SkeletonMap } from "../Skeletons/SkeletonMap";

type Props = {
  shippers?: ShipperProps[];
  zoom?: number;
  hoveredShipperId?: string | null;
  selectedShipperId?: string | null;
  onMarkerClick: (shipperId: string | undefined) => void;
};

const TrackingMap = ({
  shippers = [],
  hoveredShipperId = null,
  selectedShipperId = null,
  onMarkerClick,
}: Props) => {
  const { activeShippers, zoom, center, loadMap } = useTrackingMap({
    shippers,
    selectedShipperId,
  });

  const { isMobile } = useIsMobile();

  const mapHeight = isMobile ? "60vh" : "100%";

  const styles = {
    containerMap: {
      width: "100%",
      height: mapHeight,
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      borderBottomRightRadius: isMobile ? "unset" : "10px",
      borderBottomLeftRadius: isMobile ? "unset" : "10px",
      border: "unset",
    },
  };

  return isUndefined(google) ? ( // google is not defined
    <SkeletonMap />
  ) : (
    <GoogleMap
      center={center}
      zoom={zoom}
      mapContainerStyle={styles.containerMap}
      onLoad={loadMap}
    >
      {activeShippers?.map((shipper: ShipperProps) => {
        const isHover = hoveredShipperId === shipper.id;
        const isSelected = selectedShipperId === shipper.id;

        const svgString = ReactDOMServer.renderToString(
          <TrackingMapIcon
            photo={shipper.photo}
            isHover={isHover}
            isSelected={isSelected}
          />
        );
        const svgUrl = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
        return (
          <Marker
            key={uuidv4()}
            position={shipper.position}
            icon={svgUrl}
            onClick={() => onMarkerClick(shipper.id)}
          />
        );
      })}
    </GoogleMap>
  );
};

export { TrackingMap };
