import cn from "classnames";

import styles from "./SkeletonGroup.module.scss";

export interface SkeletonGroupProps {
  children?: JSX.Element | JSX.Element[];
  containerStyles?: string;
}

export const SkeletonGroup = ({
  children,
  containerStyles,
}: SkeletonGroupProps): JSX.Element => (
  <div className={cn(styles["skeleton-group-container"], containerStyles)}>
    {children}
  </div>
);
