import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

const useQueryParameter2LocalStorage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (parameter: string, cb?: (s: string | null, d: Dispatch) => void) => {

    const params = new URLSearchParams(history.location.search);

    const clearParameter = parameter.replace('@', '');

    const queryParameter = params.get(clearParameter);

    if (queryParameter !== null) {
      localStorage.setItem(parameter, queryParameter);
    }

    if ('function' === typeof cb) {
      cb(queryParameter, dispatch)
    }
  };
};

export default useQueryParameter2LocalStorage;
