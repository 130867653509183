import { orange } from "../../constants/colors";

import { OptionType } from "../../components/Dropdown";
import { State } from "../../interfaces";

import { REQUEST_STATUSES, STATUSES } from "../../constants/requests";
import { moneyFormatter } from "../../utils/money";
import { dateTimeFormatter } from "../../utils/date";
import { getVehicleCategory } from "../../constants/shippers";
import { optimizeStopsPath } from "../../utils/distance";

export const requestSelector = (state: State) => state.request;

export const optimizedRequestSelector = (state: State) => {
  const request = state.request;

  const optimizedRequest = {
    ...request,
    stops: optimizeStopsPath(request.stops),
  };

  return optimizedRequest;
};

export const requestPackagesSelector =
  ({ stop = 0 }: { stop: number }) =>
  (state: State) =>
    requestSelector(state).stops[stop]?.packages || {};

export const getPackages = (state: State) => {
  const { stops } = requestSelector(state);

  return stops.reduce((acc, { packages }) => ({ ...acc, ...packages }), {});
};

/**
 * get available packages to drop as dropdown items.
 *
 * @param state
 * @return OptionType[]
 * @todo test me :-(
 */
export const packagesToDropSelector = (state: State): OptionType[] => {
  const { stops = [] } = requestSelector(state);

  // get packages from all stops.
  const packages = stops.flatMap(({ packages = {} }) => packages);

  // get a list of ids of the selected packages, if any.
  const packages_to_drop: any = stops.reduce(
    (acc, { packages_to_drop = {} }): any => [
      ...acc,
      ...Object.keys(packages_to_drop),
    ],
    []
  );

  // flat packages list.
  const reducedPackages: any = Object.keys(packages).reduce(
    (r: [], k: any) => ({ ...r, ...packages[k] }),
    []
  );

  const filterByNotSelected: any = ([package_id]: [string]) =>
    !packages_to_drop?.includes(package_id);

  const filteredPackagesEntries =
    Object.entries(reducedPackages).filter(filterByNotSelected);

  const filteredPackages = Object.fromEntries(filteredPackagesEntries);

  // return data as dropdown option.
  return Object.keys(filteredPackages).map((id: any) => ({
    value: id,
    label: reducedPackages[id]?.description,
  }));
};

/**
 * do you have packages to drop?
 *
 * @param state
 * @return boolean
 */

export const hasPackagesToDropSelector = (state: State): boolean =>
  packagesToDropSelector(state).length > 0;

/**
 * what packages you need to drop in this stop?
 *
 * @param state
 * @return object
 */
export const packagesToDropByStopSelector =
  ({ stop = 0 }: { stop: number }) =>
  (state: State) =>
    requestSelector(state).stops[stop]?.packages_to_drop || {};

/**
 * how much for my request?
 *
 * @param state
 * @return object
 */

export const requestPriceSelector = (state: State) => {
  const {
    price: { price_components = [], base_price = {} },
    status,
  }: any = requestSelector(state);

  const priceStatus = status?.["request/get-price"];

  const basePrice = {
    title: "Precio Base",
    subtitle: moneyFormatter(base_price?.value),
    table: true,
    priceStatus,
  };

  const response = price_components
    .filter(({ value = 0 }: { value?: number }) => value > 0)
    .map(({ description, value }: any, index: number) => ({
      title: description,
      subtitle: moneyFormatter(value),
      table: true,
      color: description === "Total" ? orange : undefined,
    }));

  return [basePrice, ...response];
};

export const requestDistanceSelector = (state: State) => {
  const { distance }: any = requestSelector(state);

  return distance;
};

export const requestInProgressSelector = (state: State) => {
  const { requests }: any = requestSelector(state);

  return Object.entries<any>(requests || {})
    .filter(
      ([, request]) =>
        request.status === REQUEST_STATUSES.PENDING ||
        request.status === REQUEST_STATUSES.WAITING_RESPONSE ||
        request.status === REQUEST_STATUSES.ACCEPTED
    )
    .sort(([, aRequest], [, bRequest]) => {
      if (aRequest.created > bRequest.created) return -1;
      return 1;
    })
    .map(formatRequestSummary);
};

const getRequestTotalPrice = (request: any): number => {
  const priceComponentsLength = request?.price?.price_components?.length;

  if (!priceComponentsLength) return 0;

  const total =
    request?.price?.price_components[priceComponentsLength - 1].value;

  return total;
};

export const requestHistorySelector = (state: State) => {
  const { requests }: any = requestSelector(state);

  return Object.entries<any>(requests || {})
    .sort(([, aRequest], [, bRequest]) => {
      if (aRequest.created > bRequest.created) return -1;
      return 1;
    })
    .map(formatRequestSummary);
};

export const currentRequestSelector =
  (requestId?: string) => (state: State) => {
    const { requests }: any = requestSelector(state);

    if (!requestId || !requests) return null;

    return requests[requestId];
  };

export const requestTrackingSelector =
  (requestId?: string) => (state: State) => {
    const { tracking }: any = requestSelector(state);

    if (!requestId || !tracking) return null;

    return tracking[requestId];
  };

const formatRequestSummary = ([id, request]: any) => {
  const requestTotalPrice = getRequestTotalPrice(request);
  return {
    id: id,
    status: request.status,
    shipperID: request.shipper_id,
    requestTotalPrice,
    summary: [
      {
        title: `${id}`,
        subtitle: "Identificador de pedido",
      },
      {
        title: STATUSES[request.status],
        subtitle: "Estado",
      },
      {
        title: getVehicleCategory(request.vehicle_category),
        subtitle: "Tipo de vehiculo",
      },
      {
        title: dateTimeFormatter(request.created),
        subtitle: "Fecha de solicitud",
      },
      {
        title: `${request.sections.length + 1} Paradas`,
        subtitle: "Paradas",
      },
      {
        title: moneyFormatter(requestTotalPrice),
        subtitle: "Monto",
      },
    ],
  };
};
