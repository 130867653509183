import React from "react";

const FilterIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1025 0.999999C10.1023 0.782992 10.1728 0.571815 10.3032 0.398404C10.4337 0.224993 10.6171 0.0987798 10.8256 0.0388504C11.0342 -0.0210791 11.2566 -0.0114648 11.4592 0.0662391C11.6619 0.143943 11.8337 0.28551 11.9487 0.469532L14.634 3.15483C14.7267 3.24765 14.8003 3.35783 14.8505 3.47907C14.9006 3.60032 14.9264 3.73026 14.9264 3.86148C14.9263 3.99269 14.9005 4.12261 14.8502 4.24382C14.7999 4.36504 14.7263 4.47516 14.6335 4.56791C14.5407 4.66066 14.4305 4.73422 14.3092 4.7844C14.188 4.83457 14.0581 4.86037 13.9268 4.86032C13.7956 4.86027 13.6657 4.83438 13.5445 4.78413C13.4233 4.73387 13.3132 4.66023 13.2204 4.56741L12.1015 3.44754V10.99C12.1015 11.2549 11.9963 11.509 11.8089 11.6964C11.6216 11.8837 11.3675 11.989 11.1025 11.989C10.8376 11.989 10.5835 11.8837 10.3961 11.6964C10.2088 11.509 10.1035 11.2549 10.1035 10.99V0.999999H10.1025Z"
      fill="#FF8D3D"
    />
    <path
      d="M5.46838 14.9999C5.4686 15.2169 5.39815 15.4281 5.26769 15.6015C5.13722 15.7749 4.95384 15.9012 4.74527 15.9611C4.53671 16.021 4.3143 16.0114 4.11168 15.9337C3.90906 15.856 3.73725 15.7144 3.62224 15.5304L0.93694 12.8451C0.74962 12.6577 0.644438 12.4035 0.644531 12.1385C0.644625 11.8735 0.749987 11.6193 0.93744 11.432C1.12489 11.2447 1.37908 11.1395 1.64408 11.1396C1.90909 11.1397 2.1632 11.2451 2.35052 11.4325L3.46939 12.5524V5.00998C3.46939 4.74503 3.57464 4.49093 3.76199 4.30359C3.94934 4.11624 4.20344 4.01099 4.46839 4.01099C4.73334 4.01099 4.98744 4.11624 5.17479 4.30359C5.36213 4.49093 5.46738 4.74503 5.46738 5.00998V14.9999H5.46838Z"
      fill="#FF8D3D"
    />
  </svg>
);

export default FilterIcon;
