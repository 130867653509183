export type LoadingOrContentProps = {
  loading: boolean;
  children: React.ReactNode;
  customLoading?: JSX.Element;
};

const LoadingOrContent = ({
  loading,
  children,
  customLoading,
}: LoadingOrContentProps): JSX.Element => {
  if (loading) {
    return customLoading || <p>loading ..</p>;
  }

  return <>{children}</>;
};

export { LoadingOrContent };
