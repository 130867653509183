import { FunctionComponent } from "react";
import { v4 as uuidv4 } from "uuid";

import { TextStack, Divider } from "../../../../components";

import { orange, violet } from "../../../../constants/colors";
import { STATUSES } from "../../../../constants/requests";

import styles from "./InfoCard.module.scss";

export type InfoCardProps = {
  requestId: string;
  requestVehicleType: string;
  requestStatus: number;
  requestCreationDate: string;
  requestCommitment: string;
  requestPrice: string;
};

const InfoCard: FunctionComponent<InfoCardProps> = ({
  requestId,
  requestVehicleType,
  requestStatus,
  requestCreationDate,
  requestCommitment,
  requestPrice,
}) => {
  const data = [
    {
      title: `${requestId}`,
      subtitle: "Identificador de pedido",
      color: orange,
      medium: true,
    },
    {
      title: `${requestVehicleType}`,
      subtitle: "Tipo de vehiculo",
      color: violet,
      medium: true,
    },
    {
      title: STATUSES[requestStatus],
      subtitle: "Estado",
      color: violet,
      medium: true,
    },
    { divider: true },
    {
      title: requestCreationDate,
      subtitle: "Fecha de solicitud",
      color: violet,
      medium: true,
    },
    {
      title: requestCommitment,
      subtitle: "Entrega aproximada",
      color: violet,
      medium: true,
    },
    {
      title: requestPrice,
      subtitle: "Monto",
      color: violet,
      medium: true,
    },
  ];
  return (
    <div className={styles.wrapper}>
      {data.map((props) =>
        props.divider ? (
          <Divider vertical key={uuidv4()} />
        ) : (
          <TextStack {...props} key={uuidv4()} />
        )
      )}
    </div>
  );
};

export default InfoCard;
