import { FunctionComponent } from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../constants/paths";
import box from "../../../assets/images/box.svg";
import { Button } from "../../../components";
import styles from "./EmptyState.module.scss";

export type EmptyStateProps = {};

const EmptyState: FunctionComponent<EmptyStateProps> = () => {
  const history = useHistory();

  const handleCreateRequest = () => {
    history.push(PATHS.CREATE_REQUEST);
  };

  return (
    <div className={styles.wrapper}>
      <img src={box} alt="" />

      <h3 className={styles.wrapper__title}>¡Hacé un pedido!</h3>

      <p className={styles.wrapper__message}>
        Comenzá a administrar tus pedidos, nubes y repartidores desde la
        plataforma fletti y quedáte tranqui.
      </p>

      <Button
        title="Nuevo Pedido"
        type="gradient"
        icon={<FaPlus />}
        onClick={handleCreateRequest}
      />
    </div>
  );
};

export default EmptyState;
