import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchUser,
  createBusinessRequest as createBusinessRequestService,
  getUserBusinessRequest as getUserBusinessRequestService,
} from "../../services/user";
import { getRolesFromJwt } from "../../utils/jwt";

const accessToken = localStorage.getItem("@access_token");

const initialState = {
  data: { business_profile: {}, general_profile: {} },
  businessRequest: null,
  isLogged: localStorage.getItem("@access_token") !== null,
  roles: getRolesFromJwt(accessToken),
  status: "idle",
  error: {
    register: {},
  },
};

export const getUser = createAsyncThunk("user/fetch", async () => {
  const response: any = await fetchUser();
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const createBusinessRequest = createAsyncThunk(
  "user/createBusinessRequest",
  async (data?: {}) => {
    const result: any = await createBusinessRequestService(data);
    return result?.data;
  }
);

export const getUserBusinessRequest = createAsyncThunk(
  "user/getUserBusinessRequest",
  async (userId: string) => {
    const result: any = await getUserBusinessRequestService(userId);
    return result?.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLogged(state, action: PayloadAction<boolean>) {
      state.isLogged = action.payload;
    },
    setUserRoles(state, action: PayloadAction<string[]>) {
      state.roles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })

      .addCase(createBusinessRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBusinessRequest.fulfilled, (state, action) => {
        state.status = "idle";

        const { payload } = action;

        if (payload) {
          if (payload.error && payload.error !== "") {
            state.error.register = payload;
          } else {
            state.businessRequest = payload.request;
            state.error.register = {};
          }
        }
      })

      .addCase(getUserBusinessRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserBusinessRequest.fulfilled, (state, action) => {
        state.status = "idle";
        const { payload } = action;

        if (payload) {
          if (payload.error && payload.error !== "") {
            state.error.register = payload;
          } else {
            state.businessRequest = payload.business_request;
            state.error.register = {};
          }
        }
      });
  },
});

export const { setIsLogged, setUserRoles } = userSlice.actions;

export default userSlice.reducer;
