import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { userIsManagerSelector } from "../../state/selectors/user";

import { SidebarItem, SidebarItemProps } from "./SidebarItem";
import { menuItems, configMenuItems, managerMenuItems } from "./menu-items";

import { Logo, Divider } from "..";

import { selectIsDeliveryFeatureActivated } from "../../state/selectors/cloud";
import { PATHS } from "../../constants";

import styles from "./Sidebar.module.scss";

const Sidebar = () => {
  const isDeliveryFeatureActivated = useSelector(
    selectIsDeliveryFeatureActivated
  );
  const isManager = useSelector(userIsManagerSelector);
  const filteredMenuItems = isDeliveryFeatureActivated ? menuItems : [];
  const filteredManagerMenuItems = isDeliveryFeatureActivated
    ? managerMenuItems
    : managerMenuItems.filter((item) => item.path !== PATHS.BUSINESSES);
  const filteredConfigMenuItems = isDeliveryFeatureActivated
    ? configMenuItems
    : configMenuItems.filter((item) => item.path !== PATHS.MELI_PROFILE);

  return (
    <nav className={styles.wrapper}>
      <Logo />
      <Divider />
      <ul className={styles.menu}>
        {filteredMenuItems.map((item: SidebarItemProps) => (
          <SidebarItem {...item} key={uuidv4()} />
        ))}

        {isManager &&
          filteredManagerMenuItems.map((item: SidebarItemProps) => (
            <SidebarItem {...item} key={uuidv4()} />
          ))}

        <h4 className={styles.menu__title}>CONFIGURACION</h4>

        {filteredConfigMenuItems.map((item: SidebarItemProps) => (
          <SidebarItem {...item} key={uuidv4()} />
        ))}
      </ul>
    </nav>
  );
};

export { Sidebar };
