import { FormStep } from "../../../types/FormStep";

import { Input, Dropdown } from "../../../components";
import { PERSONAL_IDENTIFICATION_TYPES } from "../../../constants";
import { User, USER_PROFILE_KEYS } from "../../../types";

const StepOne = ({
  handleInputChange,
  errors,
  data,
}: FormStep<User[USER_PROFILE_KEYS.GENERAL_PROFILE]>) => (
  <>
    <Input
      label="Nombre Completo"
      placeholder="Juan Carlos Perez"
      theme="card"
      onChange={handleInputChange("name")}
      value={data?.name}
      error={errors.name}
      required
    />

    <Input
      label="Correo Electrónico"
      placeholder="juancarlosperez@gmail.com"
      type="email"
      theme="card"
      onChange={handleInputChange("email")}
      value={data?.email}
      error={errors.email}
      required
    />

    <Dropdown
      label="Tipo de Documento"
      placeholder="Seleccione"
      theme="card"
      options={PERSONAL_IDENTIFICATION_TYPES}
      onChange={handleInputChange("identification_type")}
      value={data?.identification_type}
      required
    />

    <Input
      label="Número de Documento"
      placeholder="31313131"
      theme="card"
      onChange={handleInputChange("identification_number")}
      value={data?.identification_number}
      error={errors.identification_number}
      required
    />

    <Input
      label="Número de Celular"
      placeholder="11 3131 3131"
      theme="card"
      onChange={handleInputChange("phone")}
      value={data?.phone}
      error={errors.phone}
      required
    />
  </>
);

export default StepOne;
