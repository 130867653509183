import { FunctionComponent } from "react";
import dolly from "../../../assets/images/dolly.svg";
import { FaLink } from "@react-icons/all-files/fa/FaLink";

import { Button } from "../../../components";

import styles from "./EmptyState.module.scss";

export type EmptyStateProps = {
  action?: () => void;
};

const EmptyState: FunctionComponent<EmptyStateProps> = ({ action }) => {
  return (
    <div className={styles.wrapper}>
      <img src={dolly} alt="" />

      <h3 className={styles.wrapper__title}>¡Asociá un comercio ahora! </h3>

      <p className={styles.wrapper__message}>
        Comenzá a administrar tus comercios invitando a la empresa que quierás
        gestionar a unirse a fletti.
      </p>

      <Button
        title="Link de invitación"
        type="gradient"
        icon={<FaLink />}
        onClick={action}
      />
    </div>
  );
};

export default EmptyState;
