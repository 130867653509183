import React from "react";
import MagnifyingGlass from "../../assets/images/icons/magnifying_glass.svg";
import styles from "./Search.module.scss";

type Props = {
  placeholder?: string;
  onChange: (s: string) => void;
};

const Search = ({ placeholder = "Buscar...", onChange }: Props) => {
  return (
    <div className={styles.wrapper}>
      <img src={MagnifyingGlass} alt="search" className={styles.icon} />
      <input
        className={styles.input}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
      ></input>
    </div>
  );
};

export { Search };
