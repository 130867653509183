import { useDragControls, useAnimate, PanInfo } from "framer-motion";
import { Dispatch, SetStateAction, useState } from "react";

export const MAX_HEIGHT = window.innerHeight * 0.5 * -1;

const duration = 0.4;
const ease = "linear";

const useDraggableContainer = ({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [drawerYPosition, setDrawerPosition] = useState(0);
  const [scope, animate] = useAnimate();

  const controls = useDragControls();

  const openDrawer = async (releasePoint: number) => {
    await animate(
      "#drawer",
      {
        y: [releasePoint, MAX_HEIGHT],
      },
      {
        ease,
        duration,
      }
    );

    setOpen(true);
  };

  const closeDrawer = async (releasePoint: number) => {
    await animate(
      "#drawer",
      {
        y: [releasePoint, 0],
      },
      { ease, duration }
    );

    setOpen(false);
  };

  const handleDropDrawer = (evt: PointerEvent, panInfo: PanInfo) => {
    let releasePoint =
      panInfo.offset.y < 0
        ? panInfo.offset.y < MAX_HEIGHT
          ? MAX_HEIGHT
          : panInfo.offset.y // swipe up
        : panInfo.offset.y * -1; // swipe down

    releasePoint = releasePoint < MAX_HEIGHT ? 0 : releasePoint;

    setDrawerPosition(releasePoint);

    // if the offset is positive it means that I slide upwards
    const swipeUp = panInfo.offset.y > 0;

    if (swipeUp) {
      closeDrawer(releasePoint);
    } else {
      openDrawer(releasePoint);
    }
  };

  return {
    scope,
    controls,
    handleDropDrawer,
    drawerYPosition,
  };
};

export { useDraggableContainer };
