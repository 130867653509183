import { useState } from "react";
import { useSelector } from "react-redux";
import { updateUser } from "../../services/user";
import { userCloudIdSelector } from "../../state/selectors/user";
import { USER_PROFILE_KEYS, User, Cloud } from "../../types";
import { updateCloud } from "../../services/cloud";

const useSaveUser = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userCloudId = useSelector(userCloudIdSelector);

  const handleSavePersonalData = async (
    general_profile: User[USER_PROFILE_KEYS.GENERAL_PROFILE]
  ) => {
    setIsLoading(true);

    await updateUser({
      ...general_profile,
      identification_type: parseInt(
        general_profile?.identification_type as string
      ),
      identification_number: parseInt(general_profile?.identification_number!),
    });

    setIsLoading(false);
  };

  const handleSaveCloudData = async (cloudData: Cloud["cloud"]) => {
    setIsLoading(true);

    await updateCloud(userCloudId, {
      // @TODO [salva@06-06-2024] define if we could change Alias.
      // alias: cloudData.alias,
      business_name: cloudData.business_name,
      name: cloudData.name,
    });

    setIsLoading(false);
  };

  return {
    isLoading,
    handleSavePersonalData,
    handleSaveCloudData,
  };
};

export { useSaveUser };
