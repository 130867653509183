import { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { fetchShippers } from "../../../../services/shippers";

import { State } from "../../../../interfaces";
import { ShipperProps } from "../../types";

type FetchShipperProps = {
  id: string;
  general_profile: {
    name: string;
    photo: string;
  };
  shipper_profile: {
    available_now: boolean;
  };
  last_known_location: {
    point: {
      latitude: number;
      longitude: number;
    };
  };
};

type Props = {
  shippers: ShipperProps[];
  selectedShipperId: string | null;
};

type MapType = typeof google extends Object ? google.maps.Map : unknown;

const useTrackingMap = ({ shippers, selectedShipperId }: Props) => {
  const [mapShippers, setMapShippers] = useState<ShipperProps[]>([]);
  const [map, setMap] = useState<MapType | null>(null);
  const center = useSelector((state: State) => state?.shippers?.map?.mapCenter);
  const zoom: number = useSelector(
    (state: State) => state?.shippers?.map?.zoom
  );

  const activeShippers = useMemo(
    () =>
      mapShippers?.filter(
        (shipper: ShipperProps) => shipper.position.lat && shipper.position.lng
      ),
    [mapShippers]
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    // center map on the active markers.
    const bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < activeShippers.length; i++) {
      bounds.extend(activeShippers[i].position);
    }
    if (!selectedShipperId) {
      map.fitBounds(bounds);
    }
    if (activeShippers.length === 1) {
      map.setZoom(zoom);
    }

    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeShippers, selectedShipperId]);

  const loadMap = useCallback((map: MapType): void | Promise<void> => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (shippers.length === 0) {
      return;
    }
    if (shippers.length > 0) {
      setMapShippers(shippers);
    }

    const interval = setInterval(() => {
      fetchShippers().then((data: FetchShipperProps[]) => {
        const updatedShippers: ShipperProps[] = data
          ?.filter(
            (shipper: FetchShipperProps) =>
              shipper?.shipper_profile?.available_now
          )
          .map((shipper: FetchShipperProps) => {
            return {
              id: shipper.id,
              name: shipper.general_profile?.name,
              photo: shipper.general_profile?.photo,
              position: {
                lat: shipper.last_known_location!.point.latitude,
                lng: shipper.last_known_location!.point.longitude,
              },
            };
          });
        setMapShippers(updatedShippers);
      });
    }, 30000); // 30 seconds

    return () => clearInterval(interval);
  }, [shippers]);

  useEffect(() => {
    if (map && map.getZoom() !== zoom) {
      map.setZoom(zoom);
    }
  }, [center, map, zoom]);

  return { activeShippers, zoom, center, loadMap };
};

export { useTrackingMap };
