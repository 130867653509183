import isNaN from "lodash/isNaN";

const PHONE_MIN_LENGTH = 8;
const ID_MIN_LENGTH = 8;
const BUSINESS_ID_MIN_LENGTH = 11;

export const emptyValidator = (value: string | Set<string> | boolean) => {
  return null;
};
export const noEmpty = (value: string | Set<string> | boolean) => {
  let isEmpty = value === "";

  const isSet = value instanceof Set;

  if (typeof value === "boolean") {
    isEmpty = !value;
  }

  if (isSet) {
    isEmpty = value.size <= 0;
  }

  return isEmpty ? "Campo Requerido" : null;
};

export const emailValidator = (value: string | Set<string>) => {
  if (value instanceof Set) {
    return null;
  }
  if (value === "") {
    return null;
  }
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !value.match(re) ? "Ingrese un email valido" : null;
};

export const greaterEqualThanZero = (value: any) =>
  value < 0 ? "El valor deberia ser mayor o igual a 0" : null;

export const isNumber = (number: string) => {
  if (noEmpty(number) !== null) {
    return noEmpty(number);
  }

  return isNaN(Number(number)) ? "el dato ingresado no es un numero" : null;
};

const validateNumberAndLimit =
  (limit: number) =>
  (phone: string): string | null => {
    const numberValidation = isNumber(phone);

    if (numberValidation !== null) {
      return numberValidation;
    }

    return `${phone}`.length < limit
      ? `ingrese un numero de ${limit} caracteres.`
      : null;
  };

export const validatePhone = validateNumberAndLimit(PHONE_MIN_LENGTH);
export const validateID = validateNumberAndLimit(ID_MIN_LENGTH);
export const validateBusinessID = validateNumberAndLimit(
  BUSINESS_ID_MIN_LENGTH
);

export const onlyText = (value: any) =>
  /^[A-Za-z]+$/.test(value) || !value
    ? null
    : "El valor del campo debe incluir solo letras";
