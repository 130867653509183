import { useLayoutEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from "../constants";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useLayoutEffect(() => {
    // state updater.
    const updateSize = () =>
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);

    // add listener.
    window.addEventListener("resize", updateSize);

    // first render.
    updateSize();

    // remove listener on unmount.
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { isMobile };
};

export { useIsMobile };
