import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getShipperRequests } from "../../../state/slices/shippers";
import {
  shippersProfitAccumulatedSelector,
  shippersSelector,
} from "../../../state/selectors/shippers";

import { orange, violet } from "../../../constants/colors";
import { moneyFormatter } from "../../../utils/money";
import { selectIsDeliveryFeatureActivated } from "../../../state/selectors/cloud";
import { Shipper } from "../../../interfaces";

const useShippers = (): {
  shippers?: Shipper[] | null;
  summaryData?: Record<string, unknown>[];
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const shippers = useSelector(shippersSelector);
  const shippersProfitAccumulated = useSelector(
    shippersProfitAccumulatedSelector
  );
  const isDeliveryFeatureActivated = useSelector(
    selectIsDeliveryFeatureActivated
  );

  useEffect(() => {
    const fetchShipperAsync = async () => {
      setIsLoading(true);

      await dispatch(getShipperRequests());

      setTimeout(() => setIsLoading(false), 400);
    };

    if (shippers === null) {
      fetchShipperAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippers]);

  const baseData = [
    {
      title: shippers?.length,
      subtitle: "Repartidores",
      color: violet,
      xxl: true,
    },
  ];

  const summaryData = isDeliveryFeatureActivated
    ? [
        ...baseData,
        {
          title: shippers?.reduce((acc: number, shipper: any) => {
            return acc + (shipper.previous_itineraries?.length || 0);
          }, 0),
          subtitle: "Pedidos",
          color: violet,
          xxl: true,
        },
        {
          title: moneyFormatter(shippersProfitAccumulated || 0),
          subtitle: "Saldo Actual",
          color: orange,
          xxl: true,
        },
      ]
    : baseData;

  return {
    summaryData,
    shippers,
    isLoading,
  };
};

export { useShippers };
