import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";

import { getRequests } from "../../state/slices/request";
import { requestHistorySelector } from "../../state/selectors/requests";

import { PATHS } from "../../constants/paths";

import {
  Layout,
  Divider,
  List,
  ListSkeleton,
  Card,
  Button,
  TextStack,
  LoadingOrContent,
} from "../../components";

import { orange, violet } from "../../constants/colors";
import EmptyState from "../RequestsInProgress/EmptyState";

import styles from "./History.module.scss";
import { userSelector } from "../../state/selectors/user";
import { STATUSES } from "../../constants/requests";
import useQuery from "../../hooks/useQuery";
import HistorySearchBar from "./HistorySearchBar";
import { moneyFormatter } from "../../utils/money";

// @TODO [salva@11-05-2924] [#253] do we still needing this route?
const History: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = useQuery();
  const [shipperId, setShipperId] = useState(
    queryParams.get("shipperID") || undefined
  );
  const [businessId, setBusinessId] = useState(
    queryParams.get("businessID") || undefined
  );

  const user = useSelector(userSelector);

  const { requests, loadingRequest } = useSelector((store: any) => ({
    requests: requestHistorySelector(store),
    loadingRequest: store.request.status["requests/get"] === "loading",
  }));

  const [filterStatus, setFilterStatus] = useState<any>();
  const [filterDateRange, setFilterDateRange] = useState<any>();

  useEffect(() => {
    if (user) {
      dispatch(
        getRequests({
          since: filterDateRange?.value || "1w",
          status: STATUSES.indexOf(filterStatus?.value || ""),
          shipperID: shipperId,
          businessID: businessId,
        })
      );
    }
  }, [dispatch, user, filterDateRange, filterStatus, shipperId, businessId]);

  const handleCreateRequest = () => {
    history.push(PATHS.CREATE_REQUEST);
  };

  const handleNavigateToRequest = (requestId: string) => () => {
    history.push(PATHS.REQUEST.replace(":requestId", requestId));
  };

  const summaryData = [
    {
      title: requests.reduce((acc: any, request: any) => {
        if (request.shipperID) {
          return acc.add(request.shipperID);
        }
        return acc;
      }, new Set()).size,
      subtitle: "Nº de Repartidores",
      color: violet,
      xxl: true,
    },
    {
      title: requests?.length,
      subtitle: "Nº de Pedidos",
      color: violet,
      xxl: true,
    },
    {
      title: moneyFormatter(
        requests.reduce((acc: number, request: any) => {
          return acc + request.requestTotalPrice;
        }, 0)
      ),
      subtitle: "Total Pedidos",
      color: orange,
      xxl: true,
    },
  ];

  return (
    <Layout
      headerAction={
        <Button
          title="Nuevo Pedido"
          type="gradient"
          icon={<FaPlus />}
          onClick={handleCreateRequest}
        />
      }
      summaryData={summaryData}
    >
      <Divider title="Mis Pedidos" />

      <LoadingOrContent
        loading={loadingRequest}
        customLoading={<ListSkeleton />}
      >
        <List
          data={requests}
          searchComponent={() => (
            <HistorySearchBar
              dateRange={filterDateRange}
              setFilterDateRange={setFilterDateRange}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              shipperId={shipperId}
              setShipperId={setShipperId}
              businessId={businessId}
              setBusinessId={setBusinessId}
            />
          )}
          itemComponent={(request: any) => (
            <Card className={styles["item-container"]}>
              <>
                {Object.values(request.summary).map((props: any) => (
                  <TextStack {...props} key={uuidv4()} />
                ))}
              </>

              <Button
                icon={<FaEye />}
                color={orange}
                onClick={handleNavigateToRequest(request.id)}
              />
            </Card>
          )}
          emptyState={<EmptyState />}
        />
      </LoadingOrContent>
    </Layout>
  );
};

export { History };
