import Alert from "../../../../assets/images/icons/alert.svg";
import DotViolet from "../../../../assets/images/icons/dot_violet.svg";
import DotGray from "../../../../assets/images/icons/dot_gray.svg";
import { useState } from "react";
export enum ShipperStatus {
  AVAILABLE = "AVAILABLE",
  OFFLINE = "OFFLINE",
  PENDING = "PENDING",
}
const useTrackingShipperItem = (shipper_profile: any) => {
  const [visible, setVisible] = useState(false);
  const status: ShipperStatus = !shipper_profile
    ? ShipperStatus.PENDING
    : shipper_profile.available_now
    ? ShipperStatus.AVAILABLE
    : ShipperStatus.OFFLINE;

  const theme = {
    AVAILABLE: "list-card--status",
    OFFLINE: "list-card--status--offline",
    PENDING: "list-card--status--pending",
  };
  const icon = {
    AVAILABLE: <img src={DotViolet} alt="dot" />,
    OFFLINE: <img src={DotGray} alt="dot" />,
    PENDING: <img src={Alert} alt="alert" />,
  };
  const closeMenu = () => setVisible(false);
  const toggleMenu = () => setVisible((visible) => !visible);
  return { visible, toggleMenu, closeMenu, icon, theme, status };
};

export { useTrackingShipperItem };
