import ReactGA from "react-ga4";
import { GaEvent } from "../types";

const isDevelop = "development" === process.env.NODE_ENV;

/**
 *
 * @returns ReactGA Wrapper.
 */
const useGATracking = () => {
  /**
   * Page view Wrapper
   * @param path
   * @param trackerNames
   * @param title
   * @returns
   */
  const trackGAPageView = ({
    path,
    trackerNames,
    title,
  }: {
    path: string;
    trackerNames?: [];
    title?: string;
  }) => {
    if (isDevelop) return;

    ReactGA.send({ hitType: "pageview", path });
  };

  /**
   * Event Wrapper
   * @param category
   * @param action
   * @param label
   * @returns
   */
  const trackGAEvent = ({ category, action, label }: GaEvent) => {
    if (isDevelop) return;

    ReactGA.event({ category, action, label });
  };

  return { trackGAPageView, trackGAEvent };
};

export { useGATracking };
