import { Logo } from "../../components";
import styles from "./Policy.module.scss";

const Policy = (): JSX.Element => {
  const copyright_text =
    "Versión de la política de privacidad: 1.0 Fecha de publicación: 25/03/2022";

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__logo}>
        <Logo />
      </div>

      <div className={styles.wrapper__policy}>
        <h1>Política de privacidad de la Aplicación Fletti</h1>
        <p className={styles.wrapper__policy__text}>
          Para recibir la información sobre sus Datos Personales, la finalidad y
          las partes con las que se comparte, contacten con el Titular.
        </p>

        <h2>Responsable de Tratamiento de los Datos y Titular</h2>
        <h3>Clases de Datos recogidos</h3>

        <p className={styles.wrapper__policy__text}>
          El sistema obtiene datos básicos del usuario, como su nombre y email
          con el único fin de generar un canal de comunicación efectivo.
        </p>
        <p className={styles.wrapper__policy__text}>
          Además, se le solicita permiso al usuario para el uso de la cámara y
          el acceso a las imágenes del dispositivo con el fin de permitir al
          usuario la carga de una foto de perfil dentro del sistema, la cual no
          se utilizará para otro fin que no sea el estrictamente asociado a la
          gestión de los envíos.
        </p>
        <p className={styles.wrapper__policy__text}>
          El sistema registra la ubicación en tiempo real de todo Shipper,
          únicamente mientras se encuentre activo, aún mientras la aplicación se
          encuentre cerrada. Esto permite: * Recibir las ofertas mas
          convenientes * Brindarle al usuario información precisa de su envío
        </p>

        <h3>Modalidad y lugar del Tratamiento de los Datos recogidos</h3>

        <h4>Modalidades de Tratamiento</h4>
        <p className={styles.wrapper__policy__text}>
          El Responsable de Tratamiento tratará los Datos de los Usuarios de
          manera adecuada y adoptará las medidas de seguridad apropiadas para
          impedir el acceso, la revelación, alteración o destrucción no
          autorizados de los Datos.
        </p>
        <p className={styles.wrapper__policy__text}>
          El tratamiento de Datos se realiza mediante ordenadores y/o
          herramientas informáticas, siguiendo procedimientos y modalidades
          organizativas estrictamente relacionadas con las finalidades
          señaladas. Además del Responsable de Tratamiento, en algunos casos
          podrán acceder a los Datos ciertas categorías de personas encargadas
          relacionadas con el funcionamiento de la página (administración,
          ventas, marketing, departamento jurídico y de administración de
          sistemas) o contratistas externos que presten servicios al Responsable
          de Tratamiento (tales como proveedores externos de servicios técnicos,
          empresas de mensajería, "hosting providers", empresas de informática,
          agencias de comunicación) que serán nombrados por el Titular como
          Encargados del Tratamiento, si fuera necesario. Se podrá solicitar al
          Responsable de Tratamiento en cualquier momento una lista actualizada
          de dichas personas.
        </p>

        <h4>Datos Personales (o Datos)</h4>
        <p className={styles.wrapper__policy__text}>
          Constituye un dato personal cualquier información relativa a una
          persona física o jurídica, identificada o identificable, aunque sea
          indirectamente, mediante referencia a cualquier otra información.
        </p>

        <h4>Lugar</h4>
        <p className={styles.wrapper__policy__text}>
          Los Datos serán tratados en la sede operativa del Responsable de
          Tratamiento, así como en otros lugares en los que se encuentren
          situadas las partes que también están involucradas en dicho
          tratamiento. Para más información, por favor póngase en contacto con
          el Responsable de Tratamiento.
        </p>

        <h4>Período de conservación</h4>

        <p className={styles.wrapper__policy__text}>
          Los Datos serán tratados durante el plazo necesario para prestar el
          servicio solicitado por el Usuario, o el que se requiera en función de
          las finalidades descritas en este documento, y el Usuario tendrá en
          todo momento la facultad de solicitar la interrupción de su
          Tratamiento o la cancelación de los Datos.
        </p>

        <h3>
          Información adicional sobre la recogida de Datos y su tratamiento
        </h3>

        <h4>Defensa jurídica</h4>

        <p className={styles.wrapper__policy__text}>
          Los Datos Personales del Usuario podrán ser utilizados para la defensa
          jurídica del Titular en juicio o en las fases prejudiciales previas a
          un posible pleito derivado del uso abusivo por parte del Usuario de
          estas Aplicaciones o de los servicios relacionados.
        </p>

        <p className={styles.wrapper__policy__text}>
          El Usuario es consciente de que el Titular puede ser requerido por
          autoridades públicas a fin de revelar Datos Personales.
        </p>

        <h4>
          Información adicional acerca de los Datos Personales del Usuario
        </h4>
        <p className={styles.wrapper__policy__text}>
          Además de las informaciones contenidas en esta política de privacidad,
          estas Aplicaciones podrá proporcionar al Usuario información
          contextual relativa a servicios específicos o a la recogida y
          tratamiento de los Datos Personales.
        </p>

        <h4>Log del sistema y mantenimiento</h4>
        <p className={styles.wrapper__policy__text}>
          Por motivos relativos al funcionamiento y el mantenimiento, estas
          Aplicaciones y cualesquiera otros servicios de terceros que se
          utilicen podrán recoger un Log del sistema, esto es, archivos que
          registren las interacciones y que pueden contener Datos Personales,
          tales como la dirección IP del Usuario.
        </p>

        <h4>Información no contenida en esta política de privacidad</h4>
        <p className={styles.wrapper__policy__text}>
          Se podrá solicitar en cualquier momento información adicional sobre la
          recogida y el tratamiento de los Datos Personales al Responsable de
          Tratamiento. Encontraró la información de contacto al inicio del
          presente documento.
        </p>

        <p className={styles.wrapper__policy__text}></p>
        <h4>Ejercicio de los derechos por los Titulares de los Datos</h4>
        <p className={styles.wrapper__policy__text}>
          Los titulares a los que se refieren los Datos Personales tienen
          derecho a obtener en cualquier momento la confirmación de que estos
          han sido almacenados por el Responsable de Tratamiento, a conocer su
          contenido y origen, a verificar su exactitud o solicitar que sean
          completados, cancelados, actualizados o rectificados, a que sean
          anonimizados o a que se bloqueen aquellos Datos Personales que están
          siendo tratados en contravención de las leyes, así como a oponerse a
          su tratamiento por cualquier motivo legítimo. Las solicitudes deberán
          remitirse al Responsable de Tratamiento utilizando los datos de
          contacto indicados anteriormente.
        </p>
        <p className={styles.wrapper__policy__text}>
          Estas Aplicaciones no permite solicitudes "Do Not Track".
        </p>
        <p className={styles.wrapper__policy__text}>
          Para determinar si cualquiera de los servicios de terceros que utiliza
          acepta solicitudes "Do Not Track", por favor lea sus políticas de
          privacidad.
        </p>

        <h4>Modificación de esta política de privacidad</h4>
        <p className={styles.wrapper__policy__text}>
          El Responsable de Tratamiento se reserva el derecho de modificar esta
          política de privacidad en cualquier momento notificando a los Usuarios
          a través de esta página. Se recomienda encarecidamente que revisen
          esta página con frecuencia, tomando como referencia la fecha de la
          última modificación indicada al final. En el caso de que un Usuario
          está en desacuerdo con alguna de las modificaciones realizadas a esta
          Política, el Usuario deberá cesar en el uso de estas Aplicaciones y
          podrá solicitar al Responsable de Tratamiento que elimine sus Datos
          Personales. Salvo que se indique lo contrario, la política de
          privacidad vigente en cada momento será de aplicación a todos los
          Datos Personales que el Responsable de Tratamiento haya recogido hasta
          entonces.
        </p>

        <h4>Definiciones y referencias legales</h4>

        <ul>
          <li>
            <b>Datos Personales (o Datos)</b>
            <p className={styles.wrapper__policy__text}>
              Constituye un dato personal cualquier información relativa a una
              persona física, identificada o identificable, aunque sea
              indirectamente, mediante referencia a cualquier otra información,
              inclusive a un número de identificación personal.
            </p>
          </li>

          <li>
            <b>Datos de Uso</b>
            <p className={styles.wrapper__policy__text}>
              Las informaciones recogidas de forma automática por estas
              Aplicaciones (o por servicios de terceros utilizados por estas
              Aplicaciones), que podrón incluir: las direcciones IP o nombres de
              dominio de los ordenadores utilizados por el Usuario que se
              conecte a estas Aplicaciones, las direcciones URI (Uniform
              Resource Identifier), la hora de la solicitud, el método utilizado
              para realizar la solicitud al servidor, las dimensiones del
              archivo obtenido en respuesta, el código numérico indicando el
              estado de la respuesta del servidor (resultado satisfactorio,
              error, etc.), el país de origen, las características del navegador
              y del sistema operativo utilizados por el visitante, las diversas
              coordenadas temporales de la visita (por ejemplo, el tiempo de
              permanencia en cada una de las páginas) y los detalles relativos
              al itinerario seguido dentro de la Aplicación, con especial
              referencia a la secuencia de páginas consultadas, a los parámetros
              relativos al sistema operativo y al entorno informático del
              Usuario.
            </p>
          </li>

          <li>
            <b>Usuario</b>
            <p className={styles.wrapper__policy__text}>
              El individuo que utiliza estas Aplicaciones, que debe coincidir
              con el Titular de los Datos o bien haber sido autorizado por éste
              y cuyos Datos Personales son objeto de tratamiento.
            </p>
          </li>

          <li>
            <b>Titular de los Datos</b>
            <p className={styles.wrapper__policy__text}>
              La persona física o jurídica a la que se refieren los Datos
              Personales.
            </p>
          </li>

          <li>
            <b>Encargado del Tratamiento</b>
            <p className={styles.wrapper__policy__text}>
              La persona física, jurídica, administración pública o cualquier
              otra institución, asociación u organización autorizada por el
              Responsable de Tratamiento a tratar los Datos Personales de
              conformidad con esta política de privacidad.
            </p>
          </li>

          <li>
            <b>Responsable de Tratamiento (o Titular)</b>
            <p className={styles.wrapper__policy__text}>
              La persona física, jurídica, administración pública o cualquier
              otra institución, asociación u organización con la facultad,
              incluso solidariamente con otro Responsable de Tratamiento, de
              tomar decisiones relativas a la finalidad, los métodos de
              tratamiento de los Datos Personales y los medios utilizados,
              incluyendo las medidas de seguridad relativas al funcionamiento y
              el uso de estas Aplicaciones. A menos que se especifique lo
              contrario, el Responsable de Tratamiento es el Titular de estas
              Aplicaciones.
            </p>
          </li>

          <li>
            <b>Estas Aplicaciones</b>
            <p className={styles.wrapper__policy__text}>
              Cualquier herramienta de software brinda por la empresa mediante
              la cual se han recogidos los Datos Personales del Usuario.
            </p>
          </li>

          <li>
            <b>Información legal</b>
            <p className={styles.wrapper__policy__text}>
              En relación con esta Política de Privacidad rigen las
              disposiciones de la Ley No 25.326 de Protección de Datos
              Personales y sus normas complementarias. La Autoridad de
              Aplicación es la Dirección Nacional de Protección de Datos
              Personales, dependiente del Ministerio de Justicia y Derecho
              Humanos de la Nación. En caso de surgir cualquier controversia
              respecto de la interpretación o cumplimiento de la presente, la
              misma será dirimida por los Tribunales en lo Contencioso
              Administrativo Federal con asiento en la Capital Federal. Esta
              política de privacidad se refiere únicamente a las aplicaciones
              Android de Fletti publicadas en Google Play Store{" "}
            </p>
          </li>
        </ul>
      </div>
      <footer className={styles.wrapper__footer}>
        <div className={styles.wrapper__footer__copyright}>
          <Logo className={styles.wrapper__footer__copyright__logo} />
        </div>

        <p className={styles.wrapper__footer__text}>{copyright_text}</p>
      </footer>
    </div>
  );
};

export { Policy };
