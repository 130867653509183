import moment from "moment";

export const dateAddMinutes = (value: string, minutesToAdd: number) => {
  return moment(value).add(minutesToAdd, "minutes");
};

export const dateTimeFormatter = (value: string) => {
  if (!value) return "No Disponible";
  return moment(value).format("DD/MM/YY HH:mm");
};

export const dateFormatter = (value: string) => {
  if (!value) return "No Disponible";
  return moment(value).format("DD/MM/YY");
};

export const timeFormatter = (value: string | moment.Moment) => {
  if (!value) return "No Disponible";
  return moment(value).format("HH:mm");
};
