import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getRequests } from "../../state/slices/request";
import { requestInProgressSelector } from "../../state/selectors/requests";

import { PATHS } from "../../constants/paths";

import {
  Layout,
  List,
  TextStack,
  Button,
  Divider,
  Card,
} from "../../components";

import EmptyState from "./EmptyState";

import { orange } from "../../constants/colors";
import { userSelector } from "../../state/selectors/user";
import { getBusinessPayments } from "../../state/slices/businesses";
import InfoCard from "./InfoCard";

import styles from "./RequestsInProgress.module.scss";

interface Props {}

const RequestsInProgress: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(userSelector);
  const requests = useSelector(requestInProgressSelector);

  useEffect(() => {
    if (user.id) {
      dispatch(getRequests({ since: "1d" }));
      dispatch(getBusinessPayments());
    }
  }, [dispatch, user]);

  const handleCreateRequest = () => {
    history.push(PATHS.CREATE_REQUEST);
  };

  const handleNavigateToRequest = (requestId: string) => () => {
    history.push(PATHS.REQUEST.replace(":requestId", requestId));
  };

  return (
    <Layout
      className={styles.wrapper}
      headerAction={
        <Button
          title="Nuevo Pedido"
          type="gradient"
          icon={<FaPlus />}
          onClick={handleCreateRequest}
        />
      }
      infoCard={<InfoCard requests={requests} />}
    >
      <Divider title="Pedidos en curso" />

      <List
        noSearch
        data={requests}
        itemComponent={(request: any) => (
          <Card className={styles["item-container"]}>
            <>
              {Object.values(request.summary).map((props: any) => (
                <TextStack {...props} key={uuidv4()} />
              ))}
            </>

            <Button
              icon={<FaEye />}
              color={orange}
              onClick={handleNavigateToRequest(request.id)}
            />
          </Card>
        )}
        emptyState={<EmptyState />}
      />
    </Layout>
  );
};

export { RequestsInProgress };
