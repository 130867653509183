import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

import { Shipper } from "../../../interfaces";
import { GA_EVENTS, toastConfig } from "../../../constants";

import { activeTrackingShippersSelector } from "../../../state/selectors/shippers";
import {
  getShipperPayments,
  getShipperRequests,
  getShippers,
  updateMapCenter,
} from "../../../state/slices/shippers";
import { acceptShipper, declineShipper } from "../../../services/shippers";
import { ShipperStatuses, UseTrackingScreenOutput } from "../types";
import { useGATracking } from "../../../hooks";

// @TODO [salva@12-03-2024] split this hook, resolves to many stuff.
const useTrackingScreen = ({
  data,
}: {
  data?: Shipper[] | null;
}): UseTrackingScreenOutput => {
  //data: Shippers actives and inactives
  const dispatch = useDispatch();
  const activeTrackingShippers = useSelector(activeTrackingShippersSelector);
  const [isListCollapsed, setIsListCollapsed] = useState(false);
  const [hoveredShipperId, setHoveredShipperId] = useState<string | null>(null);
  const [selectedShipperId, setSelectedShipperId] = useState<string | null>(
    null
  );
  const [searchName, setSearchName] = useState<string>("");
  const [showFilters, setShowFilters] = useState(false);
  const [showAcceptShipperModal, setShowAcceptShipperModal] = useState(false);
  const [shipperStatusToFilter, setShipperStatusToFilter] = useState<any>({
    available: true,
    no_available: true,
  });
  const [shipperAcceptanceLoading, setShipperAcceptanceLoading] =
    useState<boolean>(false);

  const hasFilters =
    Object.values(shipperStatusToFilter).some((value) => value === false) ||
    !!searchName;

  const selectedShipper: Shipper | undefined = useMemo(
    () => data?.find((shipper: Shipper) => shipper.id === selectedShipperId),
    [data, selectedShipperId]
  );

  const { trackGAEvent } = useGATracking();

  const applyStatusFilter = (
    shippers: any,
    { available, no_available }: { available: boolean; no_available: boolean }
  ) => {
    if (available && no_available) {
      return shippers; // Mostrar todos los datos
    } else if (available) {
      return shippers.filter(
        (shipper: any) => shipper.shipper_profile?.available_now === true
      ); // Mostrar solo disponibles
    } else if (no_available) {
      return shippers.filter(
        (shipper: any) => shipper.shipper_profile?.available_now === false
      ); // Mostrar solo no disponibles
    }
    return []; // Mostrar array vacío si ambos filtros están desactivados
  };

  const applyNameFilter = (shippers: any, searchName: string) => {
    if (!searchName) return shippers; // No need to filter if searchName is empty
    return shippers.filter(
      (shipper: any) =>
        shipper.fullname?.toLowerCase().includes(searchName.toLowerCase()) ||
        shipper.general_profile?.name
          ?.toLowerCase()
          .includes(searchName.toLowerCase())
    );
  };

  const filteredData = useMemo(() => {
    const filteredByStatus = applyStatusFilter(data, shipperStatusToFilter);
    const filteredByName = applyNameFilter(filteredByStatus, searchName);
    return filteredByName;
  }, [data, searchName, shipperStatusToFilter]);

  const toggleListCollapse = () => {
    setIsListCollapsed(!isListCollapsed);
  };
  const handleInputChange = (value: string) => {
    // debounce ga call while typing.
    debounce(() => trackGAEvent(GA_EVENTS.SHIPPERS.SEARCHING_BY_TEXT), 400);
    setSearchName(value);
  };
  const handleShowFilters = (value: boolean) => {
    setShowFilters(value);
  };
  const handleChangeShipperStatusToFilter = (
    key: ShipperStatuses,
    value: boolean
  ) => {
    setShipperStatusToFilter({ ...shipperStatusToFilter, [key]: value });
  };

  const handleMarkerClick = (shipperId: string | undefined) => {
    if (shipperId) {
      setSelectedShipperId(shipperId);
      const listItem = document.getElementById(shipperId);
      if (listItem) {
        listItem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const handleOnClickShipperItem = (shipper: Shipper) => {
    const shipper_location: { lng: number; lat: number } = {
      lng: shipper.last_known_location?.point.longitude as number,
      lat: shipper.last_known_location?.point.latitude as number,
    };

    if (shipper.shipper_profile?.available_now && shipper.id) {
      dispatch(updateMapCenter({ center: shipper_location, zoom: 14 }));
      setSelectedShipperId(shipper.id);
    }

    if (!shipper.shipper_profile && shipper.id) {
      setSelectedShipperId(shipper.id);
      setShowAcceptShipperModal(true);
    }
  };

  const handleOnHoverShipperItem = (shipperId?: string | null) => {
    setHoveredShipperId(shipperId || null);
  };

  const handleShowAcceptShipperModal = (value: boolean) => {
    setShowAcceptShipperModal(value);
  };

  const handleAcceptShipper = async () => {
    setShipperAcceptanceLoading(true);

    try {
      await acceptShipper({ id: selectedShipperId });

      dispatch(getShippers());
      dispatch(getShipperRequests());
      dispatch(getShipperPayments());
    } catch (e) {
      toast.error("Ocurrio un error.", toastConfig);
    }
    setShipperAcceptanceLoading(false);
    setShowAcceptShipperModal(false);
  };

  const handleDeclineShipper = async () => {
    setShipperAcceptanceLoading(true);
    try {
      await declineShipper({ id: selectedShipperId });

      dispatch(getShippers());
      dispatch(getShipperRequests());
      dispatch(getShipperPayments());
    } catch (e) {
      toast.error("Ocurrio un error.", toastConfig);
    }

    setShipperAcceptanceLoading(false);
    setShowAcceptShipperModal(false);
  };

  return {
    activeTrackingShippers,
    filteredData,
    handleAcceptShipper,
    handleChangeShipperStatusToFilter,
    handleDeclineShipper,
    handleInputChange,
    handleMarkerClick,
    handleOnClickShipperItem,
    handleOnHoverShipperItem,
    handleShowAcceptShipperModal,
    handleShowFilters,
    hasFilters,
    hoveredShipperId,
    isListCollapsed,
    selectedShipper,
    selectedShipperId,
    shipperAcceptanceLoading,
    shipperStatusToFilter,
    showAcceptShipperModal,
    showFilters,
    toggleListCollapse,
  };
};

export { useTrackingScreen };
