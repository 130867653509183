import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";

import {
  Layout,
  Card,
  Map,
  Chat,
  Button,
  LoadingOrContent,
  RequestStopsDetails,
  Skeleton,
  SkeletonGroup,
} from "../../components";

import Status from "./components/Status";
import InfoCard from "./components/InfoCard";
import ShipperDetails from "./components/ShipperDetails";

import useRequest from "./useRequest";
import styles from "./Request.module.scss";

export type RequestProps = {
  match: any;
};

const Request = ({ match }: RequestProps): JSX.Element => {
  const {
    stops,
    currentStop,
    requestDistance,
    currentItinerary,
    history,
    polyline,
    requestId,
    requestVehicleType,
    shipper,
    tracking,
    shipperPolyline,
    requestItineraryID,
    requestStatus,
    requestCreationDate,
    requestCommitment,
    requestPrice,
    isLoading,
    shouldSearchAvailability,
  } = useRequest({
    match,
  });

  return (
    <Layout
      className={styles.wrapper}
      headerAction={
        <Button
          title="Ir atrás"
          onClick={history.goBack}
          icon={<FaChevronLeft color="#fff" />}
          className={styles.button}
          type="clear"
        />
      }
      infoCard={
        <InfoCard
          requestId={requestId}
          requestVehicleType={requestVehicleType}
          requestStatus={requestStatus}
          requestCreationDate={requestCreationDate}
          requestCommitment={requestCommitment}
          requestPrice={requestPrice}
        />
      }
    >
      <LoadingOrContent
        loading={isLoading}
        customLoading={
          <Card className={styles["status-skeleton"]}>
            <SkeletonGroup
              containerStyles={styles["status-skeleton__container"]}
            >
              {[1, 2, 3, 4, 5].map(() => (
                <SkeletonGroup
                  containerStyles={styles["status-skeleton__item"]}
                >
                  <Skeleton height="50px" width="50px" circle />
                  <Skeleton width="120px" />
                </SkeletonGroup>
              ))}
            </SkeletonGroup>
          </Card>
        }
      >
        <Status
          className={styles.wrapper__status}
          status={requestStatus}
          stops={stops}
        />
      </LoadingOrContent>

      <div className={styles["wrapper__first-row"]}>
        <LoadingOrContent
          loading={isLoading}
          customLoading={
            <Card className={styles["wrapper__first-row__skeleton"]}>
              {[1, 2, 3].map(() => (
                <>
                  <SkeletonGroup>
                    <Skeleton circle width="30px" />
                    <Skeleton />
                  </SkeletonGroup>

                  {[1, 2, 3, 4].map(() => (
                    <Skeleton
                      containerStyle={
                        styles["wrapper__first-row__skeleton__line"]
                      }
                    />
                  ))}
                </>
              ))}
            </Card>
          }
        >
          <RequestStopsDetails
            stops={stops}
            currentStop={currentStop}
            requestDistance={requestDistance}
          />
        </LoadingOrContent>

        <Card>
          <h5>Ubicación</h5>

          <LoadingOrContent
            loading={isLoading}
            customLoading={<Skeleton square width="600px" />}
          >
            <Map
              stops={stops}
              routeCords={polyline}
              shipperRouteCords={shipperPolyline}
              tracking={tracking}
            />
          </LoadingOrContent>
        </Card>
      </div>

      <div className={styles["wrapper__second-row"]}>
        <Card>
          <LoadingOrContent
            loading={isLoading}
            customLoading={
              <>
                <Skeleton />

                <SkeletonGroup
                  containerStyles={
                    styles["wrapper__second-row__skeleton__avatar"]
                  }
                >
                  <Skeleton circle width="50px" />
                  <Skeleton />
                </SkeletonGroup>

                <Skeleton />
                <Skeleton />
              </>
            }
          >
            <ShipperDetails
              shipper={shipper}
              requestId={requestId}
              shouldSearchAvailability={shouldSearchAvailability}
            />
          </LoadingOrContent>
        </Card>

        {currentItinerary && (
          <Card>
            <Chat
              itinerary={currentItinerary}
              itineraryId={requestItineraryID}
            />
          </Card>
        )}
      </div>
    </Layout>
  );
};

export { Request };
