import { useSelector } from "react-redux";
import useValidations, { isRequired } from "use-validations";

import { State } from "../../interfaces";
import { Cloud } from "../../types";
import { emailValidator } from "../../utils/validators";
import { Button, Input } from "../../components";

import { useSaveUser } from "./useSaveUser";
import styles from "./DataForm.module.scss";

const initialData: Cloud["cloud"] = {
  name: "",
  alias: "",
  business_name: "",
  identification_type: 0,
  identification_number: 0,
  address: {
    feature_name: "",
    address_lines: [],
    max_address_line_index: 0,
    admin_area: "",
    sub_admin_area: "",
    locality: "",
    sub_locality: "",
    thoroughfare: "",
    sub_thoroughfare: "",
    premises: "",
    postal_code: "",
    country_code: "",
    country_name: "",
    latitude: 0,
    longitude: 0,
    has_latitude: false,
    has_longitude: false,
    phone: "",
    url: "",
  },
  owner_id: "",
  working_mode: 0,
  modules: [],
  sales: undefined,
};

const CloudDataForm = (): JSX.Element => {
  const cloudData = useSelector((state: State) => state.cloud);

  const { errors, data, handleInputChange } = useValidations<Cloud["cloud"]>({
    defaultData: cloudData.cloud || initialData,
    validators: {
      name: isRequired,
      email: emailValidator,
      identification_type: isRequired,
    },
  });

  const { handleSaveCloudData, isLoading } = useSaveUser();

  const handleSaveCloud = () => {
    if (data) {
      const completeData = { ...initialData, ...data };
      handleSaveCloudData(completeData);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Input
          label="Nombre"
          placeholder="Transporte Manolo"
          theme="card"
          onChange={handleInputChange("name")}
          value={data?.name}
          error={errors?.name}
          required
        />

        {/* @TODO [salva@06-06-2024] define if we could change Alias. */}
        {/* <Input
          label="Alias"
          placeholder="TRMA"
          theme="card"
          onChange={handleInputChange("alias")}
          value={data?.alias}
          error={errors?.name}
          required
        /> */}

        <Input
          label="Nombre de Negocio"
          theme="card"
          onChange={handleInputChange("business_name")}
          value={data?.business_name}
          error={errors?.business_name}
          required
        />
      </div>

      <Button
        title="Guardar"
        type="gradient"
        className={styles.button}
        onClick={handleSaveCloud}
        loading={isLoading}
        xxl
      />
    </>
  );
};

export { CloudDataForm };
