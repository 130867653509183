import { useMemo } from "react";
import es from "date-fns/locale/es";
import { formatDistanceToNow } from "date-fns";
import { Tooltip } from "@mui/material";

import Telephone from "../../../../assets/images/icons/telephone.svg";
import DefaultImage from "../../../../assets/images/user.svg";
import ThreePoints from "../../../../assets/images/icons/three_points.svg";

import { Shipper } from "../../../../interfaces";
import { formatPhoneNumber } from "../../../../utils/telephone";

import {
  ShipperStatus,
  useTrackingShipperItem,
} from "./useTrackingShipperItem";
import TrackingShipperItemMenu from "./components/TrackingShipperItemMenu/TrackingShipperItemMenu";

import styles from "./ShipperItem.module.scss";

type Props = {
  shipper: Shipper;
  isCollapsed: boolean;
  isSelected: boolean;
  onClick: () => void;
  onHover: () => void;
  onHoverLeave: () => void;
};

const ShipperItem = ({
  shipper: {
    id,
    general_profile,
    shipper_profile,
    fullname,
    last_known_location,
  },
  isCollapsed,
  isSelected = false,
  onClick,
  onHover,
  onHoverLeave,
}: Props) => {
  const { visible, toggleMenu, closeMenu, icon, theme, status } =
    useTrackingShipperItem(shipper_profile);

  const last_location = useMemo(() => {
    if (!last_known_location?.last_updated) return;
    if (last_known_location?.last_updated === "0001-01-01T00:00:00Z") return;

    const diff = formatDistanceToNow(last_known_location.last_updated, {
      locale: es as any,
    });

    return diff;
  }, [last_known_location?.last_updated]);

  const no_location_time = useMemo(() => {
    if (!shipper_profile?.available_now) return 0;
    if (!last_known_location?.last_updated) return 0;
    if (last_known_location?.last_updated === "0001-01-01T00:00:00Z") return 0;

    const lastUpdated = new Date(last_known_location.last_updated).getTime();
    const now = Date.now();
    const diffMs = now - lastUpdated;
    const diffM = Math.floor(diffMs / 60000);

    return diffM;
  }, [last_known_location?.last_updated, shipper_profile?.available_now]);

  return (
    <li
      id={id}
      className={styles.wrapper}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
    >
      <div
        className={
          isSelected
            ? `${styles["list-card"]} ${styles["list-card--selected"]}`
            : isCollapsed
            ? styles["list-card--collapsed"]
            : styles["list-card"]
        }
      >
        {!isCollapsed && (
          <TrackingShipperItemMenu
            close={closeMenu}
            visible={visible}
            shipper={{ id, general_profile, shipper_profile, fullname }}
          />
        )}

        {isCollapsed && (
          <div>
            <div className={styles["image-container"]}>
              <img
                className={styles["list-card--avatar"]}
                alt="avatar"
                src={
                  general_profile?.photo
                    ? `data:image/jpeg;base64,${general_profile?.photo}`
                    : DefaultImage
                }
              />
              {no_location_time > 10 && (
                <Tooltip title="Hace más de 10 minutos que no se registra su ubicación">
                  <div className={styles["red-dot"]}></div>
                </Tooltip>
              )}
            </div>
            <div className={styles[theme[status]]}>
              {icon[status]}
              {shipper_profile ? (
                <small>
                  {shipper_profile.available_now ? "Disponible" : "Offline"}
                </small>
              ) : (
                <small>Pendiente</small>
              )}
            </div>
          </div>
        )}

        {!isCollapsed && (
          <div style={{ padding: "4px 4px 0" }}>
            <div style={{ display: "flex" }}>
              <div className={styles["image-container"]}>
                <img
                  className={styles["list-card--avatar"]}
                  alt="avatar"
                  src={
                    general_profile?.photo
                      ? `data:image/jpeg;base64,${general_profile?.photo}`
                      : DefaultImage
                  }
                />
                {no_location_time > 10 && (
                  <Tooltip title="Hace más de 10 minutos que no se registra su ubicación">
                    <div className={styles["red-dot"]}></div>
                  </Tooltip>
                )}
              </div>

              <div style={{ flex: 1 }}>
                <div className={styles["list-card--name--container"]}>
                  <h5 className={styles["list-card--name"]}>
                    {fullname || general_profile?.name}
                  </h5>

                  <div className={styles[theme[status]]}>
                    {icon[status]}
                    {shipper_profile ? (
                      <small>
                        {shipper_profile.available_now
                          ? "Disponible"
                          : "Offline"}
                      </small>
                    ) : (
                      <small>Pendiente</small>
                    )}
                  </div>
                </div>

                <div className={styles["list-card--description"]}>
                  <img
                    src={Telephone}
                    alt="tel"
                    className={styles["list-card--description--icon"]}
                  />
                  <small>
                    {(general_profile?.phone &&
                      formatPhoneNumber(general_profile?.phone)) ||
                      "-- ---- ----"}
                  </small>
                </div>
              </div>
            </div>
            <div className={styles["list-card-footer"]}>
              <small className={styles["list-card-footer__last-location"]}>
                última ubicación: {last_location || "-"}
              </small>

              <button
                disabled={status === ShipperStatus.PENDING}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleMenu();
                }}
              >
                <img src={ThreePoints} alt="points" />
              </button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export { ShipperItem };
