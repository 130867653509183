import { FunctionComponent } from "react";

import { Input, VehiclePicker } from "../../../../components";

import RequestForm from "../../components/StopForm";
import ScheduleFields from "../../components/StopForm/ScheduleFields";

import StepProps from "../StepProps";
import styles from "./FirstStep.module.scss";

const FirstStep: FunctionComponent<StepProps> = ({
  handleInputChange,
  data,
  setHasErrors,
  packagesCount,
  setPackagesCount,
}) => (
  <div className={styles.wrapper}>
    <h4>Datos de Pedido</h4>

    <div className={styles.wrapper__row}>
      <Input
        label="Pedido Express"
        type="radio"
        name="request_type"
        value="express"
        onChange={handleInputChange("request_type")}
        checked={data.request_type === "express"}
      />
      <Input
        label="Pedido Programado (Proximamente)"
        type="radio"
        placeholder="julianejemplo@mail.com"
        name="request_type"
        disabled
        onChange={handleInputChange("request_type")}
        value="schedule"
        checked={data.request_type === "schedule"}
      />
    </div>

    <VehiclePicker
      onChange={handleInputChange("vehicle_type")}
      checked={data.vehicle_type}
    />

    {data.request_type === "schedule" && (
      <ScheduleFields handleInputChange={handleInputChange} data={data} />
    )}

    <h3 className={styles.wrapper__title}>
      Parada 1 - Punto de recogida inicial
    </h3>

    <RequestForm
      stop={0}
      data={data.stops[0]}
      setHasErrors={setHasErrors}
      packagesCount={packagesCount}
      setPackagesCount={setPackagesCount}
    />
  </div>
);

export default FirstStep;
