import { useMemo } from "react";

import {
  REQUEST_STATUSES,
  STOP_STATUSES,
} from "../../../../constants/requests";

import type { StatusProps } from "./Status";

// @todo move to constants.
const STOP_STATUS_MAP = {
  [STOP_STATUSES.PENDING]: "pending",
  [STOP_STATUSES.WIP]: "in-progress",
  [STOP_STATUSES.PARTIALLY_COMPLETE]: "in-progress",
  [STOP_STATUSES.COMPLETE]: "done",
};

const useStatus = ({ status = 0, stops }: StatusProps) => {
  const currentStop = useMemo(
    () => stops.find((stop: any) => stop.status < STOP_STATUSES.COMPLETE),
    [stops]
  );

  const steps = useMemo(
    () => [
      {
        title: "En Busqueda",
        status:
          status > REQUEST_STATUSES.WAITING_RESPONSE ? "done" : "in-progress",
      },
      {
        title: "Asignado",
        status: status >= REQUEST_STATUSES.ACCEPTED ? "done" : "pending",
      },
      ...stops.map((stop: any, index: number) => ({
        title: `Parada #${index + 1}`,
        status: STOP_STATUS_MAP[stop.status],
        isStop: true && status >= REQUEST_STATUSES.ACCEPTED,
        isCurrentStop:
          stop === currentStop && status === REQUEST_STATUSES.ACCEPTED,
      })),
      {
        title: "Finalizado",
        status: status === REQUEST_STATUSES.FINISHED ? "done" : "pending",
      },
    ],
    [currentStop, status, stops]
  );

  return { steps };
};

export default useStatus;
