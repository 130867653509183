import { State } from "../../interfaces";

export const cloudSelector = (state: State) => state?.cloud?.cloud;

export const getModulesSelector = (state: State) =>
  state?.cloud?.cloud?.modules;

export const selectIsDeliveryFeatureActivated = (state: State) =>
  state?.cloud?.cloud?.modules?.some((module: any) => module === "DELIVERY");

export const userCloudRequestSelector = (state: State) =>
  state.cloud.cloudRequest;

export const getCloudSelector = (state: State) => state.cloud.cloud;
