import { FunctionComponent } from "react";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { v4 as uuidv4 } from "uuid";

import { Avatar, TextStack } from "../../../../components";

import EmptyState from "./EmptyState";

import { orange } from "../../../../constants/colors";
import styles from "./ShipperDetails.module.scss";
import isEmpty from "lodash/isEmpty";
import { getIdentificationType } from "../../../../utils/documents";

export type ShipperDetailsProps = {
  shipper: any;
  requestId: string;
  shouldSearchAvailability: boolean;
};

const userData = (shipper: any) => [
  [
    {
      title: shipper.vehicle_model,
      subtitle: "Vehículo",
    },
    {
      title: shipper.vehicle_domain,
      subtitle: "Placa de Vehículo",
    },
  ],
  [
    {
      title: getIdentificationType(shipper.identification_type),
      subtitle: "Tipo documento",
    },
    {
      title: shipper.identification_number,
      subtitle: "Nro documento",
    },
  ],
];

const ShipperDetails: FunctionComponent<ShipperDetailsProps> = ({
  shipper,
  requestId,
  shouldSearchAvailability,
}) => {
  if (isEmpty(shipper)) {
    return (
      <EmptyState
        requestId={requestId}
        shouldSearchAvailability={shouldSearchAvailability}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <h4>Detalles del Repartidor</h4>
        <div className={styles.wrapper__header}>
          <Avatar />

          <div className={styles.wrapper__header__info}>
            <h4 className={styles.wrapper__header__info__title}>
              {shipper.name}
            </h4>

            <h4>
              <FaStar color={orange} />
              <FaStar color={orange} />
              <FaStar color={orange} />
              <FaStar color={orange} />
              <FaStar color={orange} />
            </h4>

            <h4 className={styles.wrapper__header__info__username}>
              {shipper.nickname}
            </h4>
          </div>
        </div>

        <div className={styles.wrapper__details}>
          {userData(shipper).map((row) => (
            <div className={styles.wrapper__row} key={uuidv4()}>
              {row.map((props) => (
                <TextStack {...props} key={uuidv4()} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShipperDetails;
