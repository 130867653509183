import { Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import { Shipper } from "../../interfaces";
import { List, LoadingOrContent, SectionHeader } from "../../components";
import { useDraggableContainer, MAX_HEIGHT } from "./hooks";
import { UseTrackingScreenOutput } from "./types";
import {
  EmptyState,
  FilterMenu,
  ShipperItem,
  TrackingMap,
  MobileSkeleton,
} from "./components";

import styles from "./ShippersMobile.module.scss";

type Props = Pick<
  UseTrackingScreenOutput,
  | "activeTrackingShippers"
  | "handleChangeShipperStatusToFilter"
  | "handleMarkerClick"
  | "handleOnClickShipperItem"
  | "handleOnHoverShipperItem"
  | "handleShowFilters"
  | "hasFilters"
  | "hoveredShipperId"
  | "isListCollapsed"
  | "selectedShipperId"
  | "shipperStatusToFilter"
  | "showFilters"
> & {
  drawerOpen: boolean;
  hasShippers: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  shippers: Shipper[];
  shippersToList: Shipper[] | null;
  isLoading: boolean;
};

const ShippersMobile = ({
  activeTrackingShippers,
  drawerOpen,
  handleChangeShipperStatusToFilter,
  handleMarkerClick,
  handleOnClickShipperItem,
  handleOnHoverShipperItem,
  handleShowFilters,
  hasFilters,
  hasShippers,
  hoveredShipperId,
  isListCollapsed,
  selectedShipperId,
  setDrawerOpen,
  shipperStatusToFilter,
  shippers,
  shippersToList,
  showFilters,
  isLoading,
}: Props): JSX.Element => {
  const { scope, controls, handleDropDrawer, drawerYPosition } =
    useDraggableContainer({
      setOpen: setDrawerOpen,
    });

  return (
    <LoadingOrContent loading={isLoading} customLoading={<MobileSkeleton />}>
      <SectionHeader
        title={
          <div className={styles["title-container"]}>
            <p>Mis Repartidores</p>
            <small>{shippers?.length ?? "-"}</small>
          </div>
        }
        icon={
          <FilterMenu
            isOpen={showFilters}
            onChange={handleChangeShipperStatusToFilter}
            defaultData={shipperStatusToFilter}
            handleShowFilters={handleShowFilters}
            showFilters={showFilters}
          />
        }
      />

      {!hasShippers ? (
        <EmptyState />
      ) : (
        <motion.div ref={scope} className={styles.wrapper}>
          <TrackingMap
            shippers={activeTrackingShippers}
            hoveredShipperId={hoveredShipperId}
            selectedShipperId={selectedShipperId}
            onMarkerClick={(shipperId) => handleMarkerClick(shipperId)}
          />

          <motion.div
            id="drawer"
            // ref={drawerRef}
            onClick={(evt) => evt.stopPropagation()}
            className={styles.content}
            initial={{ y: drawerYPosition }}
            animate={{ y: drawerOpen ? MAX_HEIGHT : 0 }}
            drag="y"
            dragControls={controls}
            onDragEnd={handleDropDrawer}
            dragListener={false}
            dragConstraints={{
              top: MAX_HEIGHT,
              bottom: 0,
            }}
          >
            <div className={styles.content__container}>
              <div
                onPointerDown={(event) => {
                  controls.start(event);
                }}
                className={styles.handle}
              />

              <div className={styles["children-container"]}>
                <List
                  data={shippersToList}
                  itemComponent={({
                    data: shipper,
                  }: {
                    index: number;
                    data: Shipper;
                  }) => (
                    <ShipperItem
                      shipper={shipper}
                      onClick={() => handleOnClickShipperItem(shipper)}
                      onHover={() => handleOnHoverShipperItem(shipper.id)}
                      onHoverLeave={() => handleOnHoverShipperItem(null)}
                      isCollapsed={isListCollapsed}
                      isSelected={selectedShipperId === shipper.id}
                    />
                  )}
                  emptyState={hasFilters && "No hay resultados"}
                  isTracking
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </LoadingOrContent>
  );
};

export { ShippersMobile };
