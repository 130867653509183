import { State } from "../../interfaces";
import { Business } from "../../interfaces/Business";

export const userSelector = (state: State) => state.user.data;

export const isLoggedSelector = (state: State) => state.user.isLogged;

export const getUserRolesSelector = (state: State) => {
  const user = userSelector(state);

  return user?.roles || [];
};

export const userIsManagerSelector = (state: State) => {
  return (
    getUserRolesSelector(state)?.find((role: string) => role === "manager") !=
    null
  );
};

export const userCloudIdSelector = (state: State) => {
  return (
    userSelector(state)?.managed_cloud_id ||
    userSelector(state)?.managed_business_cloud_id ||
    userSelector(state)?.business_profile.business_cloud_id
  );
};

export const userShippersCloudIdSelector = (state: State) => {
  return userSelector(state)?.managed_shippers_cloud_id;
};

export const generalProfileSelector = (state: State) => {
  const user = userSelector(state);

  return user?.general_profile;
};

export const nicknameSelector = (state: State) => {
  const user = userSelector(state);

  return user?.general_profile.nickname;
};

export const businessUserSelector = (state: State): Business => {
  const user = userSelector(state);
  return user;
};

export const userBusinessNameSelector = (state: State) => {
  const user = businessUserSelector(state);

  return user?.business_profile?.business_name || "";
};

export const getUserIdSelector = (state: State) => {
  const user = userSelector(state);

  return user?.id || "";
};

export const userBusinessRequestSelector = (state: State) => {
  return state.user.businessRequest;
};
