import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaBan } from "@react-icons/all-files/fa/FaBan";

import { State } from "../../../interfaces";
import clock from "../../../assets/images/clock.svg";
import { red } from "../../../constants/colors";
import { PATHS } from "../../../constants/paths";
import styles from "../Register.module.scss";

export type Props = {};

const StepThree = (_: Props): JSX.Element => {
  const { businessRequest, error } = useSelector(({ user }: State) => user);
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(PATHS.HOME);
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { message: errorMessage } = error.register;
  return (
    <div className={styles["step-three"]}>
      {businessRequest?.status === 0 && (
        <>
          <img src={clock} alt="" />
          <p>Espera que el administrador de la nube te dé el alta</p>
        </>
      )}
      {businessRequest?.status === 2 && (
        <>
          <FaBan color={red} size={96} />
          <p>La nube rechazó tu solicitud</p>
        </>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default StepThree;
