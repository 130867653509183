import { Skeleton, SkeletonGroup } from "../../../../components";
import { ShippersSkeleton } from "./ShippersSkeleton";
import { SkeletonMap } from "./SkeletonMap";

import styles from "./DesktopSkeleton.module.scss";

const DesktopSkeleton = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Skeleton containerStyle={styles["shippers-column"]} height="100%">
        <SkeletonGroup containerStyles={styles["title-container"]}>
          <Skeleton height="20px" square width="32px" />
          <Skeleton height="24px" />
        </SkeletonGroup>

        <SkeletonGroup containerStyles={styles["title-container"]}>
          <Skeleton height="38px" />
          <Skeleton square width="42px" />
        </SkeletonGroup>

        <div className={styles["shippers-container"]}>
          <ShippersSkeleton />
          <ShippersSkeleton />
          <ShippersSkeleton />
        </div>
      </Skeleton>

      <SkeletonMap />
    </div>
  );
};

export { DesktopSkeleton };
