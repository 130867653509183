import { FunctionComponent } from "react";

import { RequestStop } from "../../../../interfaces/Request";

import {
  Card,
  Input,
  Dropdown,
  PlaceAutocomplete,
} from "../../../../components";
import Packages from "../Packages";

import useStopForm from "./useStopForm";
import styles from "./StopForm.module.scss";

interface Props {
  stop: number;
  data: RequestStop;
  setHasErrors?: any;
  packagesCount?: number;
  setPackagesCount?: (n: number) => void;
}

const StopForm: FunctionComponent<Props> = ({
  stop,
  data,
  setHasErrors = () => {},
  packagesCount,
  setPackagesCount,
}) => {
  const {
    packagesToDrop,
    handleDropdownChange,
    getDropdownValue,
    handleInputChange,
    errors,
  } = useStopForm({ data, setHasErrors, stop });

  return (
    <div className={styles.wrapper}>
      <Card className={styles["card-container"]}>
        <div className={styles["card-container__header"]}>
          <h2 className={styles["card-container__header__title"]}>
            Datos de la parada
          </h2>
          <div></div>
        </div>
        <div className={styles["card-container__row"]}>
          <PlaceAutocomplete
            className={styles["card-container__row__place-autocomplete"]}
            onChange={handleInputChange("address")}
            value={data?.address?.description}
            error={errors?.address}
            required
          />

          <Input
            label="Piso y dpto"
            placeholder="7A"
            onChange={handleInputChange("address_department")}
            value={data?.address_department || ""}
            error={errors?.address_department}
          />
        </div>

        <div className={styles["card-container__row"]}>
          <Input
            label="Persona a Cargo"
            placeholder="Julián Ejemplo"
            onChange={handleInputChange("manager")}
            value={data?.manager || ""}
            error={errors?.manager}
            required
          />

          <Input
            label="Correo electrónico"
            type="email"
            placeholder="julianejemplo@mail.com"
            onChange={handleInputChange("email")}
            value={data?.email || ""}
            error={errors?.email}
          />

          <Input
            label="Número de Contacto"
            placeholder="11 0000 1234"
            onChange={handleInputChange("phone")}
            value={data?.phone || ""}
            error={errors?.phone}
            required
          />
        </div>

        <div className={styles["card-container__row"]}>
          <Input
            label="Dato para tener en cuenta"
            placeholder="(ej: contiene vasos de vidrios)"
            onChange={handleInputChange("message")}
            value={data?.message || ""}
            error={errors?.message}
          />

          <Input
            label="Número de pisos"
            type="number"
            placeholder="6"
            onChange={handleInputChange("floors")}
            value={data?.floors}
            error={errors?.floors}
            min={0}
          />
        </div>

        <div className={styles["wrapper__packages-to-drop"]}>
          {stop !== 0 && (
            <>
              <div className={styles.wrapper__header}>
                <h2>Paquetes a entregar</h2>
                <div></div>
              </div>
              <Dropdown
                options={packagesToDrop}
                onChange={handleDropdownChange}
                isMulti
                value={getDropdownValue(data?.packages_to_drop)}
              />
            </>
          )}
        </div>
        <div className={styles["wrapper__packages-to-drop"]}>
          <Packages
            stop={stop}
            packagesCount={packagesCount}
            setPackagesCount={setPackagesCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default StopForm;
