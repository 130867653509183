import React, { useMemo } from "react";
import cn from "classnames";

import van from "../../assets/images/van.svg";
import trolley from "../../assets/images/trolley.svg";

import { Logo } from "..";
import BestLogisticSolution from "./BestLogisticSolution";

import styles from "./Layout.module.scss";

export interface GuessLayoutProps {
  children?: React.ReactNode;
  theme?: string;
}

const GuessLayout: React.FC<GuessLayoutProps> = ({ children, theme }) => {
  const isRegister = useMemo(() => theme === "register", [theme]);

  return (
    <div
      className={cn(styles["login-wrapper"], {
        [styles["login-wrapper--register"]]: isRegister,
      })}
    >
      <div className={styles["login-wrapper__left-column"]}>
        <img
          src={isRegister ? trolley : van}
          alt="van"
          className={cn(styles["login-wrapper__left-column__icon"], {
            [styles["login-wrapper--register__left-column__icon"]]: isRegister,
          })}
        />

        {isRegister ? <Logo /> : <BestLogisticSolution />}
      </div>

      {children}
    </div>
  );
};

export { GuessLayout };
