import { FunctionComponent } from "react";
import Select from "react-select";
import cn from "classnames";

import { Label } from "..";
import { CardWrap } from "../CardWrap";

import styles from "./Dropdown.module.scss";

export type OptionType = {
  label?: string;
  value: string;
};

export type DropdownProps = {
  placeholder?: string;
  className?: string | boolean;
  options?: OptionType[];
  theme?: string;
  label?: string;
  onChange?: (x: any) => void;
  value?: any;
  isMulti?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const Dropdown: FunctionComponent<DropdownProps> = ({
  placeholder,
  className,
  options,
  theme,
  label,
  onChange,
  value = [],
  isMulti = false,
  isSearchable = false,
  disabled = false,
  required = false,
}) => {
  const handleChange = (value: string) => {
    if (typeof onChange === "function") {
      onChange(value);
    }
  };

  return (
    <CardWrap theme={theme}>
      <>
        {label && <Label required={required}>{label}</Label>}
        <Select
          isClearable
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          isSearchable={isSearchable}
          options={options}
          className={cn(styles.dropdown, className)}
          isMulti={isMulti}
          isDisabled={disabled}
        />
      </>
    </CardWrap>
  );
};

export { Dropdown };
