import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { toast } from "react-toastify";

import { copyToClipboard } from "../../utils";
import { userCloudIdSelector, userSelector } from "../../state/selectors/user";

import { toastConfig } from "../../constants";
import { PATHS } from "../../constants/paths";

import {
  Layout,
  Divider,
  List,
  Button,
  LoadingOrContent,
  ListSkeleton,
} from "../../components";

import {
  getBusinesses,
  getBusinessPayments,
  getBusinessRequests,
} from "../../state/slices/businesses";
import {
  businessesDebtsSelector,
  businessesSelector,
} from "../../state/selectors/businesses";

import EmptyState from "./EmptyState/EmptyState";
import BusinessItem from "./BusinessItem/BusinessItem";
import { orange, violet } from "../../constants/colors";
import { moneyFormatter } from "../../utils/money";

interface BusinessesProps {}

const Businesses: React.FC<BusinessesProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const businesses = useSelector(businessesSelector);
  const userCloudId = useSelector(userCloudIdSelector);
  const businessesDebtAccumulated = useSelector(businessesDebtsSelector);

  useEffect(() => {
    if (user.id) {
      dispatch(getBusinesses());
      dispatch(getBusinessRequests());
      dispatch(getBusinessPayments());
    }
  }, [dispatch, user]);

  const handleCopyLink = () => {
    const path = PATHS.LOGIN.replace(":application?", "business");
    copyToClipboard(`${window.location.origin}${path}?cloudId=${userCloudId}`);

    toast.success("Copiado al portapapeles!", toastConfig);
  };

  const summaryData = [
    {
      title: businesses?.length,
      subtitle: "Nº de Negocios",
      color: violet,
      xxl: true,
    },
    {
      title: businesses?.reduce((acc: number, business: any) => {
        return acc + (business.requestsCount || 0);
      }, 0),
      subtitle: "Nº de Pedidos",
      color: violet,
      xxl: true,
    },
    {
      title: moneyFormatter(businessesDebtAccumulated),
      subtitle: "Saldo Actual",
      color: orange,
      xxl: true,
    },
  ];

  return (
    <Layout
      headerAction={
        <Button
          title="Link de invitación"
          type="gradient"
          icon={<FaLink />}
          onClick={handleCopyLink}
        />
      }
      summaryData={summaryData}
    >
      <Divider title="Comercios" />

      <LoadingOrContent
        loading={businesses === null}
        customLoading={<ListSkeleton />}
      >
        <List
          data={businesses}
          emptyState={<EmptyState action={handleCopyLink} />}
          itemComponent={BusinessItem}
        />
      </LoadingOrContent>
    </Layout>
  );
};

export { Businesses };
