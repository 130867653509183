import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";

import { State } from "../../interfaces/State";
import { GA_EVENTS, PATHS } from "../../constants";
import { Layout, Button, Divider } from "../../components";
import { getUser } from "../../state/slices/user";
import { useGATracking, useIsMobile } from "../../hooks";

import { GeneralDataForm } from "./GeneralDataForm";
import { CloudDataForm } from "./CloudDataForm";

import styles from "./Profile.module.scss";

enum TAB_OPTIONS {
  PERSONAL = "PERSONAL",
  CLOUD = "CLOUD",
}

const Profile = () => {
  const [selectedTab, setSelectedTab] = useState<TAB_OPTIONS>(
    TAB_OPTIONS.PERSONAL
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { trackGAPageView } = useGATracking();

  useLayoutEffect(() => {
    trackGAPageView({ path: "Profile" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: userData } = useSelector((store: State) => store.user);

  useEffect(() => {
    if (Object.values(userData).every(isEmpty)) {
      dispatch(getUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleLogout = () => history.replace(PATHS.LOGOUT);

  return (
    <Layout
      hideCard={isMobile}
      headerAction={
        <Button
          title="Salir"
          type="gradient"
          icon={<FaSignOutAlt />}
          onClick={handleLogout}
          gaEvent={GA_EVENTS.PROFILE.LOGOUT}
        />
      }
    >
      {!isMobile && <Divider title="Mi cuenta" />}

      <div className={styles["tab-switch-wrapper"]}>
        <Button
          className={cn(styles.title, {
            [styles["title--selected"]]: selectedTab === TAB_OPTIONS.PERSONAL,
          })}
          title="Datos Personales"
          type="clear"
          onClick={() => setSelectedTab(TAB_OPTIONS.PERSONAL)}
          gaEvent={GA_EVENTS.PROFILE.SWITCH_PERSONAL_TAB}
        />

        <p className={styles.separator}>|</p>

        <Button
          className={cn(styles.title, {
            [styles["title--selected"]]: selectedTab === TAB_OPTIONS.CLOUD,
          })}
          title="Datos Nube"
          type="clear"
          onClick={() => setSelectedTab(TAB_OPTIONS.CLOUD)}
          gaEvent={GA_EVENTS.PROFILE.SWITCH_CLOUD_TAB}
        />
      </div>

      {selectedTab === TAB_OPTIONS.PERSONAL && <GeneralDataForm />}

      {selectedTab === TAB_OPTIONS.CLOUD && <CloudDataForm />}
    </Layout>
  );
};

export { Profile };
