import { FunctionComponent } from "react";

import { GuessLayout } from "../../components";

import RegisterCloudComponent from "./RegisterCloud";

export type RegisterInterface = {};

const RegisterCloud: FunctionComponent<RegisterInterface> = () => {
  return (
    <GuessLayout theme="register">
      <RegisterCloudComponent />
    </GuessLayout>
  );
};

export { RegisterCloud };
