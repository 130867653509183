import { FunctionComponent } from "react";

import StopForm from "../../components/StopForm";

import StepProps from "../StepProps";

import styles from "./SecondStep.module.scss";

const SecondStep: FunctionComponent<StepProps> = ({
  currentStop = 1,
  totalStops = 1,
  data,
  setHasErrors,
  packagesCount,
  setPackagesCount,
}) => (
  <div className={styles.wrapper}>
    <h3 className={styles.wrapper__title}>
      Parada {currentStop + 1} {totalStops + 1 && `/ ${totalStops + 1}`}
    </h3>

    <StopForm
      stop={currentStop}
      data={data.stops[currentStop]}
      setHasErrors={setHasErrors}
      packagesCount={packagesCount}
      setPackagesCount={setPackagesCount}
    />
  </div>
);

export default SecondStep;
