import { combineReducers } from "redux";
import shippers from "./shippers";
import user from "./user";
import zones from "./zones";
import businesses from "./businesses";
import vehicles from "./vehicles";
import request from "./request";
import itineraries from "./itineraries";
import cloud from "./cloud";

const rootReducer = () =>
  combineReducers({
    shippers,
    user,
    zones,
    businesses,
    cloud,
    request,
    itineraries,
    vehicles,
  });

export default rootReducer;
