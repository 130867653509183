import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { deleteStop } from "../../../../state/slices/request";
import { SummaryStepProps } from "./SummaryStep";

const useSummaryStep = ({
  stop,
  lastStop,
  dropins,
  dropoffs,
  editMode = true,
  confirmationCode,
  ...props
}: SummaryStepProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  // @todo unify with packages logic.
  const packagesToDrop = useMemo(() => {
    if (typeof props.packages_to_drop === "object") {
      return Object.values(props.packages_to_drop);
    }

    return dropoffs;
  }, [dropoffs, props.packages_to_drop]);

  const hasPackagesToDrop = useMemo(
    () => !isEmpty(packagesToDrop),
    [packagesToDrop]
  );

  const packages = useMemo(() => {
    if (typeof props.packages === "object") {
      return Object.values(props.packages);
    }

    return dropins;
  }, [dropins, props.packages]);

  const hasPackages = useMemo(() => !isEmpty(packages), [packages]);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleDeleteStop = () => {
    dispatch(deleteStop({ stop }));
  };

  const couldDelete = useMemo(
    () => stop > 0 && !lastStop && editMode,
    [stop, lastStop, editMode]
  );

  return {
    open,
    hasPackagesToDrop,
    hasPackages,
    handleOpenModal,
    handleDeleteStop,
    packages,
    packagesToDrop,
    setOpen,
    couldDelete,
    confirmationCode,
  };
};

export default useSummaryStep;
