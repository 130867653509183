import { State } from "../../interfaces";
import { keyToId } from "../../utils";

export const shippersSelector = (state: State) => {
  const {
    shippers: { shippers = {}, shipper_requests = {}, status = {} },
  } = state || {};

  if (Object.values(status).includes("loading")) {
    return null;
  }

  const reducedRequests = shipper_requests
    ? Object.keys(shipper_requests).reduce((acc: any, curr: string) => {
        const value: any =
          shipper_requests[curr as keyof typeof shipper_requests];
        acc[curr] = {
          ...value,
          id: curr,
        };
        return acc;
      }, {})
    : {};

  const shippersSum = {
    ...reducedRequests,
    ...shippers,
  };
  return keyToId(shippersSum);
};

export const shipperProfitSelector: any =
  (user_id: string) => (state: State) => {
    const {
      shippers: { shipper_payments = {} },
    } = state || {};

    if (!shipper_payments) {
      return {};
    }

    const { shipper_open_bills } = shipper_payments;

    const shipperProfit = shipper_open_bills?.[user_id]?.shipper_profit || 0;

    return { shipperProfit };
  };

export const shippersProfitAccumulatedSelector = (state: State): number => {
  const {
    shippers: { shipper_payments = {} },
  } = state || {};

  if (!shipper_payments) {
    return 0;
  }

  const { to_shipper_pending_bills } = shipper_payments;

  const openBills = Object.values(to_shipper_pending_bills);

  const shippersProfit = openBills.reduce((acc: number, bill: any) => {
    return acc + bill.shipper_profit;
  }, 0);

  return shippersProfit;
};

export const shipperNameSelector = (user_id?: string) => (state: State) => {
  const {
    shippers: { shippers = [] },
  } = state || {};

  const shipper = shippers?.find((user: any) => user.id === user_id);

  return shipper?.general_profile?.name || user_id;
};
export const shippersCloudDataSelector = (state: State) => {
  const {
    shippers: { shippersCloudData = {} },
  } = state || {};

  return shippersCloudData?.shippers_cloud;
};

export const activeTrackingShippersSelector = (state: State) =>
  state?.shippers?.shippers
    ?.filter((shipper) => shipper?.shipper_profile?.available_now === true)
    .map((shipper) => {
      return {
        id: shipper.id,
        name: shipper.general_profile?.name,
        photo: shipper.general_profile?.photo,
        position: {
          lat: shipper.last_known_location!.point.latitude,
          lng: shipper.last_known_location!.point.longitude,
        },
      };
    });
