import { useEffect } from "react";
import { FormStep } from "../../../types/FormStep";

import { Input, Dropdown, PlaceAutocomplete, Card } from "../../../components";
import { BUSINESS_IDENTIFICATION_TYPES } from "../../../constants";
import { User, USER_PROFILE_KEYS } from "../../../types";

const StepTwo = ({
  handleInputChange,
  errors,
  data,
}: FormStep<User[USER_PROFILE_KEYS.BUSINESS_PROFILE]>) => {
  const cloudId = localStorage.getItem("cloudId") || undefined;

  useEffect(() => {
    if (cloudId !== null) {
      handleInputChange("cloudId")(cloudId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Input
        label="Razón Social"
        placeholder="Nombre"
        theme="card"
        onChange={handleInputChange("business_name")}
        value={data?.business_name}
        error={errors.business_name}
        required
      />

      <Card>
        <PlaceAutocomplete
          onChange={handleInputChange("address_line")}
          value={data?.address_line}
          error={errors?.address_line}
          required
        />
      </Card>

      <Dropdown
        label="Tipo de Clave Fiscal"
        placeholder="Seleccione"
        theme="card"
        options={BUSINESS_IDENTIFICATION_TYPES}
        onChange={handleInputChange("identification_type")}
        value={data?.identification_type}
        required
      />

      <Input
        label="Numero de Clave Fiscal"
        placeholder="27313131312"
        theme="card"
        onChange={handleInputChange("identification_number")}
        value={data?.identification_number || ""}
        error={errors.identification_number}
        required
      />
    </>
  );
};

export default StepTwo;
