import { useState, useCallback, useMemo } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

import { GMAPS_API_KEY } from "../../constants/api";

import { MapProps } from "./Map";

const DEFAULT_ZOOM = 13;

const DEFAULT_RADIUS = 1;

const useMap = ({
  routeCords = [],
  zoom = DEFAULT_ZOOM,
  radius = DEFAULT_RADIUS,
  stops = [],
  tracking,
}: MapProps) => {
  const positions = useMemo(() => {
    const currentStop = stops.find((stop) => !stop.status || stop.status < 3);
    return stops.map((stop) => ({
      status:
        stop === currentStop
          ? "current"
          : stop.status === 3
          ? "completed"
          : "pending",
      position: {
        lat: Number(stop.address.latitude),
        lng: Number(stop.address.longitude),
      },
    }));
  }, [stops]);

  const firstPosition = useMemo(() => positions[0].position, [positions]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GMAPS_API_KEY,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setMap] = useState(null);

  const onLoad = useCallback((map: any) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const trackingPosition = tracking
    ? {
        lat: tracking.location.point.latitude,
        lng: tracking.location.point.longitude,
      }
    : null;
  return {
    center: trackingPosition || firstPosition,
    firstPosition,
    positions,
    tracking: trackingPosition,
    isLoaded,
    onLoad,
    onUnmount,
    radius,
    routeCords,
    zoom,
  };
};

export default useMap;
