import { FunctionComponent } from "react";
import { Card, Input, Label } from "../../../../components";

import { Request } from "../../../../interfaces/Request";

import styles from "./StopForm.module.scss";

export type ScheduleFieldsProps = {
  handleInputChange: (s: string) => (s: string) => void;
  data: Request;
};

const ScheduleFields: FunctionComponent<ScheduleFieldsProps> = ({
  handleInputChange,
  data,
}) => {
  return (
    <Card className={styles["card-container"]}>
      <Label>Frecuencia</Label>

      <div className={styles["wrapper__row"]}>
        <Input
          label="Diaria"
          type="radio"
          name="frequency"
          value="daily"
          onChange={handleInputChange("frequency")}
          checked={data.frequency === "daily"}
        />

        <Input
          label="Semanal"
          type="radio"
          placeholder="julianejemplo@mail.com"
          name="frequency"
          value="weekly"
          onChange={handleInputChange("frequency")}
          checked={data.frequency === "weekly"}
        />

        <Input
          label="Mensual"
          type="radio"
          name="frequency"
          value="month"
          onChange={handleInputChange("frequency")}
          checked={data.frequency === "month"}
        />

        <Input
          label="Trimestral"
          type="radio"
          placeholder="julianejemplo@mail.com"
          name="frequency"
          value="3-month"
          onChange={handleInputChange("frequency")}
          checked={data.frequency === "3-month"}
        />
      </div>

      <Label>Horarios</Label>

      <div className={styles["wrapper__row"]}>
        <Input
          label="Horas Fijas"
          type="radio"
          name="schedule"
          value="daily"
          onChange={handleInputChange("schedule")}
          checked={data.schedule === "daily"}
        />

        <Input
          label="Rango de Horas"
          type="radio"
          placeholder="julianejemplo@mail.com"
          name="schedule"
          value="weekly"
          onChange={handleInputChange("schedule")}
          checked={data.schedule === "weekly"}
        />
      </div>

      <div className={styles["wrapper__row"]}>
        {/* First Half */}
        <p>De</p>

        <Input
          type="time"
          name="from"
          onChange={handleInputChange("from")}
          value={data.from}
        />

        <p>a</p>

        <Input
          type="time"
          name="to"
          onChange={handleInputChange("to")}
          value={data.to}
        />

        {/* Second Half */}
        <p>De</p>

        <Input
          type="time"
          name="from"
          onChange={handleInputChange("second_from")}
          value={data.second_from}
        />

        <p>a</p>

        <Input
          type="time"
          name="to"
          onChange={handleInputChange("second_to")}
          value={data.second_to}
        />
      </div>
    </Card>
  );
};

export default ScheduleFields;
