import isEmpty from "lodash/isEmpty";
import {
  PERSONAL_IDENTIFICATION_TYPES,
  BUSINESS_IDENTIFICATION_TYPES,
} from "../../constants";
import { User } from "../../types";
import { OptionType } from "../../components/Dropdown";

const getIdentificationType = (
  identification_type?: string | OptionType | undefined
) => {
  if (typeof identification_type === "number") {
    return identification_type;
  }

  return undefined;
};

const parseData = <T extends User>(data: T): T => {
  let parsedData = data;

  // if it has General Profile Data.
  if (!isEmpty(data.general_profile)) {
    const identification_type_index = getIdentificationType(
      parsedData.general_profile?.identification_type
    );

    parsedData = {
      ...parsedData,
      general_profile: {
        ...parsedData.general_profile,
        identification_type:
          identification_type_index !== undefined
            ? PERSONAL_IDENTIFICATION_TYPES[identification_type_index]
            : undefined,
      },
    };
  }

  // if it has Business Profile Data.
  if (!isEmpty(data.business_profile)) {
    const identification_type_index = getIdentificationType(
      parsedData.business_profile?.identification_type
    );

    parsedData = {
      ...parsedData,
      business_profile: {
        ...parsedData.business_profile,
        identification_type:
          identification_type_index !== undefined
            ? BUSINESS_IDENTIFICATION_TYPES[identification_type_index]
            : undefined,
        address_line: parsedData.business_profile?.address_line,
      },
    };
  }

  return parsedData;
};

export const PROFILE_UTILS = { parseData };
