import { FunctionComponent, useMemo } from "react";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

import { orange, violet } from "../../constants/colors";
import { GaEvent } from "../../types";
import { useGATracking } from "../../hooks";
import styles from "./Button.module.scss";

enum TYPES {
  GRADIENT = "gradient",
  CLEAR = "clear",
  ORANGE_GRADIENT = "orange-gradient",
  DARK_GRADIENT = "dark-gradient",
  GREEN_GRADIENT = "green-gradient",
}

export type ButtonInterface = {
  title?: string;
  type?:
    | "gradient"
    | "clear"
    | "orange-gradient"
    | "dark-gradient"
    | "green-gradient";
  icon?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  iconClassName?: string;
  color?: string;
  onClick?: (evt?: React.MouseEvent<HTMLElement>) => void;
  rightIcon?: boolean;
  xxl?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: "small";
  gaEvent?: GaEvent;
};

const Button: FunctionComponent<ButtonInterface> = ({
  title = "",
  type,
  icon,
  color,
  className,
  iconClassName,
  onClick,
  rightIcon = false,
  xxl,
  hidden,
  disabled = false,
  loading = false,
  size,
  gaEvent,
  titleClassName,
}) => {
  const hasTitle = useMemo(() => title !== "", [title]);
  const hasIcon = useMemo(() => icon !== undefined, [icon]);

  const { trackGAEvent } = useGATracking();

  const elements = useMemo(() => {
    const elementsList = [];

    const iconEl = (
      <div
        className={cn(styles.button__icon, iconClassName, {
          [styles["button--clear__icon"]]: type === TYPES.CLEAR,
          [styles["button__icon--right-icon"]]: rightIcon,
          [styles["button__icon--only"]]: hasIcon && !hasTitle,
        })}
        key={uuidv4()}
      >
        {icon}
      </div>
    );

    const titleEl = (
      <p
        className={cn(styles.button__title, titleClassName, {
          [styles["button__title--small"]]: size === "small",
        })}
        key={uuidv4()}
      >
        {title}
      </p>
    );

    if (hasIcon && !loading) {
      elementsList.push(iconEl);
    }

    if (hasTitle) {
      elementsList.push(titleEl);
    }

    return rightIcon ? elementsList.reverse() : elementsList;
  }, [
    icon,
    title,
    hasTitle,
    iconClassName,
    rightIcon,
    type,
    hasIcon,
    loading,
    size,
    titleClassName,
  ]);

  const handleOnClick = (evt: React.MouseEvent<HTMLElement>) => {
    // tracking Google Analytics Event.
    if (gaEvent !== undefined) {
      trackGAEvent(gaEvent);
    }

    onClick?.(evt);
  };

  return (
    <button
      className={cn(styles.button, className, {
        [styles["button--gradient"]]: type === TYPES.GRADIENT,
        [styles["button--orange-gradient"]]: type === TYPES.ORANGE_GRADIENT,
        [styles["button--dark-gradient"]]: type === TYPES.DARK_GRADIENT,
        [styles["button--green-gradient"]]: type === TYPES.GREEN_GRADIENT,
        [styles["button--clear"]]: type === TYPES.CLEAR,

        [styles["button--bg-orange"]]: color === orange,
        [styles["button--bg-violet"]]: color === violet,

        [styles["button--xxl"]]: xxl,
        [styles["button--hidden"]]: hidden,
        [styles["button--loading"]]: loading && !rightIcon,
        [styles["button--loading-right"]]: loading && rightIcon,
        [styles["button--small"]]: size === "small",
      })}
      type="button"
      onClick={handleOnClick}
      disabled={disabled || loading}
    >
      {elements}
    </button>
  );
};

export { Button };
