import { FunctionComponent } from "react";
import cn from "classnames";

import styles from "./Message.module.scss";

export type MessageProps = {
  text: string;
  time?: string;
  incoming?: boolean;
};

const Message: FunctionComponent<MessageProps> = ({ text, incoming, time }) => {
  return (
    <div className={styles.wrapper}>
      <p
        className={cn(styles.wrapper__text, {
          [styles["wrapper__text--incoming"]]: incoming,
        })}
      >
        {text}
      </p>

      <small
        className={cn(styles.wrapper__time, {
          [styles["wrapper__time--incoming"]]: incoming,
        })}
      >
        {time}
      </small>
    </div>
  );
};

export default Message;
