import { useMemo } from "react";
import cn from "classnames";
import { BiChevronLeft } from "@react-icons/all-files/bi/BiChevronLeft";
import {
  Button,
  List,
  LoadingOrContent,
  Search,
  SectionHeader,
} from "../../components";
import { Shipper } from "../../interfaces";
import { UseTrackingScreenOutput } from "./types";
import {
  EmptyState,
  FilterMenu,
  ShipperItem,
  TrackingMap,
  DesktopSkeleton,
} from "./components";

import styles from "./ShippersDesktop.module.scss";

type Props = Pick<
  UseTrackingScreenOutput,
  | "activeTrackingShippers"
  | "handleChangeShipperStatusToFilter"
  | "handleInputChange"
  | "handleMarkerClick"
  | "handleOnClickShipperItem"
  | "handleOnHoverShipperItem"
  | "handleShowFilters"
  | "hoveredShipperId"
  | "isListCollapsed"
  | "selectedShipperId"
  | "shipperStatusToFilter"
  | "showFilters"
  | "toggleListCollapse"
> & {
  shippersToList: Shipper[];
  hasFilters: boolean;
  isLoading: boolean;
};

const ShippersDesktop = ({
  handleChangeShipperStatusToFilter,
  handleInputChange,
  handleOnClickShipperItem,
  handleOnHoverShipperItem,
  handleShowFilters,
  hasFilters,
  isListCollapsed,
  selectedShipperId,
  shipperStatusToFilter,
  shippersToList,
  showFilters,
  toggleListCollapse,
  activeTrackingShippers,
  hoveredShipperId,
  handleMarkerClick,
  isLoading,
}: Props): JSX.Element => {
  const emptyState = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (hasFilters) {
      return "No hay resultados.";
    }

    return isListCollapsed ? null : <EmptyState />;
  }, [isLoading, hasFilters, isListCollapsed]);

  return (
    <LoadingOrContent loading={isLoading} customLoading={<DesktopSkeleton />}>
      <div className={styles.wrapper}>
        <div className={styles.collapsible}>
          <Button
            type="clear"
            icon={<BiChevronLeft />}
            onClick={toggleListCollapse}
            className={cn(styles["collapsible-button"], {
              [styles["collapsible-button--closed"]]: isListCollapsed,
            })}
          />

          <div className={styles["collapsible--divider-vertical"]} />
        </div>

        <div
          className={cn(styles["shippers-container"], {
            [styles["shippers-container--collapsed"]]: isListCollapsed,
          })}
        >
          {!isListCollapsed && <SectionHeader title="Mis Repartidores" />}

          <div className={styles.filtersContainer}>
            {!isListCollapsed && <Search onChange={handleInputChange} />}

            <FilterMenu
              isOpen={showFilters}
              onChange={handleChangeShipperStatusToFilter}
              defaultData={shipperStatusToFilter}
              handleShowFilters={handleShowFilters}
              showFilters={showFilters}
            />
          </div>

          <List
            data={shippersToList}
            itemComponent={({
              data: shipper,
            }: {
              index: number;
              data: Shipper;
            }) => (
              <ShipperItem
                shipper={shipper}
                onClick={() => handleOnClickShipperItem(shipper)}
                onHover={() => handleOnHoverShipperItem(shipper.id)}
                onHoverLeave={() => handleOnHoverShipperItem(null)}
                isCollapsed={isListCollapsed}
                isSelected={selectedShipperId === shipper.id}
              />
            )}
            emptyState={emptyState}
            isTracking
          />
        </div>

        <TrackingMap
          shippers={activeTrackingShippers}
          hoveredShipperId={hoveredShipperId}
          selectedShipperId={selectedShipperId}
          onMarkerClick={(shipperId) => handleMarkerClick(shipperId)}
        />
      </div>
    </LoadingOrContent>
  );
};

export { ShippersDesktop };
