import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { copyToClipboard } from "../utils";
import { PATHS, toastConfig } from "../constants";
import { shippersCloudDataSelector } from "../state/selectors/shippers";
import { getShippers } from "../state/slices/shippers";
import { getCloudSelector } from "../state/selectors/cloud";

export const WHATSAPP_TEMPLATE_TEXT = `¡Hola! Quiero que te sumés a mi nube de fletti {CLOUD_NAME} como repartidor 🚚 📦.
Si no tenés la app descargada, podés hacerlo rápidamente desde este link ➡️ {CLOUD_LINK} y la instalás.
Luego ingresás y escribís el código *{CLOUD_ALIAS}* y ya podrás usar fletti!`;

const useCopyInviteToClipboard = () => {
  const shippersCloudData = useSelector(shippersCloudDataSelector);
  const cloud = useSelector(getCloudSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shippersCloudData) {
      dispatch(getShippers());
    }
  }, [shippersCloudData, dispatch]);

  const copyInviteToClipboard = () => {
    if (!cloud) {
      toast.error("Ocurrio un error al obtener el cloud.", toastConfig);
      return;
    }

    const path = PATHS.NEW_SHIPPERS_LANDING.replace(
      ":cloudId?",
      cloud?.alias.toUpperCase()
    );

    const formattedText = WHATSAPP_TEMPLATE_TEXT.replace(
      "{CLOUD_NAME}",
      cloud.name
    )
      .replace("{CLOUD_LINK}", window.location.origin.concat(path))
      .replace("{CLOUD_ALIAS}", cloud.alias.toUpperCase());

    copyToClipboard(formattedText);

    toast.success("Copiado al portapapeles!", toastConfig);
  };

  return { copyInviteToClipboard };
};

export { useCopyInviteToClipboard };
