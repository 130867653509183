import { useHistory } from "react-router-dom";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";

import { PATHS } from "../../constants/paths";

import { Layout, Button, Divider } from "../../components";

import styles from "./MeliProfile.module.scss";
import { useEffect, useState } from "react";
import { hasMLRegistration, unlinkMLRegistration } from "../../services/user";

const MeliProfile = () => {
  const history = useHistory();
  const [token, setToken] = useState("");
  const [linkResult, setLinkResult] = useState<string | null>(null);
  const [hasML, setHasML] = useState<boolean>();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const result = params.get("result");

    if (result !== null) {
      setLinkResult(result);
    }

    const checkML = async () => {
      const a = await hasMLRegistration();
      setHasML(a);
    };
    checkML();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setToken(localStorage.getItem("@access_token") || "");
  }, []);

  const handleLogout = () => history.replace(PATHS.LOGOUT);

  const handleLink = () => {
    window.location.href = `https://auth.mercadopago.com.ar/authorization?client_id=1919330611678760&response_type=code&platform_id=mp&state=1234&redirect_uri=https://api.fletti.com/ecommerce/ml/auth?authorization=${token}`;
  };

  const handleUnlink = async () => {
    const result = await unlinkMLRegistration();
    if (result) {
      setHasML(false);
    }
  };

  return (
    <Layout
      headerAction={
        <Button
          title="Salir"
          type="gradient"
          icon={<FaSignOutAlt />}
          onClick={handleLogout}
        />
      }
    >
      <Divider title="Mi cuenta" />
      <h2 className={styles.title}>Cuenta de Mercado Pago</h2>
      {hasML ? <h3>Cuenta verificada</h3> : <h3>Debe Verificar la cuenta</h3>}
      {linkResult === "succes" ? (
        <span>La vinculación fue realizada con exito</span>
      ) : (
        <></>
      )}
      {linkResult === "error" ? (
        <span>
          Ocurrio un error al realizar la vinculación. Intenta hacerlo más tarde
        </span>
      ) : (
        <></>
      )}
      {hasML ? (
        <Button
          title="Desvincular cuenta de MercadoPago"
          type="gradient"
          xxl
          className={styles.submit}
          onClick={handleUnlink}
        />
      ) : (
        <Button
          title="Vincular cuenta de MercadoPago"
          type="gradient"
          xxl
          className={styles.submit}
          onClick={handleLink}
        />
      )}
    </Layout>
  );
};

export { MeliProfile };
