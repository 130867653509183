import { State } from "../../interfaces";
import { Business } from "../../interfaces/Business";
import { BusinessData } from "../../interfaces/BusinessData";
import {
  getBusinessIdentificationType,
  getIdentificationType,
} from "../../utils/documents";
import { userShippersCloudIdSelector } from "./user";

export const businessesSelector = (state: State) => {
  const {
    businesses: { data = [], business_requests = [], status = {} },
  } = state;

  if (Object.values(status).includes("loading")) {
    return null;
  }

  const businessesTransformed: BusinessData[] = data
    ? data.map((business: Business) => ({
        business_id: business.id,
        address_line: business.business_profile.address_line,
        agents: [],
        contact: {
          name: business.general_profile.name,
          nickname: business.general_profile.nickname,
          phone: business.general_profile.phone,
          email: business.general_profile.email,
        },
        identification_type: getIdentificationType(
          business.general_profile.identification_type
        ),
        identification_number: business.general_profile.identification_number,
        business: business.business_profile.business,
        business_active: business.business_profile.business_active,
        business_cloud_id: business.business_profile.business_cloud_id,
        business_name: business.business_profile.business_name,
        business_identification_type: getBusinessIdentificationType(
          business.business_profile.identification_type
        ),
        business_identification_number:
          business.business_profile.identification_number,
        status: business.business_profile.business_active
          ? "Activo"
          : "Inactivo",
        requestsCount:
          (business.active_requests?.length || 0) +
          (business.previous_requests?.length || 0),
      }))
    : [];

  const businessRequestsArray = Object.entries(business_requests);

  const businessRequestsTransformed: BusinessData[] = businessRequestsArray.map(
    ([businessId, businessRequest]) => ({
      business_id: businessId,
      address_line: businessRequest.address_line,
      agents: [],
      contact: {
        name: businessRequest.fullname,
        nickname: "N/D",
        phone: businessRequest.phone,
        email: businessRequest.email,
      },
      identification_type: getIdentificationType(
        businessRequest.identification_type
      ),
      identification_number: businessRequest.identification_number,
      business: businessRequest.business,
      business_active: businessRequest.business_active,
      business_cloud_id: businessRequest.business_cloud_id,
      business_name: businessRequest.business_name,
      business_identification_type: getBusinessIdentificationType(
        businessRequest.business_identification_type
      ),
      business_identification_number:
        businessRequest.business_identification_number,
      status: "Pendiente",
      requestsCount: 0,
    })
  );

  return [...businessRequestsTransformed, ...businessesTransformed];
};

export const businessDebtSelector =
  (business_id?: string) => (state: State) => {
    const {
      businesses: { business_payments: businesses_payments = null },
    } = state || {};

    let debtObject = {
      openTotal: 0,
      shippersCloudId: null,
      pendingTotal: 0,
      pendingBillId: null,
    };
    if (!businesses_payments || !business_id) {
      return debtObject;
    }

    if (businesses_payments.shippers_cloud_open_bills?.[business_id]) {
      const [shippers_cloud_id, businessOpenBills]: any = Object.entries(
        businesses_payments.shippers_cloud_open_bills[business_id]
      )[0];

      const { total_value } = businessOpenBills;

      debtObject.openTotal += total_value;
      debtObject.shippersCloudId = shippers_cloud_id;
    }
    if (businesses_payments.shippers_cloud_pending_bills?.[business_id]) {
      const [shippers_cloud_id, businessPendingBills]: any = Object.entries(
        businesses_payments.shippers_cloud_pending_bills[business_id]
      )[0];

      const { total_value } = businessPendingBills;

      debtObject.pendingTotal += total_value;
      debtObject.shippersCloudId = shippers_cloud_id;
      debtObject.pendingBillId = businessPendingBills.id;
    }

    return debtObject;
  };

export const businessesDebtsSelector = (state: State): number => {
  const {
    businesses: { business_payments: businesses_payments = {} },
  } = state || {};

  if (!businesses_payments) {
    return 0;
  }

  if (businesses_payments.shippers_cloud_open_bills) {
    const businessesOnpenBills = Object.values(
      businesses_payments.shippers_cloud_open_bills
    );

    const businessesDebt = businessesOnpenBills.reduce(
      (acc: number, business: any) => {
        const shippersCloudId = userShippersCloudIdSelector(state);
        return acc + (business[shippersCloudId].total_value || 0);
      },
      0
    );

    return businessesDebt;
  }
  return 0;
};

export const businessNameSelector = (businessId?: string) => (state: State) => {
  const businesses = businessesSelector(state);
  const business = businesses?.find(
    (business: BusinessData) => business.business_id === businessId
  );

  return business?.business_name || businessId;
};
