import { OptionType } from "../components/Dropdown";

export enum USER_PROFILE_KEYS {
  GENERAL_PROFILE = "general_profile",
  BUSINESS_PROFILE = "business_profile",
}

export type User = {
  // isLogged: boolean;
  [USER_PROFILE_KEYS.GENERAL_PROFILE]?: {
    name: string;
    email: string;
    identification_type?: string | OptionType;
    identification_number: string;
    phone: string;
  };
  [USER_PROFILE_KEYS.BUSINESS_PROFILE]?: {
    business_name: string;
    identification_type?: string | OptionType;
    identification_number: string;
    address_line: string | { description: string };
  };
};
