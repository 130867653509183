export const getVehicleCategory = (category: number = -1) => {
  switch (category) {
    case 0:
      return "Bici/Moto";
    case 1:
      return "Auto";
    case 2:
      return "Camioneta";
    case 3:
      return "Camión";
    default:
      return "No Mapeado";
  }
};
