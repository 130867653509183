import styles from "./BestLogisticSolution.module.scss";

interface Props {
  className?: string;
  text?: string[];
}

const BestLogisticSolution = ({
  className,
  text = ["La mejor", "solución logística", "para tu Empresa."],
}: Props): JSX.Element => (
  <div className={className}>
    <>
      <h2 className={styles["wrapper__title"]}>{text[0]}</h2>

      <h2 className={styles["wrapper__title"]}>{text[1]}</h2>

      <h2
        className={`${styles["wrapper__title"]} ${styles["wrapper__title--selected"]}`}
      >
        {text[2]}
      </h2>
    </>
  </div>
);

export default BestLogisticSolution;
