import { useDispatch, useSelector } from "react-redux";

import { requestPackagesSelector } from "../../../../state/selectors/requests";

import {
  removePackage,
  updatePackages,
} from "../../../../state/slices/request";

const EMPTY_PACKAGE = {
  id: 1,
  description: "",
  size: "",
  weight: 1,
};

const usePackages = ({
  stop,
  packagesCount = 0,
  setPackagesCount,
}: {
  stop: number;
  packagesCount?: number;
  setPackagesCount?: (n: number) => void;
}) => {
  const dispatch = useDispatch();

  const packages = useSelector(requestPackagesSelector({ stop }));

  const handleAddPackage = () => {
    setPackagesCount?.(packagesCount + 1);
    dispatch(
      updatePackages({
        pkgData: EMPTY_PACKAGE,
        index: packagesCount,
        stop,
      })
    );
  };

  const handleRemovePackage = (index: string) => () => {
    dispatch(removePackage({ index, stop }));
  };

  return {
    packages,
    addPackage: handleAddPackage,
    removePackage: handleRemovePackage,
  };
};

export default usePackages;
