type Props = {
  photo?: string;
  isHover?: boolean;
  isSelected?: boolean;
};

const TrackingMapIcon = ({ photo, isHover, isSelected }: Props) => {
  return (
    <svg width="52" height="60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="circleView">
          <circle cx="26" cy="26" r="16" />
        </clipPath>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="4" />
          <feFlood floodColor="#861DF0" result="colorOut" />
          <feComposite
            operator="in"
            in="colorOut"
            in2="blurOut"
            result="theShadow"
          />
          <feBlend in="SourceGraphic" in2="theShadow" mode="normal" />
        </filter>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#791af9" />
          <stop offset="100%" stopColor="#a923d7" />
        </linearGradient>
      </defs>
      <g filter={isHover ? "url(#shadow)" : ""}>
        <circle
          cx="26"
          cy="26"
          r="19.5"
          fill={isSelected ? "url(#gradient)" : "#FAF9FE"}
          stroke={isHover ? "#861DF0" : "none"}
          strokeWidth={isHover ? "1" : "0"}
        />
      </g>
      {photo ? (
        <image
          href={`data:image/png;base64,${photo}`}
          x="10"
          y="10"
          height="32"
          width="32"
          clipPath="url(#circleView)"
        />
      ) : (
        <>
          <path
            d="M25.0086 35.4562C24.0411 35.4562 20.9678 35.4551 20.0004 35.4562L20.026 36.9902C24.8062 37.0018 35.8213 37.0007 40.6016 36.9972L40.5736 35.4562H33.7076C34.2077 35.1881 34.548 34.6601 34.548 34.054C34.548 33.1763 33.8347 32.4629 32.9569 32.4629C32.0792 32.4629 31.3659 33.1763 31.3659 34.054C31.3659 34.6601 31.7062 35.187 32.2063 35.4562H29.3167H26.5087C27.0088 35.1881 27.3491 34.6601 27.3491 34.054C27.3491 33.1763 26.6358 32.4629 25.7581 32.4629C24.8803 32.4629 24.167 33.1763 24.167 34.054C24.167 34.6601 24.5085 35.1881 25.0086 35.4562Z"
            fill="#21014C"
          />
          <path
            d="M35.4263 32.8292C34.9741 31.9212 34.0381 31.2964 32.9564 31.2964C31.5133 31.2964 30.3267 32.4108 30.2102 33.8247C29.639 33.8515 29.069 33.8573 28.506 33.8468C28.3999 32.4224 27.2087 31.2964 25.7575 31.2964C24.5044 31.2964 23.4449 32.1368 23.1103 33.2826C21.8293 33.0519 20.7756 32.7966 20.089 32.6147C20.8059 29.8475 23.4064 21.321 28.914 18.1027C32.7664 15.8542 37.7133 17.1539 39.9618 21.0051C42.2103 24.8563 40.9141 29.7998 37.064 32.0506C36.5442 32.3548 35.9963 32.6124 35.4263 32.8292ZM35.755 23.4611C34.9403 22.067 33.1487 21.5972 31.7546 22.412C30.3605 23.2268 29.8908 25.0184 30.7056 26.4124C31.5203 27.8065 33.3119 28.2763 34.706 27.4615C36.1001 26.6467 36.5698 24.8552 35.755 23.4611Z"
            fill="url(#paint0_linear_1656_1874)"
          />
        </>
      )}
      <path
        d="M26,50 L16,42 L36,42 Z"
        fill={isSelected ? "#B426D1" : "#FAF9FE"}
      />
      <defs>
        <filter
          id="filter0_d_1656_1874"
          x="0"
          y="0"
          width="63"
          height="63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1656_1874"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1656_1874"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1656_1874"
          x1="30.5772"
          y1="17"
          x2="30.5772"
          y2="33.8517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B426D1" />
          <stop offset="1" stopColor="#7419FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { TrackingMapIcon };
