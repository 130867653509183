import { Skeleton, SkeletonGroup } from "../../../../components";

import styles from "./ShippersSkeleton.module.scss";

const ShippersSkeleton = (): JSX.Element => {
  return (
    <SkeletonGroup containerStyles={styles.wrapper}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Skeleton circle width="50px" />

        <div className={styles["lines-container"]}>
          <Skeleton />

          <Skeleton width="50%" />
        </div>
      </div>
    </SkeletonGroup>
  );
};

export { ShippersSkeleton };
