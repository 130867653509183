import { FunctionComponent } from "react";
import cn from "classnames";

import { violet, orange, soft_blue } from "../../constants/colors";
import styles from "./TextStack.module.scss";

export interface TextStackProps {
  title?: string | number | JSX.Element;
  subtitle?: string | number;
  color?: string;
  normalWeight?: boolean;
  table?: boolean;
  reverse?: boolean;
  xxl?: boolean;
  medium?: boolean;
}

const TextStack: FunctionComponent<TextStackProps> = ({
  title,
  subtitle,
  color,
  normalWeight = false,
  table = false,
  reverse = false,
  xxl = false,
  medium = false,
}) => (
  <div
    className={cn(styles["wrapper"], {
      [styles["wrapper--table"]]: table,
      [styles["wrapper--reverse"]]: reverse,
    })}
  >
    <h5
      className={cn(styles["wrapper__title"], {
        [styles["wrapper__title--table"]]: table,
        [styles["wrapper__title--violet"]]: color === violet,
        [styles["wrapper__title--orange"]]: color === orange,
        [styles["wrapper__title--soft-blue"]]: color === soft_blue,
        [styles["wrapper__title--normal-weight"]]: normalWeight,
        [styles["wrapper__title--xxl"]]: xxl,
        [styles["wrapper__title--medium"]]: medium,
      })}
    >
      {title}
    </h5>
    <small
      className={cn(styles["wrapper__subtitle"], {
        [styles["wrapper__subtitle--table"]]: table,
        [styles["wrapper__subtitle--table--orange"]]: color === orange,
        [styles["wrapper__subtitle--table--violet"]]: color === violet,
      })}
    >
      {subtitle}
    </small>
  </div>
);

export default TextStack;
