import { PackageItemInterface, RequestStop } from "../interfaces";
import { moveToNewIndex } from "./array";

export const findDistanceInMetersHaversine = (
  address1: { latitude: number; longitude: number },
  address2: { latitude: number; longitude: number }
) => {
  const R = 6371e3; // metres
  const φ1 = (address1.latitude * Math.PI) / 180; // φ, λ in radians
  const φ2 = (address2.latitude * Math.PI) / 180;
  const Δφ = ((address2.latitude - address1.latitude) * Math.PI) / 180;
  const Δλ = ((address2.longitude - address1.longitude) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
};

export const sortByDistance = (
  firstStop: RequestStop,
  stopsWithoutFirst: RequestStop[]
) => {
  return stopsWithoutFirst.sort((currentStop, nextStop) => {
    const distanceToCurrentStop = findDistanceInMetersHaversine(
      firstStop.address,
      currentStop.address
    );
    const distanceToNextStop = findDistanceInMetersHaversine(
      firstStop.address,
      nextStop.address
    );
    return distanceToCurrentStop < distanceToNextStop ? -1 : 1;
  });
};

const findStopWithDropOffs = (
  stops: RequestStop[],
  pkg: PackageItemInterface
) => {
  for (let idx = 0; idx < stops.length; idx++) {
    const stop = stops[idx];
    for (
      let doIdx = 0;
      doIdx < Object.values(stop.packages_to_drop || {}).length;
      doIdx++
    ) {
      const doItem = Object.values(stop.packages_to_drop || {})[doIdx];

      if (doItem.id === pkg.id) {
        return idx;
      }
    }
  }
  return -1;
};

export const sortByPackages = (stops: RequestStop[]) => {
  let notModified = false;
  let sortedStops = stops;
  do {
    for (let i = sortedStops.length - 1; i >= 1; i--) {
      if (Object.values(sortedStops[i].packages || {}).length > 0) {
        for (const pkg of Object.values(sortedStops[i].packages || {})) {
          const stopIdx = findStopWithDropOffs(sortedStops.slice(0, i), pkg);
          if (stopIdx > -1) {
            notModified = true;
            sortedStops = moveToNewIndex(sortedStops, stopIdx, i);
            break;
          }
        }
      }
    }
    notModified = false;
  } while (notModified);

  return sortedStops;
};

export const optimizeStopsPath = (stops: RequestStop[]) => {
  const firstStop = stops[0];
  const stopsWithoutFirst = stops.slice(1);

  const sortedStopsByDistance = sortByDistance(firstStop, stopsWithoutFirst);

  const sortedStopsByPackages = sortByPackages(sortedStopsByDistance);

  return [firstStop, ...sortedStopsByPackages];
};
