import { FunctionComponent } from "react";

import box from "../../assets/images/box.svg";

import { violet } from "../../constants/colors";

import { Button } from "..";

import styles from "./ErrorFallback.module.scss";

export type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback: FunctionComponent<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}: any) => {
  return (
    <div className={styles.wrapper} role="alert">
      <div className={styles.wrapper__content}>
        <img src={box} alt="" />

        <p>Ocurrio un error</p>
        <pre>{error.message}</pre>

        <Button title="Recargar" onClick={resetErrorBoundary} color={violet} />
      </div>
    </div>
  );
};

export { ErrorFallback };
