import { FunctionComponent, useMemo } from "react";
import cn from "classnames";

import styles from "./Divider.module.scss";

export type DividerInterface = {
  vertical?: boolean;
  title?: React.ReactNode;
  className?: string;
};

const Divider: FunctionComponent<DividerInterface> = ({
  vertical = false,
  title = "",
  className,
}) => {
  const hasTitle = useMemo(() => title !== "", [title]);

  const dividerElement = (
    <hr
      className={cn(
        styles.divider,
        {
          [styles["divider--vertical"]]: vertical,
        },
        className
      )}
    />
  );

  if (!hasTitle) {
    return dividerElement;
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={styles.wrapper__title}>{title}</h5>

      {dividerElement}
    </div>
  );
};

export { Divider };
