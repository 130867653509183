// TODO [salva@24-04-2024] [#253] do we need this routes?
// import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import isEmpty from "lodash/isEmpty";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";

import { updateBusiness } from "../../services/businesses";
import { State } from "../../interfaces/State";
import { PATHS } from "../../constants/paths";
// import { BUSINESS_IDENTIFICATION_TYPES } from "../../constants";
import { Layout, Button, Divider } from "../../components";

import useRegister from "../Register/useRegister";
import { Steps } from "../Register/Steps";

import registerStyles from "../Register/Register.module.scss";
import styles from "./BusinessProfile.module.scss";
import { USER_PROFILE_KEYS } from "../../types";
import { PROFILE_UTILS } from "../Profile/utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [_, StepTwo] = Steps;

// @TODO [salva@15-04-2024] [#253] do we still needing this one?
const BusinessProfile = () => {
  const history = useHistory();
  const { data: userData = {} } = useSelector((store: State) => store.user);

  const { data, errors, handleInputChange } = useRegister({
    defaultData: PROFILE_UTILS.parseData(userData),
    inProfile: true,
  });

  const handleLogout = () => history.replace(PATHS.LOGOUT);

  // const stepTwoData = useMemo(() => {
  //   const { cloudId, ...restData } = dataTwo;
  //   return isEmpty(restData)
  //     ? {
  //         ...userData.business_profile,
  //         business_identification_type:
  //           BUSINESS_IDENTIFICATION_TYPES[
  //             parseInt(userData.business_profile.identification_type)
  //           ],
  //         business_identification_number:
  //           userData.business_profile.identification_number,
  //         address_line: { description: userData.business_profile.address_line },
  //       }
  //     : {
  //         business_identification_type:
  //           BUSINESS_IDENTIFICATION_TYPES[
  //             parseInt(userData.business_profile.identification_type)
  //           ],
  //         business_identification_number:
  //           userData.business_profile.identification_number,
  //         address_line: { description: userData.business_profile.address_line },
  //         ...dataTwo,
  //       };
  // }, [dataTwo, userData.business_profile]);

  const handleBusinessSubmit = () => {
    updateBusiness({
      ...userData.business_profile,
      cloudId: userData.cloudId,
      business_name:
        data.business_name || userData.business_profile.business_name,
      identification_type: parseInt(data.business_identification_type.value),

      identification_number: parseInt(data.business_identification_number),
      address_line:
        data.address_line?.description ||
        userData.business_profile.address_line,
    });
  };

  return (
    <Layout
      headerAction={
        <Button
          title="Salir"
          type="gradient"
          icon={<FaSignOutAlt />}
          onClick={handleLogout}
        />
      }
    >
      <Divider title="Mi cuenta" />

      <h2 className={styles.title}>Datos de Comercio</h2>

      <div
        className={`${registerStyles["wrapper__form"]} ${registerStyles["wrapper__form--no-height"]}`}
      >
        <StepTwo
          handleInputChange={handleInputChange}
          errors={errors[USER_PROFILE_KEYS.BUSINESS_PROFILE]}
          data={data[USER_PROFILE_KEYS.BUSINESS_PROFILE]}
        />
      </div>

      <Button
        title="Guardar datos del Comercio"
        type="gradient"
        xxl
        className={styles.submit}
        onClick={handleBusinessSubmit}
      />
    </Layout>
  );
};

export { BusinessProfile };
