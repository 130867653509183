import { FunctionComponent } from "react";
import ReactDOM from "react-dom";

import { Card } from "..";

import styles from "./Modal.module.scss";

export type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: JSX.Element;
};

const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) return null;

  const handleClickOverlay = (evt: any) => {
    onClose?.();
  };

  const noPropagate = (evt: any) => {
    evt.stopPropagation();
  };

  return ReactDOM.createPortal(
    <div className={styles["wrapper"]} onClick={handleClickOverlay}>
      <Card className={styles["wrapper__modal"]} onClick={noPropagate}>
        <>{children}</>
      </Card>
    </div>,
    document.body
  );
};

export { Modal };
