import { useState } from "react";
import classNames from "classnames";

import { useIsMobile } from "../../../../hooks";
import FilterIcon from "../../../../assets/images/icons/filter";
import { ShipperStatuses } from "../../types";
import { Button, Card, Input } from "../../../../components";
import { GA_EVENTS } from "../../../../constants";

import styles from "./FilterMenu.module.scss";

type Props = {
  isOpen?: boolean;
  onChange?: (key: ShipperStatuses, value: boolean) => void;
  defaultData?: Record<string, boolean>;
  handleShowFilters: (showFilters: boolean) => void;
  showFilters: boolean;
};

const FilterMenu = ({
  isOpen,
  onChange,
  defaultData = {
    available: true,
    no_available: true,
  },
  handleShowFilters,
  showFilters,
}: Props) => {
  const [checked, setChecked] = useState<Props["defaultData"]>(defaultData);

  const { isMobile } = useIsMobile();

  const noPropagate = (evt: any) => {
    evt.stopPropagation();
  };

  const handleInputChange = (key: ShipperStatuses) => {
    setChecked({ ...checked, [key]: !checked?.[key] });
    onChange && onChange(key, !checked?.[key]);
  };

  return (
    <>
      <Button
        type="clear"
        icon={<FilterIcon />}
        onClick={() => handleShowFilters(!showFilters)}
        className={styles.button}
        gaEvent={GA_EVENTS.SHIPPERS.FILTER_BUTTON}
      />

      {isOpen && (
        <Card
          className={classNames(styles.modal, {
            [styles["modal--mobile"]]: isMobile,
          })}
          onClick={noPropagate}
        >
          <Input
            className={styles.modal__input}
            type="checkbox"
            label="Disponibles"
            onChange={() => handleInputChange("available")}
            checked={checked?.available}
          />

          <Input
            className={styles.modal__input}
            type="checkbox"
            label="No Disponibles"
            checked={checked?.no_available}
            onChange={() => handleInputChange("no_available")}
          />
        </Card>
      )}
    </>
  );
};

export { FilterMenu };
