import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchVehicles } from "../../services/vehicles";

const initialState = {
  data: null,
  status: "idle",
};

export const getVehicles = createAsyncThunk("vehicles/fetch", async () => {
  const response: any = await fetchVehicles();

  return response;
});

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVehicles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      });
  },
});

export default vehiclesSlice.reducer;
