import React, { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import cn from "classnames";

import { selectIsDeliveryFeatureActivated } from "../../state/selectors/cloud";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Header, Sidebar, ErrorFallback, TextStackProps } from "..";
import { MobileHeader } from "../Header/MobileHeader";
import { Menu } from "./Menu/Menu";

import style from "./Layout.module.scss";

export interface LayoutProps {
  children: ReactNode;
  className?: string;
  noHeader?: boolean;
  headerAction?: JSX.Element;
  infoCard?: JSX.Element;
  summaryData?: TextStackProps[];
  hideCard?: boolean;
  isLoading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  className = "",
  headerAction = <></>,
  infoCard,
  summaryData,
  hideCard = false,
  isLoading = false,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isMobile } = useIsMobile();

  const isDeliveryFeatureActivated = useSelector(
    selectIsDeliveryFeatureActivated
  );

  const handleError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here
    console.error({ error });
    console.info({ info });
  };

  const handleResetError = () => {
    // reset the state of your app so the error doesn't happen again
    window.location.reload();
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      onReset={handleResetError}
    >
      <div
        className={cn(style.wrapper, {
          [style["wrapper--lock-scroll"]]: showMenu,
        })}
      >
        {isDeliveryFeatureActivated && <Sidebar />}
        <div className={style.wrapper__main}>
          {isMobile ? (
            <MobileHeader
              setShowMenu={setShowMenu}
              showMenu={showMenu}
              infoCard={infoCard}
              summaryData={summaryData}
              hideCard={hideCard}
              isLoading={isLoading}
            />
          ) : (
            <Header
              headerAction={headerAction}
              infoCard={infoCard}
              summaryData={summaryData}
              hideCard={hideCard}
              isLoading={isLoading}
            />
          )}
          <div className={cn(style.wrapper__content, className)}>
            {children}
          </div>
        </div>
      </div>

      {isMobile && showMenu && <Menu />}
    </ErrorBoundary>
  );
};

export { Layout };
