import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hasPackagesToDropSelector,
  packagesToDropByStopSelector,
  packagesToDropSelector,
  requestPackagesSelector,
} from "../../state/selectors/requests";

import { updateRequest, addStop } from "../../state/slices/request";
import { requestSelector } from "../../state/selectors/requests";

import STEPS from "./Steps";

const useRequest = () => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStop, setCurrentStop] = useState(1);
  const [hasErrors, setHasErrors] = useState(true);
  const [packagesCount, setPackagesCount] = useState(1);

  const request = useSelector(requestSelector);
  const hasPackagesToDrop = useSelector(hasPackagesToDropSelector);
  const packagesToDrop = useSelector(packagesToDropSelector);
  const packages = useSelector(requestPackagesSelector({ stop: currentStop }));
  const packagesToDropInCurrentStop = useSelector(
    packagesToDropByStopSelector({ stop: currentStop })
  );

  const hasPackagesOnCurrentStop = Object.keys(packages).length > 0;
  const hasPackagesToDropInCurrentStop =
    Object.keys(packagesToDropInCurrentStop).length > 0;

  const verifyIfHasName = (array: any) => array.label === "";

  const hasPackagesWithOutName = packagesToDrop.some(verifyIfHasName);

  const handleInputChange = (field: string) => (value: string | number) => {
    dispatch(updateRequest({ data: { ...request, [field]: value } }));
  };

  const totalStops = useMemo(() => request.stops.length - 1, [request.stops]);

  const firstStep = useMemo(() => currentStep === 0, [currentStep]);
  const secondStep = useMemo(() => currentStep === 1, [currentStep]);
  const thirdStep = useMemo(() => currentStep === 2, [currentStep]);

  const lastStop = useMemo(
    () => currentStop === totalStops,
    [currentStop, totalStops]
  );

  const StepComponent = useMemo(() => STEPS[currentStep], [currentStep]);

  const shouldDisableAdvance = useMemo(() => {
    if (thirdStep) {
      return true;
    }

    if (secondStep && hasPackagesToDrop) {
      return true;
    }

    return false;
  }, [thirdStep, secondStep, hasPackagesToDrop]);

  const handleNextStep = () => setCurrentStep(currentStep + 1);

  const handlePrevStep =
    (stop?: number) => (evt?: React.MouseEvent<HTMLElement>) => {
      if (!secondStep) {
        setCurrentStep(currentStep - 1);
      }

      if (currentStop === 1) {
        setCurrentStep(currentStep - 1);
      } else {
        setCurrentStop(currentStop - 1);
      }

      if (thirdStep) {
        if (typeof stop === "number") {
          if (stop === 0) {
            setCurrentStep(0);
          } else {
            setCurrentStop(stop);
          }
        } else {
          setCurrentStop(totalStops);
        }
      }
    };

  const handleAddStop = () => {
    if (firstStep) {
      if (request.stops.length === 1) {
        dispatch(addStop());
      }

      handleNextStep();
    }

    if (secondStep) {
      setCurrentStop(currentStop + 1);
    }

    if (lastStop && !firstStep) {
      dispatch(addStop());
    }
  };

  const shouldDisableAddStop =
    hasErrors ||
    hasPackagesWithOutName ||
    (firstStep && !hasPackagesToDrop) ||
    (secondStep &&
      !hasPackagesToDropInCurrentStop &&
      !hasPackagesOnCurrentStop);

  return {
    StepComponent,
    currentStep,
    currentStop,
    data: request,
    handleAddStop,
    handleInputChange,
    handleNextStep,
    handlePrevStep,
    setCurrentStop,
    shouldDisableAdvance,
    totalStops,
    firstStep,
    secondStep,
    thirdStep,
    lastStop,
    hasErrors,
    setHasErrors,
    packagesCount,
    setPackagesCount,
    hasPackagesToDrop,
    shouldDisableAddStop,
  };
};

export default useRequest;
