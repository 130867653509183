import { v4 as uuidv4 } from "uuid";

import { Card, Skeleton, SkeletonGroup } from "..";

import styles from "./List.module.scss";

export const ListSkeleton = () => {
  return (
    <>
      <>
        {[1, 2, 3, 5].map(() => (
          <Card className={styles.skeleton__row} key={uuidv4()}>
            <SkeletonGroup>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton circle />
            </SkeletonGroup>
          </Card>
        ))}
      </>
    </>
  );
};
