import { FunctionComponent } from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";

import { Layout, Button } from "../../components";
import { orange } from "../../constants/colors";

import useCreateRequest from "./useCreateRequest";
import styles from "./CreateRequest.module.scss";

export type Props = {};

const CreateRequest: FunctionComponent<Props> = () => {
  const {
    StepComponent,
    currentStop,
    data,
    handleAddStop,
    handleInputChange,
    handleNextStep,
    handlePrevStep,
    setCurrentStop,
    shouldDisableAdvance,
    totalStops,
    firstStep,
    secondStep,
    thirdStep,
    lastStop,
    hasErrors,
    setHasErrors,
    packagesCount,
    setPackagesCount,
    hasPackagesToDrop,
    shouldDisableAddStop,
  } = useCreateRequest();

  return (
    <Layout className={styles.wrapper} noHeader>
      <StepComponent
        handleInputChange={handleInputChange}
        data={data}
        currentStop={currentStop}
        setCurrentStop={setCurrentStop}
        totalStops={totalStops}
        setHasErrors={setHasErrors}
        handlePrevStep={handlePrevStep}
        packagesCount={packagesCount}
        setPackagesCount={setPackagesCount}
      />

      <div className={styles["request-error"]}>
        {firstStep && !hasErrors && !hasPackagesToDrop && (
          <small className={styles["request-error__text"]}>
            Debes cargar al menos un paquete
          </small>
        )}
      </div>

      <div className={styles["footer-container"]}>
        {!firstStep && (
          <Button
            title="Anterior"
            onClick={handlePrevStep(currentStop)}
            type="clear"
          />
        )}

        {!thirdStep && (
          <Button
            title={lastStop ? "Agregar Parada" : "Siguiente Parada"}
            onClick={handleAddStop}
            color={orange}
            className={styles["wrapper__add-stop-button"]}
            icon={<FaPlus />}
            type="orange-gradient"
            disabled={shouldDisableAddStop}
          />
        )}

        {secondStep && (
          <Button
            title="Ir al Resumen"
            onClick={handleNextStep}
            type="gradient"
            disabled={shouldDisableAdvance || hasErrors}
          />
        )}
      </div>
    </Layout>
  );
};

export { CreateRequest };
