import { useState } from "react";
import { Button, Card } from "../../../../../../components";
import styles from "./KickShipperModal.module.scss";

type KickShipperModalProps = {
  visible: boolean;
  onClick: () => void;
  close: () => void;
  fullname?: string;
};

const KickShipperModal = ({
  visible,
  onClick,
  close,
  fullname,
}: KickShipperModalProps) => {
  const noPropagate = (evt: any) => {
    evt.stopPropagation();
  };
  const [text, setText] = useState<string | null>(null);
  const handleKick = () => {
    text === "eliminar" && onClick();
    close();
  };

  return (
    <>
      {visible && <div className={styles.overlay}></div>}
      <Card
        className={`${styles.modal} ${visible ? styles.visible : ""}`}
        onClick={noPropagate}
      >
        <h1>{`¿Desea quitar al repartidor ${fullname} ?`}</h1>
        <p>
          Para eliminar al repartidor de su nube debe completar el cuadro con la
          palabra <strong>eliminar</strong>
        </p>
        <input type="text" onChange={(e) => setText(e.target.value)} />
        <div className={styles["buttons-container"]}>
          <Button type="orange-gradient" title="Cancelar" onClick={close} />
          <Button
            type="dark-gradient"
            title="Eliminar"
            onClick={handleKick}
            disabled={text !== "eliminar"}
          />
        </div>
      </Card>
    </>
  );
};

export default KickShipperModal;
