import ticketIcon from "../../assets/images/icons/ticket.svg";
import styles from "./SectionHeader.module.scss";

type Props = {
  title: React.ReactNode;
  icon?: React.ReactNode;
};

const SectionHeader = ({ title, icon }: Props): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__title}>
        <img
          className={styles.wrapper__title__icon}
          src={ticketIcon}
          alt="ticketIcon"
        />

        <h3 className={styles.title}>{title}</h3>
      </div>

      {icon}
    </div>
  );
};

export { SectionHeader };
