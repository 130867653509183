import { FunctionComponent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import cn from "classnames";

import { Card, TextStack, Button } from "../../../components";

import ExtraInfo from "./ExtraInfo";

import styles from "./BusinessItem.module.scss";
import { BusinessData } from "../../../interfaces/BusinessData";
import { useSelector } from "react-redux";
import { businessDebtSelector } from "../../../state/selectors/businesses";
import { moneyFormatter } from "../../../utils/money";

export type BusinessProps = BusinessData;

const Business: FunctionComponent<BusinessProps> = (business) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const handleToggleExtraInfo = () => setShowExtraInfo(!showExtraInfo);
  const businessDebt = useSelector(businessDebtSelector(business.business_id));

  const data = [
    {
      subtitle: "Comercio",
      title: business.business_name,
    },
    {
      subtitle: "Estado",
      title: business.status,
    },
    {
      subtitle: "Contacto",
      title: business.contact.name,
    },
    {
      subtitle: "Nº de pedidos",
      title: business.requestsCount,
    },
    {
      subtitle: "Saldo actual",
      title: moneyFormatter(businessDebt.openTotal),
    },
    {
      subtitle: "Saldo Pendiente",
      title: moneyFormatter(businessDebt.pendingTotal),
    },
  ];

  return (
    <li
      className={cn(styles.wrapper, {
        [styles["wrapper--open"]]: showExtraInfo,
      })}
    >
      <Card className={styles["card-container"]}>
        <>
          {data.map((data: any) => (
            <TextStack {...data} key={uuidv4()} />
          ))}

          <Button
            type="clear"
            icon={
              <FaChevronDown
                className={cn(styles["toggle-button"], {
                  [styles["toggle-button--open"]]: showExtraInfo,
                })}
              />
            }
            onClick={handleToggleExtraInfo}
          />
        </>
      </Card>

      <ExtraInfo
        className={cn(styles["extra-info"], {
          [styles["extra-info--open"]]: showExtraInfo,
        })}
        businessData={business}
      />
    </li>
  );
};

export default Business;
