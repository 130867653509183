import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import vanImage from "../../assets/images/van.svg";
import { GA_EVENTS, PATHS } from "../../constants";
import { useGATracking } from "../../hooks";
import { Button, Logo } from "../../components";
import BestLogisticSolution from "../../components/Layout/BestLogisticSolution";

import styles from "./NewShippersLanding.module.scss";

const copyright_text = `El futuro de las entregas en tus manos. Derechos reservados ${new Date().getFullYear()}.`;

const IOS_LINK =
  "https://play.google.com/store/apps/details?id=com.flettiappshipper";

const ANDROID_LINK =
  "https://apps.apple.com/ar/app/fletti-shipper/id1620375766";

const NewShippersLanding = (): JSX.Element => {
  const { trackGAPageView } = useGATracking();

  useLayoutEffect(() => {
    trackGAPageView({ path: "New Shippers Landing Page" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToAndroidStore = () => window.open(IOS_LINK);
  const handleGoToIOSStore = () => window.open(ANDROID_LINK);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__logo}>
        <Link to={PATHS.HOME}>
          <Logo />
        </Link>
      </div>

      <section id="hero" className={styles.wrapper__hero}>
        <div className={styles["wrapper__hero__left-column"]}>
          <BestLogisticSolution
            className={styles["wrapper__hero__left-column__simple"]}
          />

          <p className={styles["wrapper__hero__left-column__text"]}>
            Maneja todos tus envíos de forma segura y en tiempo real. Sin costos
            fijos, solo pagas cuando la usas.
          </p>

          <div className={styles["wrapper__hero__left-column__buttons"]}>
            <Button
              title="Descarga para Android"
              type="gradient"
              xxl
              onClick={handleGoToAndroidStore}
              gaEvent={GA_EVENTS.NEW_SHIPPER_LANDING.DOWNLOAD_ANDROID_APP}
            />

            <Button
              title="Descarga para iOS"
              type="dark-gradient"
              xxl
              onClick={handleGoToIOSStore}
              gaEvent={GA_EVENTS.NEW_SHIPPER_LANDING.DOWNLOAD_IOS_APP}
            />
          </div>
        </div>

        <img className={styles.wrapper__hero__image} src={vanImage} alt="" />
      </section>

      <footer className={styles.wrapper__footer}>
        <div className={styles.wrapper__footer__subscribe}>
          <h1 className={styles.wrapper__footer__title}>
            ¿Tenés dudas sobre el servicio? Escribinos a{" "}
            <a
              className={styles.wrapper__footer__title__link}
              href="mailto:info@fletti.com"
            >
              info@fletti.com
            </a>
            !
          </h1>
        </div>

        <div className={styles.wrapper__footer__copyright}>
          <Logo className={styles.wrapper__footer__copyright__logo} />

          <p className={styles.wrapper__footer__copyright__text}>
            {copyright_text}
          </p>
        </div>

        <p className={styles.wrapper__footer__text}>{copyright_text}</p>
      </footer>
    </div>
  );
};

export { NewShippersLanding };
