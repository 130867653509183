import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getItinerary, sendChatMessage } from "../../state/slices/itineraries";
import { timeFormatter } from "../../utils/date";

const useChat = ({ itinerary, itineraryId }: any) => {
  const dispatch = useDispatch();

  const [messages, setMessages] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [chatDisabled, setChatDisabled] = useState(false);

  useEffect(() => {
    if (itinerary?.chat?.messages) {
      const formattedMessages = itinerary?.chat?.messages?.map(
        (message: any) => ({
          text: message.message,
          incoming: itinerary.chat.owner_id === message.from,
          time: timeFormatter(message.created),
        })
      );
      setMessages(formattedMessages);
    }

    setChatDisabled(itinerary.status >= 5);

    return () => {
      setMessages([]);
    };
  }, [itinerary]);

  const messagesRef: any = useRef(null);

  const handleInputChange = (evt: any) => {
    setMessage(evt.target.value);
  };

  const dispatchSendChat = () => 
  dispatch(
    sendChatMessage({
      itineraryId,
      message,
      successCallback: () => {
        dispatch(getItinerary(itineraryId));
        setMessage("");
      },
    })
  );

  const handleKeyDown = (evt: any) => {
    if (evt.key === "Enter" && message !== "") {
      dispatchSendChat()
    }
  };
  const handleClick = () => {
    if (message !== "") {
      dispatchSendChat()
    } 
  };

  return {
    messagesRef,
    handleInputChange,
    handleKeyDown,
    messages,
    message,
    chatDisabled,
    handleClick
  };
};

export default useChat;
