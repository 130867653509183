import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import { History } from "history";
import google from "../../assets/images/google.svg";

import useQueryParameter2LocalStorage from "../../hooks/useQueryParameter2LocalStorage";
import { useGATracking } from "../../hooks";
import { afterSaveToken } from "../../utils";
import { API_URL, authorizeUrl, GA_EVENTS } from "../../constants";
import { Logo, Button, GuessLayout } from "../../components";

import styles from "./Login.module.scss";

export type LoginProps = {
  match: any;
  history: History;
};

const Login: FunctionComponent<LoginProps> = ({ match, history }) => {
  const param2LocalStorage = useQueryParameter2LocalStorage();

  const { application } = match.params;

  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);

  const { trackGAPageView, trackGAEvent } = useGATracking();

  useLayoutEffect(() => {
    trackGAPageView({ path: "Login" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const state = params.get("state");

    if (state !== null) {
      setState(state);
    }

    // @TODO [salva@09-06-2024] fix dispatch type.
    const handleLoginSuccess = (token: string | null, dispatch: any) => {
      trackGAEvent(GA_EVENTS.LOGIN.LOGIN_SUCCESS);

      return afterSaveToken(token, dispatch);
    };

    param2LocalStorage("cloudId");
    param2LocalStorage("@access_token", handleLoginSuccess);
    param2LocalStorage("@refresh_token");

    const error = params.get("error");

    if (error !== null) {
      setLoginError("Ocurrio un error intente nuevamente en unos minutos");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = (provider: string) => {
    const finalAuthorizeUrl = authorizeUrl.replace(
      ":application",
      application || "web"
    );

    const stateParam = state ? `&state=${state}` : "";
    window.location.href = `${API_URL}${finalAuthorizeUrl}?provider=${provider}${stateParam}`;
  };

  return (
    <GuessLayout>
      <div className={styles["wrapper"]}>
        <Logo />

        <div>
          <h4 className={styles["wrapper__title"]}>
            Ingresá a tu cuenta Fletti
          </h4>

          <h5 className={styles["wrapper__sub-title"]}>
            ¡Comenzá a operar ahora y sé parte del futuro de las entregas!
          </h5>
        </div>

        <h5 className={styles["wrapper__description"]}>
          Da de alta tu cuenta Fletti
        </h5>

        {loginError && (
          <h5 className={styles["wrapper__login-error"]}>{loginError}</h5>
        )}

        <Button
          className={styles["wrapper__social-button"]}
          type="clear"
          icon={<img src={google} alt="google" />}
          title="Acceder con Google"
          onClick={() => handleLogin("google")}
          gaEvent={GA_EVENTS.LOGIN.GOOGLE_LOGIN}
        />
      </div>
    </GuessLayout>
  );
};

export { Login };
