import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchCloudService,
  fetchUserCloudRequestService,
} from "../../services/cloud";

export const getCloud = createAsyncThunk(
  "cloud/get",
  async (cloudId: string) => {
    const result: any = await fetchCloudService(cloudId);
    return result;
  }
);

export const getCloudRequest = createAsyncThunk(
  "cloud/getCloudRequest",
  async (userId: string) => {
    const result: any = await fetchUserCloudRequestService(userId);
    return result;
  }
);
const initialState = {
  status: "idle",
  cloud: {},
  cloudRequest: null,
};

// const getActionType = (type: string) => type.split("/").slice(0, 2).join("/");

export const cloudSlice = createSlice({
  name: "cloud",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCloud.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCloud.fulfilled, (state, action) => {
        state.status = "idle";
        state.cloud = action.payload;
      })
      .addCase(getCloudRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCloudRequest.fulfilled, (state, action) => {
        state.status = "idle";
        state.cloudRequest = action.payload;
      });
  },
});

export default cloudSlice.reducer;
