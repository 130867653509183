import { useLayoutEffect } from "react";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { Button } from "../../components";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { useHistory } from "react-router-dom";

import ShipperMapsImage from "../../assets/images/shippers-map.png";
import flettiSmallLogo from "../../assets/images/fletti.svg";
import flettiWhiteLogo from "../../assets/images/fletti_white.svg";
import flettiMarker from "../../assets/images/fletti-marker.svg";
import googleIcon from "../../assets/images/icons/home-cards/google-icon.svg";
import cloudSetting from "../../assets/images/icons/home-cards/cloud-setting.svg";
import downloadApp from "../../assets/images/icons/home-cards/download-app.svg";
import startTracking from "../../assets/images/icons/home-cards/start-tracking.svg";

import { GA_EVENTS, PATHS } from "../../constants";

import { useGATracking } from "../../hooks";
import styles from "./Home.module.scss";

const Home = (): JSX.Element => {
  const history = useHistory();
  const { trackGAPageView } = useGATracking();

  useLayoutEffect(() => {
    trackGAPageView({ path: "Landing Page" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigationToLogin = () => history.push(PATHS.WEB_LOGIN);
  const handleNavigationToRegister = () => history.push("login/clouds");
  const handleNavigationToWhatsapp = () => {
    window.open(PATHS.WHATSAPP_URL);
  };

  const CARDS = [
    {
      title: "Registrate de manera simple, sólo usando tu cuenta de Google.",
      icon: <img src={googleIcon} alt="google" />,
    },
    {
      title:
        "Configurá tu nube en segundos: ingresá el nombre y alias de tu empresa.",
      icon: <img src={cloudSetting} alt="cloud setting" />,
    },
    {
      title:
        "Los repartidores descargan la app e ingresan tu alias para unirse a tu nube.",
      icon: <img src={downloadApp} alt="download app" />,
    },
    {
      title:
        "Con tu aprobación, se marcan como disponibles y comienzan a ser rastreados en tiempo real.",
      icon: <img src={startTracking} alt="start tracking" />,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.header}>
          <img src={flettiSmallLogo} alt="fletti-logo" />

          <Button
            className={styles["login-button"]}
            type="clear"
            icon={<FaUser color="orange" />}
            title="ingreso"
            size="small"
            onClick={handleNavigationToLogin}
            gaEvent={GA_EVENTS.LANDING_PAGE.LOGIN}
          />
        </div>

        <div className={styles["column-container"]}>
          <div className={styles["column-container__left"]}>
            <div>
              <h2>
                Fletti <strong>Tracker</strong>
              </h2>

              <p>
                Conocé en todo momento dónde <br />
                está tu flota, repartidores o remises.
              </p>

              <Button
                type="gradient"
                title="Registrá GRATIS tu empresa"
                onClick={handleNavigationToRegister}
                xxl
                titleClassName={styles["column-container__left__button-text"]}
                gaEvent={GA_EVENTS.LANDING_PAGE.REGISTER}
              />

              <div className={styles.info}>
                <p>
                  <img src={flettiMarker} alt="fletti-marker" />
                  Manejá todos tus envíos en tiempo real.
                </p>
                <p>
                  <img src={flettiMarker} alt="fletti-marker" />
                  Aprovechá 6 meses gratis de prueba.
                </p>
              </div>
            </div>
          </div>

          <div className={styles["column-container__right"]}>
            <img src={ShipperMapsImage} alt="" />
          </div>
        </div>

        <div className={styles["how-it-works"]}>
          <h3>¿Cómo funciona?</h3>
          <div className={styles["how-it-works__divider"]} />
          <div className={styles["info-row"]}>
            {CARDS.map((card, index) => (
              <div key={index} className={styles["info-row__card"]}>
                <div>{index + 1}</div>
                <div className={styles["info-row__card__icon"]}>
                  {card.icon}
                </div>
                <p>{card.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <footer className={styles.footer}>
        <div>
          <img
            className={styles["fletti-logo"]}
            src={flettiWhiteLogo}
            alt="fletti shipper"
          />
          <p>Derechos reservados 2024.</p>
        </div>

        <p>El futuro de las entregas en tus manos</p>

        <div
          className={styles["whatsapp--wrapper"]}
          onClick={handleNavigationToWhatsapp}
        >
          <Button
            className={styles.whatsapp}
            icon={<FaWhatsapp size={32} color="white" />}
          />
          <Button
            type="green-gradient"
            title="¿Tenés dudas?"
            onClick={handleNavigationToWhatsapp}
            gaEvent={GA_EVENTS.LANDING_PAGE.WHATSAPP}
          />
        </div>
      </footer>
    </div>
  );
};

export { Home };
