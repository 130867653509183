import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { History } from "history";
import { PATHS } from "../../constants/paths";
import { setIsLogged } from "../../state/slices/user";

export type LogoutInterface = {
  history: History;
};

const Logout: FunctionComponent<LogoutInterface> = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLogged(false));

    localStorage.removeItem("@access_token");
    localStorage.removeItem("@refresh_token");

    history.push(PATHS.HOME);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export { Logout };
