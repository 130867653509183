import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import cn from "classnames";

import { Button } from "../..";

import styles from "./SidebarItem.module.scss";

export interface SidebarItemProps {
  key?: string;
  path: string;
  name: string;
  icon?: JSX.Element;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ path, name, icon }) => {
  const history = useHistory();

  const isCurrentPath = useMemo(
    () => history.location.pathname === path,
    [history, path]
  );

  const handleRedirect = useCallback(() => history.push(path), [history, path]);

  return (
    <li
      className={cn(styles.item, {
        [styles["item--selected"]]: isCurrentPath,
      })}
    >
      <Button
        type="clear"
        title={name}
        onClick={handleRedirect}
        icon={icon}
        className={styles.item__text}
        iconClassName={styles.item__icon}
      />
    </li>
  );
};

export { SidebarItem };
