import { State } from "../../interfaces";

export const itinerarySelector = (state: State) => state.itineraries;

export const currentItinerarySelector =
  (itineraryId?: string) => (state: State) => {
    const { itineraries }: any = itinerarySelector(state);

    if (!itineraryId || !itineraries) return null;

    return itineraries[itineraryId];
  };
