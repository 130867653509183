import { FunctionComponent } from "react";
import logo from "../../../../assets/images/logo-small.svg";
import cn from "classnames";

import styles from "./Marker.module.scss";

export type MarkerProps = {
  status?: "pending" | "in-progress" | "done";
  title?: string;
  isStop?: boolean;
  isCurrentStop?: boolean;
};

const Marker: FunctionComponent<MarkerProps> = ({
  status = "done",
  title,
  isStop = false,
  isCurrentStop = false,
}) => {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles["wrapper--pending"]]: status === "pending",
        [styles["wrapper--in-progress"]]: status === "in-progress",
      })}
    >
      <span
        className={cn(styles.wrapper__line, {
          [styles["wrapper__line--pending"]]: status === "pending",
          [styles["wrapper__line--in-progress"]]: status === "in-progress",
        })}
      ></span>

      <div>
        <div
          className={cn(styles["wrapper__image-container"], {
            [styles["wrapper__image-container--pending"]]: status === "pending",
            [styles["wrapper__image-container--isStopPending"]]:
              status === "pending" && isStop && !isCurrentStop,
            [styles["wrapper__image-container--in-progress"]]:
              status === "in-progress" || isCurrentStop,
            [styles["wrapper__image-container--done"]]: status === "done",
          })}
        >
          <img src={logo} alt="fletti" />
        </div>

        <p
          className={cn(styles["wrapper__title"], {
            [styles["wrapper__title--pending"]]: status === "pending",
            [styles["wrapper__title--in-progress"]]: status === "in-progress",
            [styles["wrapper__title--done"]]: status === "done",
          })}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default Marker;
