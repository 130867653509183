import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { setIsLogged } from "../state/slices/user";

export const keyToId = (obj: object, key = "user_id") =>
  Object.entries(obj).map((entry) => ({
    ...entry[1],
    [key]: entry[0],
  }));

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");

  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";

  document.body.appendChild(el);

  const selected =
    (document.getSelection()?.rangeCount || 0) > 0
      ? document.getSelection()?.getRangeAt(0)
      : false;

  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  if (selected) {
    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(selected);
  }
};

  export const afterSaveToken = (value: string | null, dispatch: Dispatch) => {
    if (value !== null) {
      axios.defaults.headers.common.Authorization = `Bearer ${value}`;

      dispatch(setIsLogged(true));
    }
  };
