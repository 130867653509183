import { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { Shipper } from "../../../../../../interfaces";
import Telephone from "../../../../../../assets/images/icons/telephone.svg";
import Email from "../../../../../../assets/images/icons/email.svg";
import { formatPhoneNumber } from "../../../../../../utils/telephone";
import { kickShipperService } from "../../../../../../services/cloud";
import { getShippers } from "../../../../../../state/slices/shippers";
import { Divider } from "../../../../../../components";
import KickShipperModal from "../KickShipperModal/KickShipperModal";

import styles from "./TrackingShipperItemMenu.module.scss";

type Props = {
  shipper: Shipper;
  visible: boolean;
  close: () => void;
};

function TrackingShipperItemMenu({
  close,
  visible,
  shipper: { id, general_profile, shipper_profile, fullname },
}: Props) {
  const dipatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const cloudId = shipper_profile?.shippers_cloud_id;
  const handleKickShipper = () => {
    if (id && cloudId) {
      kickShipperService(cloudId, id).then(() => {
        dipatch(getShippers());
        close();
      });
    }
  };

  return (
    <>
      <KickShipperModal
        close={() => {
          setModalVisible(false);
          close();
        }}
        visible={modalVisible}
        fullname={fullname || general_profile?.name}
        onClick={handleKickShipper}
      />

      <div
        onMouseLeave={close}
        className={classNames(styles.wrapper, {
          [styles["wrapper--visible"]]: visible,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles["information"]}>
          <img src={Telephone} alt="telephone" />
          <small>
            {(general_profile?.phone &&
              formatPhoneNumber(general_profile?.phone)) ||
              "-- ---- ----"}
          </small>
        </div>
        <div className={styles["information"]}>
          <img src={Email} alt="email" />
          <small>
            {(general_profile?.phone && general_profile?.email) ||
              "-- ---- ----"}
          </small>
        </div>
        <div>
          <Divider />
          <button
            className={styles["deactivate-link"]}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setModalVisible(true);
            }}
          >
            Desactivar Repartidor
          </button>
        </div>
      </div>
    </>
  );
}

export default TrackingShipperItemMenu;
