import { FunctionComponent, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { orange, violet } from "../../../constants/colors";

import { REQUEST_STATUSES } from "../../../constants/requests";
import { moneyFormatter } from "../../../utils/money";
import { useSelector } from "react-redux";
import {
  generalProfileSelector,
  userBusinessNameSelector,
  getUserIdSelector,
} from "../../../state/selectors/user";
import { businessDebtSelector } from "../../../state/selectors/businesses";

import { TextStack, Divider, Button, Avatar } from "../../../components";

import { FaMoneyBill } from "@react-icons/all-files/fa/FaMoneyBill";
import {
  payOpenBusinessDebt,
  payPendingBusinessDebt,
} from "../../../services/shippers";

import styles from "./InfoCard.module.scss";

export type InfoCardProps = {
  requests: any[];
};

const InfoCard: FunctionComponent<InfoCardProps> = ({ requests = [] }) => {
  const [payOpenButtonDisabled, setPayOpenButtonDisabled] = useState(false);
  const [payPendingButtonDisabled, setPayPendingButtonDisabled] =
    useState(false);

  const userId = useSelector(getUserIdSelector);
  const businessDebt = useSelector(businessDebtSelector(userId));
  const profile = useSelector(generalProfileSelector);
  const userBusinessName = useSelector(userBusinessNameSelector);

  const profilePhoto = useMemo(() => {
    const { photo } = profile || {};

    return photo ? `data:image/jpeg;base64, ${photo}` : undefined;
  }, [profile]);

  const handlePayOpenBusinessDebt = async () => {
    if (!businessDebt.shippersCloudId) return;

    setPayOpenButtonDisabled(true);
    const response: any = await payOpenBusinessDebt(
      userId,
      businessDebt.shippersCloudId
    );
    if (response?.init_point) {
      window.location.href = response.init_point;
    } else {
      setPayOpenButtonDisabled(false);
    }
  };

  const handlePayPendingBusinessDebt = async () => {
    if (!businessDebt.pendingBillId || !businessDebt.shippersCloudId) return;

    setPayPendingButtonDisabled(true);
    const response: any = await payPendingBusinessDebt(
      businessDebt.pendingBillId,
      userId,
      businessDebt.shippersCloudId
    );
    if (response?.init_point) {
      window.location.href = response.init_point;
    } else {
      setPayPendingButtonDisabled(false);
    }
  };

  const summaryData = [
    // {
    //   title: requests.length,
    //   subtitle: "Pedidos",
    //   color: violet,
    //   xxl: true,
    // },
    {
      title: requests.filter(
        (request) => request.status === REQUEST_STATUSES.ACCEPTED
      ).length,
      subtitle: "Pedidos aceptados",
      color: violet,
      xxl: true,
    },
    {
      title: requests.filter(
        (request) => request.status !== REQUEST_STATUSES.ACCEPTED
      ).length,
      subtitle: "Pedidos en espera",
      color: violet,
      xxl: true,
    },
  ];

  const openDebt = {
    title: moneyFormatter(businessDebt.openTotal),
    subtitle: "Saldo Actual",
    color: orange,
    xxl: true,
  };

  const pendingDebt = {
    title: moneyFormatter(businessDebt.pendingTotal),
    subtitle: "Saldo Pendiente",
    color: orange,
    xxl: true,
  };

  return (
    <div className={styles.wrapper}>
      <Avatar src={profilePhoto} />

      <TextStack title={profile?.nickname} subtitle={userBusinessName} />

      {summaryData && (
        <>
          <Divider vertical />

          {summaryData.map((item) => (
            <TextStack key={uuidv4()} {...item} />
          ))}
        </>
      )}

      <Divider vertical />
      <TextStack key={uuidv4()} {...openDebt} />
      <Button
        title="Pagar"
        color={orange}
        icon={<FaMoneyBill color="#fff" />}
        disabled={
          businessDebt?.openTotal === 0 || businessDebt?.pendingTotal > 0
        }
        loading={payOpenButtonDisabled}
        onClick={handlePayOpenBusinessDebt}
      />

      <TextStack key={uuidv4()} {...pendingDebt} />
      <Button
        title="Pagar"
        color={violet}
        icon={<FaMoneyBill color="#fff" />}
        disabled={businessDebt?.pendingTotal === 0}
        loading={payPendingButtonDisabled}
        onClick={handlePayPendingBusinessDebt}
      />
    </div>
  );
};

export default InfoCard;
