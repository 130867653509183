import { FunctionComponent } from "react";
import DefaultImage from "../../assets/images/user.svg";
import { FaPen } from "@react-icons/all-files/fa/FaPen";
import { orange } from "../../constants/colors";
import { Button } from "..";

import styles from "./Avatar.module.scss";

export type AvatarInterface = {
  src?: string;
  edit?: boolean;
  title?: JSX.Element | string;
};

const Avatar: FunctionComponent<AvatarInterface> = ({ src, edit, title }) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.avatar} src={src ? src : DefaultImage} alt="" />

      {title}

      {edit && (
        <Button
          className={styles.avatar__edit}
          type="clear"
          icon={
            <FaPen
              className={styles.avatar__edit__icon}
              color={orange}
              size={8}
            />
          }
        />
      )}
    </div>
  );
};

export { Avatar };
