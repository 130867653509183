import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";

import { PATHS } from "../../../constants/paths";

import { Avatar, Card, TextStack, Button, Divider } from "../../../components";

import { BusinessData } from "../../../interfaces/BusinessData";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import useExtraInfo from "./useExtraInfo";
import styles from "./BusinessItem.module.scss";

export type ExtraInfoProps = {
  className?: string;
  businessData: BusinessData;
};

const ExtraInfo: FunctionComponent<ExtraInfoProps> = ({
  className,
  businessData,
}) => {
  const {
    businessAcceptanceLoading,
    handleAcceptBusiness,
    handleDeclineBusiness,
  } = useExtraInfo();
  const history = useHistory();

  const company_data = [
    {
      subtitle: businessData.business_identification_type,
      title: businessData.business_identification_number,
    },
    {
      subtitle: "Razon Social",
      title: businessData.business_name,
    },
    {
      subtitle: "Direccion 1",
      title: businessData.address_line || "No disponible",
    },
  ];

  return (
    <div className={cn(styles["extra-info"], className)}>
      <Card className={styles["extra-info__box"]}>
        <p className={styles.title}>Manager de empresa</p>

        <div className={styles.row}>
          <Avatar />
          <TextStack title={businessData.contact.name} subtitle="Nombre" />
          <TextStack
            title={businessData.identification_number}
            subtitle={businessData.identification_type}
          />
          <TextStack
            title={businessData.contact.nickname}
            subtitle="Nickname"
          />
          <TextStack title={businessData.contact.email} subtitle="E-mail" />
          <TextStack
            title={businessData.contact.phone}
            subtitle="Num de contacto"
          />
        </div>
        <p className={styles.title}>Empresa</p>
        <div className={styles.row}>
          <>
            {company_data.map((data: any) => (
              <TextStack {...data} key={uuidv4()} />
            ))}
          </>
        </div>
        <div className={styles["card-footer-container"]}>
          <Divider />
          {businessData.status === "Pendiente" ? (
            <>
              <Button
                title="Rechazar"
                type="clear"
                icon={<FaTimes />}
                onClick={() => handleDeclineBusiness(businessData.business_id)}
                loading={businessAcceptanceLoading}
              />

              <Button
                title="Dar de Alta"
                type="gradient"
                icon={<FaLink />}
                onClick={() => handleAcceptBusiness(businessData.business_id)}
                loading={businessAcceptanceLoading}
              />
            </>
          ) : (
            <>
              <Button
                title="Dar de baja"
                xxl
                type="clear"
                icon={<FaTimes />}
                // onClick={handleDeclineShipper}
              />

              <Button
                title="Ver pedidos"
                xxl
                type="orange-gradient"
                icon={<FaLink />}
                onClick={() =>
                  history.push(
                    PATHS.HISTORY + `?businessID=${businessData.business_id}`
                  )
                }
              />
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ExtraInfo;
