function insertObject<T>(arr: T[], value: T, index: number): T[] {
  return [...arr.slice(0, index), value, ...arr.slice(index)];
}

function removeObject<T>(arr: T[], index: number): T[] {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function moveToNewIndex<T>(
  arr: T[],
  srcIndex: number,
  dstIndex: number
): T[] {
  const value = arr[srcIndex];
  return insertObject(removeObject(arr, srcIndex), value, dstIndex);
}
