import { Modal, Button } from "../../../../components";
import { GA_EVENTS } from "../../../../constants";
import { UseTrackingScreenOutput } from "../../types";
import styles from "./EnrollShipperModal.module.scss";

type Props = Pick<
  UseTrackingScreenOutput,
  | "handleAcceptShipper"
  | "handleDeclineShipper"
  | "handleShowAcceptShipperModal"
  | "shipperAcceptanceLoading"
  | "showAcceptShipperModal"
>;

const EnrollShipperModal = ({
  handleAcceptShipper,
  handleDeclineShipper,
  handleShowAcceptShipperModal,
  shipperAcceptanceLoading,
  showAcceptShipperModal,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={showAcceptShipperModal}
      onClose={() => handleShowAcceptShipperModal(false)}
    >
      <>
        <h3>Dar de alta el repartidor</h3>

        <div className={styles["modal__buttons-container"]}>
          <Button
            type="clear"
            title="Cancelar"
            onClick={() => handleShowAcceptShipperModal(false)}
            loading={shipperAcceptanceLoading}
            gaEvent={GA_EVENTS.SHIPPERS.ENROLL_SHIPPER_MODAL_CANCEL}
          />

          <Button
            type="clear"
            title="Rechazar"
            onClick={handleDeclineShipper}
            gaEvent={GA_EVENTS.SHIPPERS.ENROLL_SHIPPER_MODAL_DECLINE}
          />

          <Button
            type="gradient"
            title="Dar de Alta"
            onClick={handleAcceptShipper}
            loading={shipperAcceptanceLoading}
            gaEvent={GA_EVENTS.SHIPPERS.ENROLL_SHIPPER_MODAL_ENROLL}
          />
        </div>
      </>
    </Modal>
  );
};

export { EnrollShipperModal };
