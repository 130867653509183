import { FunctionComponent } from "react";
import { v4 as uuidv4 } from "uuid";
import SummaryStep from "../../routes/CreateRequest/components/SummaryStep";
import { Card } from "../Card";

import styles from "./RequestStopsDetails.module.scss";

type Props = {
  stops: any;
  currentStop?: any;
  requestDistance: number;
};

const RequestStopsDetails: FunctionComponent<Props> = ({
  stops,
  currentStop,
  requestDistance,
}) => {
  return (
    <Card className={styles["wrapper__first-row__steps-wrapper"]}>
      {stops.map((stop: any, index: number) => (
        <SummaryStep
          {...stop}
          stop={index}
          status={stop.status}
          confirmationCode={stop.confirmationCode}
          currentStop={currentStop === stop}
          firstStop={index === 0}
          lastStop={index === stops.length - 1}
          key={uuidv4()}
          editMode={false}
        />
      ))}
      <h3>Distancia total del recorrido: {requestDistance} kms</h3>
    </Card>
  );
};

export { RequestStopsDetails };
