import { FunctionComponent, useMemo, memo } from "react";
import { useDispatch } from "react-redux";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";

import { PackageItemInterface } from "../../../../interfaces/Request";
import { updatePackages } from "../../../../state/slices/request";
import { violet } from "../../../../constants/colors";

import { Input, Button } from "../../../../components";

import styles from "./Packages.module.scss";

export type PackageItemProps = {
  id: number;
  deleteItem: () => void;
  data: PackageItemInterface;
  stop: number;
};

const PackageItem: FunctionComponent<PackageItemProps> = ({
  id,
  deleteItem,
  data,
  stop,
}) => {
  const dispatch = useDispatch();

  const handleInputChange = (field: string) => (value: string) => {
    dispatch(updatePackages({ index: id, pkgData: { [field]: value }, stop }));
  };

  const { description, weight } = useMemo(() => data, [data]);

  return (
    <div className={styles["wrapper__package-row"]}>
      <Input
        label={`Descripción paquete ID ${id}`}
        className={styles["margin-bottom"]}
        onChange={handleInputChange("description")}
        value={description}
        required
      />

      <Input
        label="Peso aproximado (kgs)"
        placeholder="24 kg"
        type="number"
        onChange={handleInputChange("weight")}
        value={Number(weight)}
        step={1}
      />

      <Button
        icon={<FaTrash color={violet} />}
        type="clear"
        onClick={deleteItem}
      />
    </div>
  );
};

export default memo(PackageItem);
