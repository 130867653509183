import { FunctionComponent, useMemo, memo } from "react";
import isEmpty from "lodash/isEmpty";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";

import { orange } from "../../../../constants/colors";
import { Button } from "../../../../components";
import PackageItem from "./PackageItem";

import usePackages from "./usePackages";
import styles from "./Packages.module.scss";

export type PackagesProps = {
  stop: number;
  packagesCount?: number;
  setPackagesCount?: (n: number) => void;
};

const Packages: FunctionComponent<PackagesProps> = ({
  stop,
  packagesCount,
  setPackagesCount,
}) => {
  const { addPackage, packages, removePackage } = usePackages({
    stop,
    packagesCount,
    setPackagesCount,
  });

  const packageItems = useMemo(
    () =>
      Object.entries(packages).map(([index]: [any, any]) => (
        <PackageItem
          key={index}
          id={index}
          deleteItem={removePackage(index)}
          data={packages[index]}
          stop={stop}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [packages]
  );

  const hasPackages = useMemo(() => !isEmpty(packages), [packages]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__header}>
        <h2>Paquetes a retirar</h2>

        <Button
          title="Agregar Paquete p/retirar"
          color={orange}
          onClick={addPackage}
          icon={<FaPlus />}
          type="gradient"
        />
      </div>

      {packageItems}
      {!hasPackages && <p>No hay paquetes para retirar</p>}

      {hasPackages && (
        <span className={styles.wrapper__message}>
          (recordá detallar cantidad de unidades, contenido neto, tamaño y más.
          Ej: Tres cajas medianas con libros)
        </span>
      )}
    </div>
  );
};

export default memo(Packages);
