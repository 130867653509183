import { useMemo, useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  currentRequestSelector,
  requestTrackingSelector,
} from "../../state/selectors/requests";
import { getRequest, getRequestTracking } from "../../state/slices/request";

import { getPoints, getPointsForTracking } from "../../services/request";
import { fetchRoutes } from "../../services/routes";

import type { RequestProps } from "./Request";
import { getItinerary } from "../../state/slices/itineraries";
import { currentItinerarySelector } from "../../state/selectors/itinerary";
import { REQUEST_STATUSES } from "../../constants/requests";
import { userSelector } from "../../state/selectors/user";
import { dateTimeFormatter, timeFormatter } from "../../utils/date";
import { moneyFormatter } from "../../utils/money";
import { getVehicleCategory } from "../../constants/shippers";

const EMPTY_ROUTE = {
  route: { overview_polyline: { points: "" } },
  commitment: 0,
};

const formatStop = (stop: any = {}) => ({
  ...stop,
  address: {
    ...stop.address,
    description: Object.values(stop?.address?.address_lines || {}).join(", "),
  },
  manager: stop?.player?.nickname,
  confirmationCode: stop.confirmation_code,
});

const useRequest = ({ match }: RequestProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [route, setRoute] = useState(EMPTY_ROUTE);
  const [shipperRoute, setShipperRoute] = useState(EMPTY_ROUTE);

  const { requestId } = match.params;

  const user = useSelector(userSelector);
  const currentRequest = useSelector(currentRequestSelector(requestId));

  const currentItinerary = useSelector(
    currentItinerarySelector(currentRequest?.itinerary_id)
  );

  const tracking = useSelector(requestTrackingSelector(requestId));

  const isLoading = useMemo(
    () => isEmpty(user) || isEmpty(currentRequest),
    [user, currentRequest]
  );

  useEffect(() => {
    if (isEmpty(currentRequest)) {
      if (user.id) {
        dispatch(getRequest(requestId));
      }
    } else {
      fetchRoutes({ points: getPoints(currentRequest) }).then(setRoute);
    }
  }, [dispatch, user, currentRequest, requestId]);

  useEffect(() => {
    if (!isEmpty(currentRequest) && currentRequest.itinerary_id) {
      dispatch(getItinerary(currentRequest.itinerary_id));
    }
  }, [dispatch, currentRequest]);

  useEffect(() => {
    if (
      !isEmpty(currentRequest) &&
      currentRequest.itinerary_id &&
      currentRequest.status !== REQUEST_STATUSES.FINISHED
    ) {
      dispatch(getRequestTracking(requestId));
    }
  }, [dispatch, currentRequest, requestId]);

  useEffect(() => {
    if (!isEmpty(tracking) && !isEmpty(currentRequest)) {
      fetchRoutes({
        points: getPointsForTracking(currentRequest, tracking),
      }).then(setShipperRoute);
    }
  }, [dispatch, tracking, currentRequest]);

  const requestStatus = useMemo(
    () => currentRequest?.status || 0,
    [currentRequest]
  );

  const requestItineraryID: string = useMemo(
    () => currentRequest?.itinerary_id || "",
    [currentRequest]
  );

  const polyline: any = useMemo(() => {
    if (isEmpty(route?.route?.overview_polyline?.points)) {
      return [];
    }

    const { geometry } = google?.maps;

    return geometry.encoding.decodePath(route.route.overview_polyline.points);
  }, [route]);

  const shipperPolyline: any = useMemo(() => {
    if (isEmpty(shipperRoute?.route?.overview_polyline?.points)) {
      return [];
    }

    const { geometry } = google?.maps;

    return geometry.encoding.decodePath(
      shipperRoute.route.overview_polyline.points
    );
  }, [shipperRoute]);

  const stops = useMemo(() => {
    const { sections = [] } = currentRequest || {};

    return [
      formatStop(sections[0]?.start),
      ...sections.map((section: any) => formatStop(section.end)),
    ];
  }, [currentRequest]);

  const currentStop = useMemo(() => {
    return stops.find((stop) => !stop.status || stop.status < 3);
  }, [stops]);

  const requestDistance = useMemo(() => {
    const { sections = [] } = currentRequest || {};
    const distanceInMts = sections.reduce(
      (acc: number, section: { distance: number }) => (acc += section.distance),
      0
    );
    return Math.round(distanceInMts / 1000);
  }, [currentRequest]);

  const shipper = useMemo(() => {
    if (isEmpty(currentRequest)) {
      return {};
    }

    const { events } = currentRequest;

    const shipperEvent = events?.find(
      (evt: any) => evt.event_type === "accepted"
    );

    return shipperEvent?.shipper;
  }, [currentRequest]);

  const requestCreationDate = dateTimeFormatter(currentRequest?.created);
  const requestCommitment = useMemo(() => {
    return timeFormatter(
      moment(currentRequest?.created).add(
        currentItinerary?.shipper?.commitment,
        "seconds"
      )
    );
  }, [currentRequest, currentItinerary]);

  const requestVehicleType = useMemo(() => {
    return getVehicleCategory(currentRequest?.vehicle_category);
  }, [currentRequest]);

  const requestPrice = useMemo(() => {
    const priceComponentsLength =
      currentRequest?.price?.price_components?.length;

    if (!priceComponentsLength) return "No Disponible";

    const total =
      currentRequest?.price?.price_components[priceComponentsLength - 1].value;

    return moneyFormatter(total);
  }, [currentRequest]);

  const shouldSearchAvailability = useMemo(
    () =>
      !isEmpty(currentRequest) &&
      (currentRequest.status === REQUEST_STATUSES.PENDING ||
        currentRequest.status === REQUEST_STATUSES.WAITING_RESPONSE),
    [currentRequest]
  );

  return {
    currentItinerary,
    currentStop,
    requestDistance,
    history,
    isLoading,
    polyline,
    shouldSearchAvailability,
    requestCommitment,
    requestCreationDate,
    requestId,
    requestVehicleType,
    requestItineraryID,
    requestPrice,
    requestStatus,
    shipper,
    shipperPolyline,
    stops,
    tracking,
  };
};

export default useRequest;
