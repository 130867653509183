import { useMemo, useEffect, useState } from "react";
import { getRequestAvailability } from "../../../../services/request";
import { createOffers } from "../../../../services/shipper-offer";

import isEmpty from "lodash/isEmpty";

const useEmptyState = ({ requestId, shouldSearchAvailability }: any) => {
  const [requestAvailability, setRequestAvailability] = useState<any>([]);

  const shippersAvailable = useMemo(
    () => requestAvailability?.data?.shippers || [],
    [requestAvailability]
  );

  useEffect(() => {
    if (shouldSearchAvailability) {
      getRequestAvailability(requestId).then(setRequestAvailability);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSearchAvailability]);

  const handleShippersOffer = () => {
    if (requestId && !isEmpty(shippersAvailable)) {
      createOffers({ request_id: requestId, shippers: shippersAvailable });
    }
  };

  const handleShipperOffer = (shipper: any) => {
    if (requestId && !isEmpty(shippersAvailable)) {
      createOffers({ request_id: requestId, shippers: [shipper] });
    }
  };

  return {
    requestAvailability,
    shippersAvailable,
    handleShippersOffer,
    handleShipperOffer,
  };
};

export default useEmptyState;
