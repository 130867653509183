import { Skeleton, SkeletonGroup } from "../../../../components";

import styles from "./SkeletonMap.module.scss";

const SkeletonMap = (): JSX.Element => {
  return (
    <Skeleton containerStyle={styles.wrapper} height="100%">
      <SkeletonGroup containerStyles={styles.map}>
        <div className={styles.map__top}>
          <Skeleton width="200px" height="38px" />

          <Skeleton square width="42px" />
        </div>

        <div className={styles.map__bottom}>
          <Skeleton square width="42px" />
          <Skeleton square width="42px" />
          <Skeleton square width="42px" />
        </div>
      </SkeletonGroup>
    </Skeleton>
  );
};

export { SkeletonMap };
