import { FunctionComponent, useCallback, useMemo } from "react";
import { CardWrap } from "../CardWrap";
import cn from "classnames";

import { Label } from "..";

import styles from "./Input.module.scss";

export type InputProps = {
  border?: boolean;
  error?: string | null;
  icon?: JSX.Element;
  label?: string;
  name?: string;
  onChange?: (s: string) => void;
  placeholder?: string;
  theme?: string;
  twoCols?: boolean;
  type?: string;
  value?: any;
  className?: string;
  checked?: boolean;
  noComplete?: boolean;
  required?: boolean;
  disabled?: boolean;
  step?: number;
  min?: number;
  maxLength?: number;
};

const Input: FunctionComponent<InputProps> = ({
  border,
  error,
  icon,
  label,
  name,
  onChange,
  placeholder,
  theme,
  twoCols,
  type = "text",
  value,
  className,
  checked = false,
  noComplete = false,
  required,
  disabled,
  step,
  min = 0,
  maxLength,
}) => {
  const isFile = useMemo(() => type === "file", [type]);
  const isCheck = useMemo(() => type === "checkbox", [type]);
  const isRadio = useMemo(() => type === "radio", [type]);

  const hasError = useMemo(() => error && error !== null, [error]);

  /**
   * @todo refactor me :(
   */
  const inputID = useMemo(
    () => `Input${name}_${label?.replaceAll(" ", "_").toUpperCase()}`,
    [name, label]
  );

  const handleInputClick = useCallback(
    () => document.getElementById(inputID)?.click(),
    [inputID]
  );

  const handleInputChange = (evt: any) => {
    if (typeof onChange === "function") {
      onChange(isCheck ? evt.target.checked : evt.target.value);
    }
  };

  return (
    <CardWrap theme={theme} twoCols={twoCols}>
      <div
        className={cn(styles.wrapper, className, {
          [styles["wrapper--two-columns"]]: twoCols,
          [styles["input--checkbox"]]: isCheck,
          [styles["input--radio"]]: isRadio,
        })}
      >
        {label && (
          <Label htmlFor={inputID} required={required}>
            {label}
          </Label>
        )}

        <div
          className={cn(styles.input, {
            [styles["input--bordered"]]: border,
            [styles["input--radio"]]: isRadio,
            [styles["input--file"]]: isFile,
            [styles["input--error"]]: hasError,
          })}
          onClick={isFile ? handleInputClick : undefined}
        >
          {icon && <div className={styles.input__icon}>{icon}</div>}

          <input
            id={inputID}
            name={name}
            className={cn(styles.input__element, {
              [styles["input__element--file"]]: isFile,
              [styles["input__element--checkbox"]]: isCheck,
              [styles["input__element--bordered"]]: border,
            })}
            type={type}
            placeholder={placeholder}
            onChange={handleInputChange}
            disabled={disabled}
            value={value}
            checked={checked}
            autoComplete={noComplete ? "off" : "on"}
            step={step}
            min={min}
            maxLength={maxLength}
          />
        </div>

        {hasError && <span className={styles.error}>{error}</span>}
      </div>
    </CardWrap>
  );
};

export { Input };
