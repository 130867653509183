import { FunctionComponent, ReactNode } from "react";
import styles from "./Card.module.scss";

export type CardProps = {
  children: ReactNode;
  className?: string;
  onClick?: (evt: any) => void;
};

const Card: FunctionComponent<CardProps> = ({
  children,
  className = "",
  onClick,
}) => {
  return (
    <div className={`${styles.wrapper} ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export { Card };
