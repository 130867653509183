import { FunctionComponent } from "react";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";

import { Input } from "..";

import usePlaceAutocomplete from "./usePlaceAutocomplete";
import styles from "./PlaceAutocomplete.module.scss";

export type PlaceAutocompleteProps = {
  onChange: (x: any) => void;
  value: any;
  className?: string;
  error?: string | null;
  required?: boolean;
};

const PlaceAutocomplete: FunctionComponent<PlaceAutocompleteProps> = ({
  className,
  error,
  required,
  ...props
}) => {
  const { predictions, inputValue, handleInputChange, handleSelectItem } =
    usePlaceAutocomplete(props);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Input
        label="Dirección"
        type="text"
        onChange={handleInputChange}
        className={styles.wrapper__input}
        value={inputValue}
        placeholder="Segurola 4310"
        noComplete
        error={error}
        required={required}
      />
      {!isEmpty(predictions) && (
        <ul className={styles.wrapper__list}>
          {predictions.map((prediction: any) => (
            <li
              className={styles.wrapper__list__item}
              key={uuidv4()}
              onClick={handleSelectItem(prediction)}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className={styles.wrapper__list__item__prediction}>
                {prediction.description}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { PlaceAutocomplete };
