import moment from "moment";
import fetchCreator from "../utils/fetchCreator";

const client = new fetchCreator();

export const getItinerary = (itineraryId: string) =>
  client.get(`itinerary/${itineraryId}`).then((data: any) => data.data);

export const sendChatMessage = (itineraryId: string, message: string) => {
  const body = {
    message,
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
  };
  return client
    .post(`/chat/itinerary/${itineraryId}`, body)
    .then((data: any) => data.data);
};
