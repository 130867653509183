import { FunctionComponent, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Card,
  Divider,
  Button,
  LoadingOrContent,
  RequestStopsDetails,
  TextStack,
  TextStackProps,
  Skeleton,
  SkeletonGroup,
} from "../../../../components";
import StepProps from "../StepProps";

import useSummary from "./useSummary";
import styles from "./ThirdStep.module.scss";

const ThirdStep: FunctionComponent<StepProps> = ({ data, handlePrevStep }) => {
  const {
    isPriceLoading,
    priceData,
    requestDistance,
    request,
    areOptimizationsAvailable,
    shouldOptimize,
    setShouldOptimize,
    handleSubmit,
    getRequestPrices,
  } = useSummary({ data });

  useEffect(() => {
    getRequestPrices(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOptimize]);

  return (
    <div className={styles.wrapper}>
      <div className={styles["wrapper__first-col"]}>
        <Divider title="Descripción de Pedido" />

        <RequestStopsDetails
          stops={request.stops}
          requestDistance={requestDistance}
        />
      </div>
      <div className={styles["wrapper__second-col"]}>
        <Divider title="Estimación precio y horas" />

        <Card>
          <LoadingOrContent
            loading={isPriceLoading}
            customLoading={
              <>
                {[1, 2, 3, 4].map(() => (
                  <SkeletonGroup>
                    <Skeleton />
                    <Skeleton circle />
                  </SkeletonGroup>
                ))}

                <Skeleton />
              </>
            }
          >
            <h4 className={styles.title}>Precio</h4>

            <>
              {priceData.map((props: TextStackProps) => (
                <TextStack {...props} key={uuidv4()} />
              ))}
            </>
            <div className={styles["wrapper__actions-container"]}>
              <Button
                disabled={!areOptimizationsAvailable}
                title={shouldOptimize ? "Deshacer" : "Optimizar"}
                type="orange-gradient"
                className={styles["center-button"]}
                onClick={() => setShouldOptimize((value) => !value)}
              />

              <Button
                title="Procesar Pedido"
                type="gradient"
                className={styles["center-button"]}
                onClick={() => handleSubmit(request)}
                loading={data?.status?.["request/create"] === "loading"}
              />
            </div>
          </LoadingOrContent>
        </Card>
      </div>
    </div>
  );
};

export default ThirdStep;
