import { FunctionComponent } from "react";

import style from "./Label.module.scss";

export type LabelProps = {
  htmlFor?: string;
  children: string;
  required?: boolean;
};

const Label: FunctionComponent<LabelProps> = ({
  htmlFor,
  children,
  required = false,
}) => {
  return (
    <label htmlFor={htmlFor} className={style.label}>
      {children}

      {required && <small className={style.label__required}>*</small>}
    </label>
  );
};

export { Label };
