import { USER_PROFILE_KEYS } from "../../types";
import {
  noEmpty,
  emailValidator,
  validatePhone,
  validateID,
  validateBusinessID,
} from "../../utils/validators";

export const validators: Record<
  USER_PROFILE_KEYS,
  Record<string, (value: string) => string | null>
> = {
  [USER_PROFILE_KEYS.GENERAL_PROFILE]: {
    name: noEmpty,
    email: emailValidator,
    phone: validatePhone,
    identification_type: noEmpty,
    identification_number: validateID,
  },
  [USER_PROFILE_KEYS.BUSINESS_PROFILE]: {
    business_name: noEmpty,
    identification_type: noEmpty,
    identification_number: validateBusinessID,
    address_line: noEmpty,
  },
};
