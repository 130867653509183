import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getUser } from "../../state/slices/user";
import { noEmpty } from "../../utils/validators";
import { toast } from "react-toastify";
import { GA_EVENTS, toastConfig } from "../../constants";
import { PATHS } from "../../constants/paths";
import { userCloudRequestSelector } from "../../state/selectors/cloud";
import { createCloudService } from "../../services/cloud";
import { getCloudRequest } from "../../state/slices/cloud";
import { getRolesFromJwt, getUserIdFromJwt } from "../../utils/jwt";
import { useGATracking } from "../../hooks";

const useRegisterCloud = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const INIT_DATA = {
    name: "",
    alias: "",
    phone: "",
  };

  const [data, setData] = useState(INIT_DATA);
  const [errors, setErrors] = useState(INIT_DATA);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [accessToken, setAccessToken] = useState<string | null>("");
  const [refreshToken, setRefreshToken] = useState<string | null>("");
  const auto = process.env.REACT_APP_AUTO === "true";
  const { trackGAEvent } = useGATracking();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");
    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (accessToken) {
      const roles = getRolesFromJwt(accessToken);
      if (roles?.some((r) => r === "manager")) {
        setTimeout(() => {
          trackGAEvent(GA_EVENTS.REGISTER.FINNISH_CLOUD_REGISTER);
          window.location.href = `${PATHS.WEB_LOGIN}?access_token=${accessToken}&refresh_token=${refreshToken}`;
        }, 5000);
      } else {
        const userId = getUserIdFromJwt(accessToken);
        dispatch(getCloudRequest(userId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);
  const cloudRequest = useSelector(userCloudRequestSelector);

  const STATUS_MESSAGES: { [status: number]: string } = {
    0: "Ya tienes una solicitud de registro de tu nube pendiente.",
    2: "La solicitud para registrar una nube fue rechazada. Por favor, comunícate con info@fletti.com",
  };

  const validators: ValidatorsType = {
    name: noEmpty,
    alias: noEmpty,
  };

  const handleInputChange = (field: string) => (value: any | string) => {
    if (typeof validators[field] === "function") {
      setErrors({ ...errors, [field]: validators[field](value) });
    }

    setData((data: any) => ({
      ...data,
      [field]: field === "alias" ? (value as string).toUpperCase() : value,
    }));
  };

  const disableButton =
    Object.entries(data).some(
      ([key, value]) => key !== "phone" && isEmpty(value)
    ) ||
    Object.values(errors).some((error) => error) ||
    [0, 2].includes(cloudRequest?.status);

  const handleSubmit = async () => {
    if (data.alias.length === 4) {
      setLoading(true);
      try {
        const response = await createCloudService(data, auto);
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        // dispatch(getCloud(response.cloud_id));
        dispatch(getUser());
        setStep(2);
        setLoading(false);
      } catch (error: any) {
        toast.error(error, toastConfig);
        setLoading(false);
      }
    } else {
      setErrors({ ...errors, alias: "El Alias debe contener 4 carácteres" });
    }
  };

  interface ValidatorsType {
    [x: string]: (value: string | Set<string>) => string | null;
  }

  const handleContinue = () => {
    // history.push(PATHS.SHIPPERS);
    window.location.href = `${PATHS.WEB_LOGIN}?access_token=${accessToken}&refresh_token=${refreshToken}`;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoHome = () => {
    history.push(PATHS.HOME);
  };

  return {
    data,
    errors,
    disableButton,
    loading,
    step,
    statusMessage: STATUS_MESSAGES[cloudRequest?.status],
    handleInputChange,
    handleSubmit,
    handleContinue,
    handleGoBack,
    handleGoHome,
    auto,
  };
};

export default useRegisterCloud;
