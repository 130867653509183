import { State } from "../../interfaces";
export const geolocationSelector = (state: State) => state.zones.geolocation;
export const zonesSelector = (state: State) => {
  const { data }: any = state.zones;

  const caba = data && data["AR:BSAS"];

  const areas = data && Object.keys(caba);

  const capitalize = (s: string) => {
    const arr = s.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  };

  return areas?.flatMap((sector: any) =>
    Object.keys(caba[sector]).map((neighborhood) => ({
      label: capitalize(neighborhood.replaceAll("_", " ")),
      value: neighborhood,
    }))
  );
};
