import { FunctionComponent, useState } from "react";
import cn from "classnames";

import { Bike, Car, Truck, Van } from "../../assets/images/icons/vehicles";
import { Button } from "..";

import styles from "./VehiclePicker.module.scss";

const noop = () => {};

const BIKE = 0;
const CAR = 1;
const TRUCK = 2;
const VAN = 3;

export type VehiclePickerProps = {
  onChange?: (s: number) => void;
  checked?: any;
};

const VehiclePicker: FunctionComponent<VehiclePickerProps> = ({
  onChange = noop,
  checked = 0,
}) => {
  const [selected, setSelected] = useState(checked);

  const handleSelectVehicle = (vehicle: number) => () => {
    onChange(vehicle);
    setSelected(vehicle);
  };

  return (
    <div className={styles.wrapper}>
      <Button
        icon={<Bike selected={selected === BIKE} />}
        type="clear"
        className={cn(styles.button, {
          [styles["button--selected"]]: selected === BIKE,
        })}
        onClick={handleSelectVehicle(BIKE)}
      />

      <Button
        icon={<Car selected={selected === CAR} />}
        type="clear"
        className={cn(styles.button, {
          [styles["button--selected"]]: selected === CAR,
        })}
        onClick={handleSelectVehicle(CAR)}
      />

      <Button
        icon={<Truck selected={selected === TRUCK} />}
        type="clear"
        className={cn(styles.button, {
          [styles["button--selected"]]: selected === TRUCK,
        })}
        onClick={handleSelectVehicle(TRUCK)}
      />

      <Button
        icon={<Van selected={selected === VAN} />}
        type="clear"
        className={cn(styles.button, {
          [styles["button--selected"]]: selected === VAN,
        })}
        onClick={handleSelectVehicle(VAN)}
      />
    </div>
  );
};

export default VehiclePicker;
