import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { FCM_KEY } from "../../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { addFirebaseToken } from "../../services/user";
import { getItinerary } from "../../state/slices/itineraries";
import { getRequests, getRequestTracking } from "../../state/slices/request";
import { isLoggedSelector } from "../../state/selectors/user";
import { toast } from "react-toastify";
import { toastConfig } from "../../constants";
import { getShippers } from "../../state/slices/shippers";

const useMessaging = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  const appBootstrap = () => {
    const firebaseConfig = {
      apiKey: "AIzaSyBkp2Y2TcEgZS5T_U0yy3GZju7eluKdb6A",
      authDomain: "fletti.firebaseapp.com",
      projectId: "fletti",
      storageBucket: "fletti.appspot.com",
      messagingSenderId: "585212743588",
      appId: "1:585212743588:web:e372af8637508d88c43b31",
      measurementId: "G-6WYJV4PBX2",
    };

    initializeApp(firebaseConfig);

    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const message: any = payload?.data;

      const subject = message?.subject;
      // const { title, body } = message.notification;
      switch (subject) {
        case "iti-completed":
        case "chat":
          dispatch(getItinerary(message.itinerary_id));
          break;
        case "tracking":
          dispatch(getRequestTracking(message.request_id));
          break;
        case "offer-accepted-cc":
        case "pickedup":
        case "delivered":
          dispatch(getRequests({}));
          break;
        case "shipper-unavailable":
        case "shipper-available":
        case "new-shipper-application":
          dispatch(getShippers());
          break;
        case "shipper-advice":
          toast.success(
            `Le hemos avisado a uno de tus repartidores que no ha registrado su ubicación!! ${message.subject}`,
            toastConfig
          );
          break;
        default:
          toast.success(
            `Llego un mensaje nuevo!! ${message.subject}`,
            toastConfig
          );
          break;
      }
    });

    if (isLogged) {
      getToken(messaging, { vapidKey: FCM_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            addFirebaseToken(currentToken);
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  };

  return { appBootstrap };
};

export default useMessaging;
