import { FunctionComponent } from "react";
import fletti from "../../assets/images/fletti.svg";

export type LogoInterface = {
  className?: string;
};

const Logo: FunctionComponent<LogoInterface> = ({ className }) => {
  return <img className={className} src={fletti} alt="logo" />;
};

export { Logo };
