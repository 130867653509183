import { useMemo } from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";

import styles from "./Skeleton.module.scss";

export interface SkeletonProps {
  containerStyle?: string;
  count?: number;
  circle?: boolean;
  square?: boolean;
  height?: string;
  width?: string;
  left?: boolean;
  right?: boolean;
  children?: React.ReactNode;
}

const Skeleton = ({
  containerStyle,
  count = 0,
  circle,
  square,
  height,
  width,
  left,
  right,
  children,
}: SkeletonProps): JSX.Element => {
  let lines = useMemo(() => {
    const line = (
      <span
        className={styles["skeleton-container__line"]}
        style={{ height, width }}
      >
        {children}
      </span>
    );

    if (circle || square) {
      return null;
    }

    const lines: JSX.Element[] = [];

    if (isEmpty(lines)) {
      return line;
    }

    for (let index = 0; index < count; index++) {
      lines.push(line);
    }
  }, [count, circle, square, height, width, children]);

  const selectedCSSVariable = {
    "--default-size": width,
  } as React.CSSProperties;

  return (
    <div
      className={cn(styles["skeleton-container"], containerStyle, {
        [styles["skeleton-container--single-line"]]: !(square || circle),
        [styles["skeleton-container--left"]]: left,
        [styles["skeleton-container--right"]]: right,
      })}
    >
      {(square || circle) && (
        <span
          style={selectedCSSVariable}
          className={cn({
            [styles["skeleton-container__circle"]]: circle,
            [styles["skeleton-container__square"]]: square,
          })}
        />
      )}

      {lines}
    </div>
  );
};

export { Skeleton };
