import { purple, orange, green } from "../../constants/colors";

export const containerStyle = {
  width: "100%",
  height: "calc(100% - 56px)",
  borderRadius: "8px",
};

export const lineStyle = {
  strokeColor: orange,
  strokeOpacity: 1,
  strokeWeight: 3,
};

export const shipperLineStyle = {
  strokeColor: purple,
  strokeOpacity: 1,
  strokeWeight: 3,
};

export const pendingCircleStyle = {
  strokeWeight: 18,
  strokeColor: orange,
};

export const currentCircleStyle = {
  strokeWeight: 18,
  strokeColor: purple,
};

export const completedCircleStyle = {
  strokeWeight: 18,
  strokeColor: green,
};
