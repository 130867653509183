import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastConfig } from "../../../constants";
import { acceptBusiness, declineBusiness } from "../../../services/businesses";
import {
  getBusinesses,
  getBusinessPayments,
  getBusinessRequests,
} from "../../../state/slices/businesses";

const useExtraInfo = () => {
  const dispatch = useDispatch();
  const [businessAcceptanceLoading, setBusinessAcceptanceLoading] =
    useState(false);

  const handleAcceptBusiness = async (user_id: string) => {
    setBusinessAcceptanceLoading(true);
    try {
      await acceptBusiness(user_id);

      dispatch(getBusinesses());
      dispatch(getBusinessRequests());
      dispatch(getBusinessPayments());
    } catch (error) {
      toast.error("Ocurrio un error.", toastConfig);
    }

    setBusinessAcceptanceLoading(false);
  };

  const handleDeclineBusiness = async (user_id: string) => {
    setBusinessAcceptanceLoading(true);
    try {
      await declineBusiness(user_id);

      dispatch(getBusinesses());
      dispatch(getBusinessRequests());
      dispatch(getBusinessPayments());
    } catch (error) {
      toast.error("Ocurrio un error.", toastConfig);
    }
    setBusinessAcceptanceLoading(false);
  };

  return {
    businessAcceptanceLoading,
    handleAcceptBusiness,
    handleDeclineBusiness,
  };
};

export default useExtraInfo;
