/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent } from "react";
import cn from "classnames";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";

import clock from "../../assets/images/clock.svg";
import compass from "../../assets/images/compass.svg";

import { GA_EVENTS } from "../../constants";
import { Button, Input, Logo } from "../../components";

import useRegisterCloud from "./useRegisterCloud";
import styles from "./RegisterCloud.module.scss";

type Props = {};

const RegisterCloud: FunctionComponent = (props: Props) => {
  const {
    data,
    errors,
    disableButton,
    loading,
    step,
    statusMessage,
    handleInputChange,
    handleSubmit,
    handleContinue,
    handleGoBack,
    handleGoHome,
    auto,
  } = useRegisterCloud();

  return (
    <div className={styles["wrapper"]}>
      {step === 1 ? (
        <>
          <Logo />

          <div className={styles["wrapper__header"]}>
            <h4 className={styles["wrapper__header__title"]}>
              Registrá tu Nube
            </h4>
          </div>
          <div className={cn(styles["wrapper__form"])}>
            <Input
              label="Nombre de la Nube"
              placeholder="Transportes Manolo"
              theme="card"
              onChange={handleInputChange("name")}
              value={data?.name}
              error={errors.name}
            />

            <Input
              label="Alias de la Nube"
              placeholder="NUBE"
              theme="card"
              maxLength={4}
              onChange={handleInputChange("alias")}
              value={data.alias}
              error={errors.alias}
            />

            <Input
              label="Teléfono"
              placeholder="1234567890"
              theme="card"
              onChange={handleInputChange("phone")}
              value={data.phone}
              error={errors.phone}
            />
          </div>

          {statusMessage && (
            <h5 className={styles["wrapper__status-error"]}>{statusMessage}</h5>
          )}

          <div className={styles["wrapper__button-container"]}>
            <Button
              title="Volver"
              type="gradient"
              onClick={handleGoBack}
              icon={<FaChevronLeft />}
              xxl
            />

            <Button
              title={"Siguiente"}
              type="gradient"
              onClick={handleSubmit}
              icon={<FaChevronRight />}
              disabled={disableButton}
              loading={loading}
              rightIcon
              xxl
            />
          </div>
        </>
      ) : (
        <div className={styles["step2container"]}>
          <Logo />

          {auto ? (
            <>
              <img
                src={compass}
                alt=""
                style={{ width: "50%", height: "50%" }}
              />
              <h2>La nube se ha dado de alta correctamente.</h2>
              <h2>Serás redireccionado en breve.</h2>
              <Button
                title={"Ingresar"}
                type="gradient"
                onClick={handleContinue}
                icon={<FaChevronRight />}
                loading={loading}
                rightIcon
                xxl
                gaEvent={GA_EVENTS.REGISTER.FINNISH_CLOUD_REGISTER}
              />
            </>
          ) : (
            <>
              <div>
                <img src={clock} alt="" />
                <h2>Un administrador revisará la solicitud en breve</h2>
              </div>
              <Button
                title={"Ir a Inicio"}
                type="gradient"
                onClick={handleGoHome}
                icon={<FaChevronRight />}
                loading={loading}
                rightIcon
                xxl
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RegisterCloud;
