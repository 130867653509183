import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown } from "../../components";
import { soft_violet } from "../../constants/colors";
import { STATUSES } from "../../constants/requests";
import { businessNameSelector } from "../../state/selectors/businesses";
import { shipperNameSelector } from "../../state/selectors/shippers";

import styles from "./History.module.scss";

export type HistorySearchBarProps = {
  dateRange: any;
  setFilterDateRange: any;
  filterStatus: any;
  setFilterStatus: any;
  shipperId?: string;
  setShipperId: any;
  businessId?: string;
  setBusinessId: any;
};

const statuses = STATUSES.map((status) => ({
  label: status.toUpperCase(),
  value: status,
}));

const dateRanges = [
  {
    label: "1 Semana",
    value: "1w",
  },
  {
    label: "2 Semanas",
    value: "2w",
  },
  {
    label: "3 Semanas",
    value: "3w",
  },
  {
    label: "1 Mes",
    value: "1M",
  },
  {
    label: "2 Meses",
    value: "2M",
  },
];

const HistorySearchBar: FunctionComponent<HistorySearchBarProps> = ({
  dateRange,
  setFilterDateRange,
  filterStatus,
  setFilterStatus,
  shipperId = undefined,
  setShipperId,
  businessId = undefined,
  setBusinessId,
}) => {
  const shipperName = useSelector(shipperNameSelector(shipperId));
  const businessName = useSelector(businessNameSelector(businessId));

  return (
    <div className={styles["search-bar"]}>
      <div className={styles["search-bar__right-box"]}>
        <Dropdown
          options={dateRanges}
          placeholder="Filtrar por Fecha"
          onChange={setFilterDateRange}
          value={dateRange}
        />
        <Dropdown
          options={statuses}
          placeholder="Filtrar por estado"
          onChange={setFilterStatus}
          value={filterStatus}
        />
        {shipperId && (
          <div className={styles["search-bar"]}>
            <p>
              Repartidor: <b>{shipperName}</b>
            </p>
            <Button
              icon={<FaTrash color={soft_violet} />}
              type="clear"
              onClick={() => setShipperId(undefined)}
            />
          </div>
        )}
        {businessId && (
          <div className={styles["search-bar"]}>
            <p>
              Comercio: <b>{businessName}</b>
            </p>
            <Button
              icon={<FaTrash color={soft_violet} />}
              type="clear"
              onClick={() => setBusinessId(undefined)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistorySearchBar;
