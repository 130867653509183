import { v4 as uuidv4 } from "uuid";

import { Card } from "../../../../components";
import Marker from "../Marker";

import useStatus from "./useStatus";
import styles from "./Status.module.scss";

export type StatusProps = {
  className?: string;
  status?: number;
  stops: any;
};

const Status = ({ className, status, stops }: StatusProps): JSX.Element => {
  const { steps } = useStatus({ status, stops });

  return (
    <Card className={`${styles.wrapper} ${className}`}>
      <div className={styles["wrapper__markers-container"]}>
        {steps.map(({ title, status, isStop, isCurrentStop }: any) => (
          <Marker
            title={title}
            status={status}
            isStop={isStop}
            isCurrentStop={isCurrentStop}
            key={uuidv4()}
          />
        ))}
      </div>
    </Card>
  );
};

export default Status;
